import { gql } from '@apollo/client'
import {
  AccountCustomTerms,
  EntryType,
  PositionStatus,
  PositionType,
  WorkType,
  InterviewStatus,
  InterviewResultStatus,
  InterviewLocation,
  InterviewUserPermission,
  InterviewResultUpdateType,
  EntryUserPermission,
  EntryDeclineReasonCategory,
  EntryMessageVariant,
  NotificationType,
  EntryActionCategory,
  EntryActionHistoryContent,
  EntryMessageScheduleRole,
  InformationStatus,
  AuthRole,
  ElectionStatus,
  ElectionPriority,
  WorkshopEntryStatus,
  EmailVerificationType,
  LifeLineChartV1,
  LifeLineChartV2,
  ValueCheckV1,
  InventoryOfOwnResourcesV1,
  LookingBackOnGrowthV1,
  NextStepV1,
  NextStepV2,
  VectorAndVisionV1,
  VectorAndVisionV2,
  TagType,
  DoubleworkFlowStepNo,
  DoubleworkEntryUserPermission,
  ConsiderationType,
  WorkshopSurveyV2,
  WorkshopSurveyV1,
  DoubleworkProceduresChecks,
  PostOrHalfYearLaterSurveyValues,
  SurveyType,
  Difficulty,
  DoubleworkEntryActionCategory,
  DoubleworkEntryActionHistoryContent,
  CareerType,
  WorksheetVersion,
  CareerScoutStatus,
  SenderStatus,
  AnnualScheduleType,
  ResumeSnapshotCareers,
  ResumeSnapshotAchievement,
  ResumeSnapshotMaster,
  ResumeSnapshotQualificationGrade,
  ResumeSnapshotSkill,
  CareerScoutGroupFlowStepNo,
  CareerScoutIndividualFlowStepNo,
  CareerScoutEntryType,
  CareerScoutEntryUserPermission,
  CareerScoutEntryActionCategory,
  CareerScoutEntryActionHistoryContent,
  DoubleworkStatus,
  DoubleworkPlaceholderSample,
  WorkingLocationType,
  RewardAmountType,
  SalaryType,
  JTFlowStepNo,
  GccFlowStepNo,
  IccFlowStepNo,
} from '@persol-epdndo/base-shared'
import { AnnualSchedule, Position, UploadFile } from '@persol-epdndo/design-systems'
import { LocalState } from './lib/state'
import { JSONObject } from './util'

export const QueryLocalState = gql`
  query localState {
    localState {
      pa @client
    }
  }
`

export interface QueryLocalStateResponse {
  localState: LocalState
}

export const QueryAccount = gql`
  query account {
    account {
      id
      name
      customTerms
      customTermsUpdatedAt
      allowIp
    }
  }
`

export interface QueryAccountResponse {
  account: {
    id: string
    name: string
    customTerms: JSONObject
    customTermsUpdatedAt: string | null
    allowIp: string[]
  }
}

export const QueryLocalStateAndCustomTerms = gql`
  query localStateAndCustomTerms {
    localState {
      pa @client
    }
    account {
      id
      customTerms
      customTermsUpdatedAt
    }
  }
`

export interface QueryLocalStateAndCustomTermsResponse {
  localState: LocalState
  account: {
    id: string
    customTerms: AccountCustomTerms
    customTermsUpdatedAt: string | null
  }
}

export const MutationAddAgreement = gql`
  mutation addAgreement {
    addAgreement(input: { items: ["accountCustomTerms"], version: "1" })
  }
`

export const QueryPrivatePageNavigation = gql`
  query privatePageNavigation {
    localState {
      pa @client
    }
    manageEntries(limit: 1) {
      total
    }
    manageDoubleworkEntries(limit: 1) {
      total
    }
    manageCareerScoutEntries(limit: 1) {
      total
    }
    workshopEntries(limit: 1) {
      id
    }
    me {
      id
      avatarUrl
      firstName
      lastName
    }
  }
`

export interface QueryPrivatePageNavigationResponse {
  localState: LocalState
  manageEntries: {
    total: number
  }
  manageDoubleworkEntries: {
    total: number
  }
  manageCareerScoutEntries: {
    total: number
  }
  workshopEntries: {
    id: string
  }
  me: {
    id: string
    avatarUrl: string
    firstName: string
    lastName: string
  }
}

export const QueryPositionMasters = gql`
  query positionMasters {
    organizations {
      value: id
      label: name
    }
    employmentTypes {
      id
      name
    }
    jobClassifications {
      value: id
      label: name
    }
    workingLocations {
      value: id
      label: name
    }
  }
`

export interface SelectOption {
  value: string
  label: string
}

export interface QueryPositionMastersResponse {
  organizations: SelectOption[]
  employmentTypes: Array<{
    id: string
    name: string
  }>
  jobClassifications: SelectOption[]
  workingLocations: SelectOption[]
}

export const QueryPositionTags = gql`
  query positionTags {
    tags(type: ${TagType.Position}) {
      value: id
      label: name
    }
  }
`

export interface QueryPositionTagsResponse {
  tags: SelectOption[]
}

export const QueryOrganizationMasters = gql`
  query QueryOrganizationMasters($organizationId: ID!, $positionType: Int!) {
    responsibleUsers(organizationIds: [$organizationId], positionType: $positionType) {
      id
      firstName
      lastName
      email
      avatarUrl
    }
    trainerUsers(organizationIds: [$organizationId]) {
      id
      firstName
      lastName
      email
      avatarUrl
    }
    offices(organizationId: $organizationId) {
      value: id
      label: name
      address
    }
  }
`

export interface QueryOrganizationMastersResponse {
  responsibleUsers: Array<{
    id: string
    firstName: string
    lastName: string
    email: string
    avatarUrl: string
  }>
  trainerUsers: Array<{
    id: string
    firstName: string
    lastName: string
    email: string
    avatarUrl: string
  }>
  offices: Array<{
    value: string
    label: string
    address: string
  }>
}

export const QuerySearchManagedPositionMasters = gql`
  query searchManagedPositionMasters {
    organizations {
      value: id
      label: name
    }
    employmentTypes {
      id
      name
    }
    jobClassifications {
      value: id
      label: name
    }
    workingLocations {
      value: id
      label: name
    }
    tags {
      value: id
      label: name
    }
  }
`

export interface QuerySearchManagedPositionMastersResponse {
  organizations: SelectOption[]
  responsibleUsers: Array<{
    id: string
    firstName: string
    lastName: string
    email: string
    avatar: string
  }>
  employmentTypes: Array<{
    id: string
    name: string
  }>
  jobClassifications: SelectOption[]
  workingLocations: SelectOption[]
  tags: SelectOption[]
}

export const MutationUploadImages = gql`
  mutation uploadImages($images: [Upload!]!) {
    uploadImages(images: $images) {
      key
      url
    }
  }
`

interface UploadedFile {
  key: string
  url: string
}

export interface MutationUploadImagesResponse {
  uploadImages: UploadedFile[]
}

export const MutationUploadFiles = gql`
  mutation uploadFiles($files: [Upload!]!, $setAttachment: Boolean) {
    uploadFiles(files: $files, setAttachment: $setAttachment) {
      key
      url
    }
  }
`

export interface MutationUploadFilesResponse {
  uploadFiles: UploadedFile[]
}

export interface CreatePositionInput {
  positionType: PositionType
  organizationId?: string
  name?: string
  openStartedAt?: Date
  openEndedAt?: Date
  applicationStartedAt?: Date
  applicationEndedAt?: Date
  responsibleUserIds?: string[]
  contactUserIds?: string[]
  contactEmails?: string[]
  employmentTypeIds?: string[]
  jobClassificationId?: string | null
  department?: string
  mission?: string
  personnelComment?: string
  officeIds?: string[]
  workingLocationIds?: string[]
  workingHour?: string
  arrivalFrequency?: string
  transfer?: string
  authorComment?: string
  requiredExperience?: string
  image?: string
  detailImages?: string[]
  detailCaptions?: string[]
  isOpenToGroup?: boolean
  isOpenToOrganization?: boolean
  capacity?: number
  workType?: WorkType
  trainerUserId?: string | null
  pointsToLearn?: string
  isAllowRemote?: boolean
  periodStartedAt?: Date
  periodEndedAt?: Date
  period?: string
  status: PositionStatus
  tagNames?: string[]
  pledgeVersion?: string
  annualIncome?: string
  applicationRequirement?: string
  socialInsurance?: string
  requiredEnviroment?: string
  nearestStation?: string
}

export const MutationCreatePosition = gql`
  mutation createPosition($input: CreatePositionInput!) {
    createPosition(input: $input) {
      id
      image
      imageUrl
      detailImages
      detailImageUrls
    }
  }
`

export interface MutationCreatePositionResponse {
  createPosition: {
    id: string
    image: string
    imageUrl: string
    detailImages: string[]
    detailImageUrls: string[]
  }
}

export type UpdatePositionInput = CreatePositionInput & {
  id: string
}

export const MutationUpdatePosition = gql`
  mutation updatePosition($input: UpdatePositionInput!) {
    updatePosition(input: $input) {
      id
      image
      imageUrl
      detailImages
      detailImageUrls
    }
  }
`

export interface MutationUpdatePositionResponse {
  updatePosition: {
    id: string
    image: string
    imageUrl: string
    detailImages: string[]
    detailImageUrls: string[]
  }
}

// NOTE: 後で、QueryPositionMastersをQueryManagedPositionMastersに変更し
// こちらをQueryPositionMasters2からQueryPositionMastersに変更する(コンフリクト対策)
export const QueryPositionMasters2 = gql`
  query positionMasters2 {
    organizations {
      value: id
      label: name
      externalId
    }
    employmentTypes {
      id
      name
    }
    jobClassifications {
      value: id
      label: name
    }
    workingLocations {
      value: id
      label: name
    }
  }
`

// NOTE: 後で、QueryPositionMastersResponseをQueryManagedPositionMastersResponseに変更し
// こちらをQueryPositionMastersResponse2からQueryPositionMastersResponseに変更する(コンフリクト対策)
export interface QueryPositionMastersResponse2 {
  organizations: Array<SelectOption & { externalId: string }>
  employmentTypes: Array<{
    id: string
    name: string
  }>
  jobClassifications: SelectOption[]
  workingLocations: SelectOption[]
}

export const QueryApproveUsers = gql`
  query approveUsers {
    approveUsers {
      id
      firstName
      lastName
      email
    }
  }
`

export interface QueryApproveUsersResponse {
  approveUsers: [
    {
      id: string
      firstName: string
      lastName: string
      email: string
    },
  ]
}

export const QueryPosition = gql`
  query position(
    $positionType: Int
    $employmentTypeIds: [ID!]
    $jobClassificationId: ID
    $workingLocationId: ID
    $organizationId: ID
    $isAllowRemotes: [Boolean!]
    $workTypes: [Int!]
    $isIncludeClosed: Boolean
    $tagIds: [ID!]
    $keywords: [String!]
    $isOnlyBookmark: Boolean
    $sortType: Int
    $positionId: ID!
  ) {
    position(
      positionType: $positionType
      employmentTypeIds: $employmentTypeIds
      jobClassificationId: $jobClassificationId
      workingLocationId: $workingLocationId
      organizationId: $organizationId
      isAllowRemotes: $isAllowRemotes
      workTypes: $workTypes
      isIncludeClosed: $isIncludeClosed
      tagIds: $tagIds
      keywords: $keywords
      isOnlyBookmark: $isOnlyBookmark
      sortType: $sortType
      positionId: $positionId
    ) {
      position {
        id
        positionType
        account {
          id
          name
        }
        organization {
          id
          name
        }
        authorUser {
          id
          firstName
          lastName
        }
        openStartedAt
        openEndedAt
        applicationStartedAt
        applicationEndedAt
        name
        responsibleUserIds
        contactUserIds
        employmentTypeIds
        jobClassification {
          id
          name
        }
        department
        mission
        personnelComment
        officeIds
        workingLocationIds
        workingHour
        transfer
        authorComment
        requiredExperience
        image
        detailImages
        detailCaptions
        isOpenToGroup
        isOpenToOrganization
        capacity
        workType
        trainerUser {
          id
          firstName
          lastName
        }
        pointsToLearn
        isAllowRemote
        periodStartedAt
        periodEndedAt
        status
        positionTag {
          id
          tag {
            id
            name
          }
        }
        isBookmarked
      }
      beforeId
      afterId
    }
  }
`

export interface QueryPositionResponse {
  position: {
    position: {
      id: string
      name: string
      positionType: number
      employmentTypeIds: string[]
      jobClassification: {
        name: string
      }
      workingLocationId: string
      organization: {
        id: string
        name: string
      }
      isAllowRemotes: boolean
      workTypes: number
      isIncludeClosed: boolean
      tagIds: string[]
      keywords: string[]
      isOnlyBookmark: boolean
      sortType: number
      positionId: string
    }
    beforeId: string
    afterId: string
  }
}

export const QueryPositions = gql`
  query positions(
    $positionType: Int!
    $employmentTypeIds: [ID!]
    $jobClassificationId: ID
    $workingLocationId: ID
    $organizationId: ID
    $isAllowRemotes: [Boolean!]
    $workTypes: [Int!]
    $isIncludeClosed: Boolean
    $tagIds: [ID!]
    $keywords: [String!]
    $isOnlyBookmark: Boolean
    $sortType: Int
    $seed: String
    $self: String
    $before: String
    $after: String
    $limit: Int
  ) {
    positions(
      positionType: $positionType
      employmentTypeIds: $employmentTypeIds
      jobClassificationId: $jobClassificationId
      workingLocationId: $workingLocationId
      organizationId: $organizationId
      isAllowRemotes: $isAllowRemotes
      workTypes: $workTypes
      isIncludeClosed: $isIncludeClosed
      tagIds: $tagIds
      keywords: $keywords
      isOnlyBookmark: $isOnlyBookmark
      sortType: $sortType
      seed: $seed
      self: $self
      before: $before
      after: $after
      limit: $limit
    ) {
      items {
        id
        positionType
        name
        imageUrl
        jobClassification {
          id
          name
        }
        organization {
          id
          name
        }
        department
        workingLocations {
          id
          name
        }
        openStartedAt
        openEndedAt
        applicationStartedAt
        applicationEndedAt
        positionTag {
          tag {
            id
            name
          }
        }
        isBookmarked
      }
      total
      hasBefore
      hasNext
      startCursor
      endCursor
    }
  }
`

// NOTE: ポジション管理APIへの接続定義
export const QueryManagePositions = gql`
  query managePositions(
    $positionType: Int
    $isOpenToGroup: Boolean!
    $isOpenToOrganization: Boolean!
    $id: ID
    $organizationIds: [ID!]
    $responsibleUserName: String
    $name: String
    $openStartedAt: DateTime
    $openEndedAt: DateTime
    $status: Int
    $period: String
    $offset: Int
    $limit: Int
  ) {
    managePositions(
      positionType: $positionType
      isOpenToGroup: $isOpenToGroup
      isOpenToOrganization: $isOpenToOrganization
      id: $id
      organizationIds: $organizationIds
      responsibleUserName: $responsibleUserName
      name: $name
      openStartedAt: $openStartedAt
      openEndedAt: $openEndedAt
      status: $status
      period: $period
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        createdAt
        modifiedAt
        positionType
        organization {
          id
          name
        }
        authorUser {
          id
          email
        }
        openStartedAt
        openEndedAt
        applicationStartedAt
        applicationEndedAt
        name
        responsibleUsers {
          id
          firstName
          lastName
          email
        }
        contactUserIds
        contactUsers {
          id
          email
        }
        contactEmails
        detailImages
        detailImageUrls
        detailCaptions
        isOpenToGroup
        isOpenToOrganization
        status
        # NOTE: 以下ポジション複製時にデータを取得する際にのみ使用するカラム
        # NOTE: 別クエリに分けた方が良いか検討。分けるならinterfaceを自動生成するようにしたいが
        arrivalFrequency
        transfer
        personnelComment
        requiredExperience
        capacity
        workType
        pointsToLearn
        trainerUser {
          id
          lastName
          firstName
        }
        isAllowRemote
        periodStartedAt
        periodEndedAt
        isOpenToGroup
        employmentTypeIds
        jobClassification {
          id
        }
        department
        mission
        officeIds
        workingLocationIds
        workingHour
        authorComment
        positionTag {
          tag {
            id
            name
          }
        }
        image
        imageUrl
        detailImages
        detailImageUrls
        annualIncome
        applicationRequirement
        socialInsurance
        requiredEnviroment
        nearestStation
      }
      total
    }
  }
`

export interface QueryManagePositionsResponse {
  managePositions: {
    items: ManagePositionItem[]
    total: number
  }
}

export interface ManagePositionItem {
  id: string
  createdAt: Date
  modifiedAt: Date
  positionType: number
  organization: {
    id: string
    name: string
  }
  authorUser: {
    id: string
    email: string
  }
  openStartedAt: Date
  openEndedAt: Date
  applicationStartedAt: Date
  applicationEndedAt: Date
  name: string
  responsibleUsers: [
    {
      id: string
      firstName: string
      lastName: string
      email: string
    },
  ]
  contactUserIds: string[]
  contactUsers: {
    email: string
  }
  contactEmails: string[]
  detailImages: string[]
  detailImageUrls: string[]
  detailCaptions: string[]
  isOpenToGroup: boolean
  isOpenToOrganization: boolean
  status: number
  // NOTE: 以下ポジション複製時にデータを取得する際にのみ使用するカラム
  // NOTE: 別クエリに分けた方が良いか検討。分けるならinterfaceを自動生成するようにしたい
  arrivalFrequency: string
  transfer: string
  personnelComment: string
  requiredExperience: string
  capacity: number
  workType: number
  pointsToLearn: string
  trainerUser: {
    id: string
    firstName: string
    lastName: string
  } | null
  isAllowRemote: boolean | null
  periodStartedAt: Date | null
  periodEndedAt: Date | null
  employmentTypeIds: string[]
  jobClassification: { id: string } | null
  department: string
  mission: string
  officeIds: string[]
  workingLocationIds: string[]
  workingHour: string
  authorComment: string
  positionTag: Array<{
    tag: {
      id: string
      name: string
    }
  }>
  image: string
  imageUrl: string
  annualIncome: string
  applicationRequirement: string
  socialInsurance: string
  requiredEnviroment: string
  nearestStation: string
}

export const MutationDeletePositions = gql`
  mutation deletePositions($positionIds: [ID!]!) {
    deletePositions(positionIds: $positionIds)
  }
`

export interface MutationDeletePositionsResponse {
  deletePositions: boolean
}

export const MutationSuspendPositions = gql`
  mutation suspendPositions($positionIds: [ID!]!) {
    suspendPositions(positionIds: $positionIds)
  }
`

export interface MutationSuspendPositionsResponse {
  suspendPositions: boolean
}

export const MutationApprovePositions = gql`
  mutation approvePositions($positionIds: [ID!]!) {
    approvePositions(positionIds: $positionIds)
  }
`

export interface MutationApprovePositionsResponse {
  approvePositions: boolean
}

export const MutationBulkExportPositions = gql`
  mutation bulkExportPositions($positionType: Int!, $positionIds: [ID!]!) {
    bulkExportPositions(positionType: $positionType, positionIds: $positionIds)
  }
`

export interface MutationBulkExportPositionsResponse {
  bulkExportPositions: string
}

export const MutationBulkImportPositions = gql`
  mutation bulkImportPositions($positionType: Int!, $files: [Upload!]!) {
    bulkImportPositions(positionType: $positionType, files: $files) {
      created
      updated
      errors
    }
  }
`

export interface MutationBulkImportPositionsResponse {
  bulkImportPositions: {
    created: number
    updated: number
    errors: Array<{
      row: number | null
      column: string | null
      value: string | null
      message: string
    }>
  }
}

export const MutationSignedUrl = gql`
  mutation signedUrl($fileKey: String!) {
    signedUrl(fileKey: $fileKey) {
      url
      isEmbedded
    }
  }
`

export interface MutationSignedUrlResponse {
  signedUrl: {
    url: string
    isEmbedded: boolean
  }
}

export interface QueryPositionsResponse {
  positions: {
    items: Array<{
      id: string
      positionType: number
      name: string
      imageUrl: string
      jobClassification: {
        name: string
      }
      organization: {
        name: string
      }
      department: string
      workingLocations: Array<{
        name: string
      }>
      openStartedAt: string | null
      openEndedAt: string | null
      applicationStartedAt: string | null
      applicationEndedAt: string | null
      positionTag: Array<{
        tag: {
          id: string
          name: string
        }
      }>
      isBookmarked: boolean
    }>
    total: number
    hasBefore: boolean | null
    hasNext: boolean | null
    startCursor: string | null
    endCursor: string | null
  }
}

export const MutationTogglePositionBookmark = gql`
  mutation togglePositionBookmark($positionId: ID!) {
    togglePositionBookmark(positionId: $positionId)
  }
`

export interface MutationTogglePositionBookmarkResponse {
  togglePositionBookmark: boolean
}

export interface CreateEntryInput {
  positionId: string
  firstName: string
  firstNameKana: string
  lastName: string
  lastNameKana: string
  phoneNumber: string
  email: string
  travelTime: number
  zipCode: string
  address1: string
  address2: string
  address3: string
  currentIncome: number | null
  salaryType: SalaryType
  basicSalary: number | null
  overtimePay: number | null
  jobAllowance: number | null
  bonus: number | null
  currentJobDetail: string
  entryReason: string
  selfPr: string
  consideration: string
  resumeFile: string
  resumeFileName: string
  pledgeVersion: string
  approveUserId?: string | null
  isTemporarySaved: boolean
}

export interface UpdateEntryInput extends CreateEntryInput {
  id: string
}

export const MutationCreateEntry = gql`
  mutation createEntry($input: CreateEntryInput!) {
    createEntry(input: $input) {
      id
      firstName
      firstNameKana
      lastName
      lastNameKana
      phoneNumber
      email
      travelTime
      zipCode
      address1
      address2
      address3
      currentIncome
      salaryType
      basicSalary
      overtimePay
      jobAllowance
      bonus
      currentJobDetail
      entryReason
      selfPr
      consideration
      resumeFile
      resumeFileName
      resumeFileUrl
      approveUser {
        id
      }
    }
  }
`

export interface MutationCreateEntryResponse {
  createEntry: {
    id: string
    firstName: string
    firstNameKana: string
    lastName: string
    lastNameKana: string
    phoneNumber: string
    email: string
    travelTime: number
    zipCode: string
    address1: string
    address2: string
    address3: string
    currentIncome: number | null
    salaryType: SalaryType
    basicSalary: number | null
    overtimePay: number | null
    jobAllowance: number | null
    bonus: number | null
    currentJobDetail: string
    entryReason: string
    selfPr: string
    consideration: string
    resumeFile: string
    resumeFileName: string
    resumeFileUrl: string
    approveUser: {
      id: string
    } | null
  }
}

export const MutationUpdateEntry = gql`
  mutation updateEntry($input: UpdateEntryInput!) {
    updateEntry(input: $input) {
      id
      firstName
      firstNameKana
      lastName
      lastNameKana
      phoneNumber
      email
      travelTime
      zipCode
      address1
      address2
      address3
      currentIncome
      salaryType
      basicSalary
      overtimePay
      jobAllowance
      bonus
      currentJobDetail
      entryReason
      selfPr
      consideration
      resumeFile
      resumeFileName
      resumeFileUrl
      approveUser {
        id
      }
    }
  }
`

export interface MutationUpdateEntryResponse {
  updateEntry: {
    id: string
    firstName: string
    firstNameKana: string
    lastName: string
    lastNameKana: string
    phoneNumber: string
    email: string
    travelTime: number
    zipCode: string
    address1: string
    address2: string
    address3: string
    currentIncome: number | null
    salaryType: SalaryType
    basicSalary: number | null
    overtimePay: number | null
    jobAllowance: number | null
    bonus: number | null
    currentJobDetail: string
    entryReason: string
    selfPr: string
    consideration: string
    resumeFile: string
    resumeFileName: string
    resumeFileUrl: string
    approveUser: {
      id: string
    } | null
  }
}

// NOTE: 後でQueryPositionにマージする(コンフリクト対策)
export const QueryPosition2 = gql`
  query position2(
    $positionType: Int
    $employmentTypeIds: [ID!]
    $jobClassificationId: ID
    $workingLocationId: ID
    $organizationId: ID
    $isAllowRemotes: [Boolean!]
    $workTypes: [Int!]
    $isIncludeClosed: Boolean
    $tagIds: [ID!]
    $keywords: [String!]
    $isOnlyBookmark: Boolean
    $sortType: Int
    $seed: String
    $positionId: ID!
  ) {
    position(
      positionType: $positionType
      employmentTypeIds: $employmentTypeIds
      jobClassificationId: $jobClassificationId
      workingLocationId: $workingLocationId
      organizationId: $organizationId
      isAllowRemotes: $isAllowRemotes
      workTypes: $workTypes
      isIncludeClosed: $isIncludeClosed
      tagIds: $tagIds
      keywords: $keywords
      isOnlyBookmark: $isOnlyBookmark
      sortType: $sortType
      seed: $seed
      positionId: $positionId
    ) {
      position {
        id
        positionType
        name
        mission
        personnelComment
        authorComment
        requiredExperience
        image
        imageUrl
        detailImages
        detailImageUrls
        detailCaptions
        workType
        capacity
        pointsToLearn
        isAllowRemote
        workingHour
        isBookmarked
        arrivalFrequency
        transfer
        periodStartedAt
        periodEndedAt
        jobClassification {
          id
          name
        }
        organization {
          id
          name
        }
        department
        authorUser {
          id
          firstName
          lastName
          avatarUrl
          email
        }
        responsibleUsers {
          id
          firstName
          lastName
          avatarUrl
          email
        }
        contactUsers {
          id
          firstName
          lastName
          avatarUrl
          email
        }
        contactEmails
        positionTag {
          tag {
            id
            name
          }
        }
        offices {
          id
          name
          address
        }
        employmentTypes {
          id
          name
        }
        annualIncome
        applicationRequirement
        socialInsurance
        requiredEnviroment
        nearestStation
        recommendPositions {
          id
          name
          imageUrl
          jobClassification {
            name
          }
        }
        recommendV2Positions {
          id
          name
          imageUrl
          jobClassification {
            name
          }
        }
      }
      beforeId
      afterId
      isValidSearchCondition
    }
  }
`

// NOTE: 後でQueryPositionResponseにマージする(コンフリクト対策)
export interface QueryPositionResponse2 {
  position: {
    position: QueryPositionResponseItem | null
    beforeId: string | null
    afterId: string | null
    isValidSearchCondition: boolean
  }
}

export interface QueryPositionResponseItem {
  id: string
  positionType: PositionType
  name: string
  mission: string
  personnelComment: string
  authorComment: string
  requiredExperience: string
  image: string
  imageUrl: string
  detailImages: string[]
  detailImageUrls: string[]
  detailCaptions: string[]
  workType: number
  capacity: number
  pointsToLearn: string
  isAllowRemote: boolean
  workingHour: string
  isBookmarked: boolean
  arrivalFrequency: string
  transfer: string
  periodStartedAt: Date
  periodEndedAt: Date
  jobClassification: {
    name: string
  }
  organization: {
    id: string
    name: string
  }
  department: string
  authorUser: {
    id: string
    firstName: string
    lastName: string
    avatarUrl: string
    email: string
  }
  responsibleUsers: Array<{
    firstName: string
    lastName: string
    avatarUrl: string
    email: string
  }>
  contactUsers: Array<{
    firstName: string
    lastName: string
    avatarUrl: string
    email: string
  }>
  contactEmails: string[]
  positionTag: Array<{
    tag: {
      id: string
      name: string
    }
  }>
  offices: Array<{
    name: string
    address: string
  }>
  employmentTypes: Array<{
    name: string
  }>
  annualIncome: string
  applicationRequirement: string
  socialInsurance: string
  requiredEnviroment: string
  nearestStation: string
  recommendPositions: Array<{
    id: string
    name: string
    imageUrl: string
    jobClassification: {
      name: string
    } | null
  }>
  recommendV2Positions: Array<{
    id: string
    name: string
    imageUrl: string
    jobClassification: {
      name: string
    } | null
  }>
}

export const QueryManageEntries = gql`
  query manageEntries($id: ID, $offset: Int, $limit: Int) {
    manageEntries(id: $id, offset: $offset, limit: $limit) {
      items {
        id
        user {
          id
          employeeNumber
        }
        firstName
        lastName
        entryReason
        selfPr
        consideration
        currentEntryFlowStep {
          id
          no
        }
        position {
          id
          name
          positionType
          jobClassification {
            id
            name
          }
          organization {
            id
            name
          }
          isAllowRemote
          periodStartedAt
          periodEndedAt
        }
      }
      total
    }
  }
`

export interface QueryManageEntriesResponse {
  manageEntries: {
    items: ManageEntryItem[]
    total: number
  }
}

export interface ManageEntryItem {
  id: string
  user: {
    id: string
    employeeNumber: string
  }
  firstName: string
  lastName: string
  entryReason: string
  selfPr: string
  consideration: string
  currentEntryFlowStep: {
    id: string
    no: number
  }
  position: {
    id: string
    name: string
    positionType: number
    jobClassification: {
      name: string
    }
    organization: {
      name: string
    }
    isAllowRemote: boolean
    periodStartedAt: Date
    periodEndedAt: Date
  }
}

export const MutationApproveOrDenyEntries = gql`
  mutation approveOrDenyEntries($entryIds: [ID!]!, $approveStatus: Int!, $sendBackReason: String) {
    approveOrDenyEntries(entryIds: $entryIds, approveStatus: $approveStatus, sendBackReason: $sendBackReason)
  }
`
export interface MutationApproveOrDenyEntriesResponse {
  approveOrDenyEntries: boolean
}

export const QueryTemplates = gql`
  query templates($id: ID) {
    templates(id: $id) {
      id
      name
      text
      organization {
        id
      }
    }
  }
`

export interface QueryTemplatesResponse {
  templates: Array<{
    id: string
    name: string
    text: string
    organization: {
      id: string
    } | null
  }>
}

export const QueryEntryMessages = gql`
  query entryMessages($entryId: ID!, $before: String, $after: String, $limit: Int, $self: String) {
    entryMessages(entryId: $entryId, before: $before, after: $after, limit: $limit, self: $self) {
      items {
        id
        message
        senderUser {
          id
        }
        interviewSchedule {
          id
          dates {
            id
            startAt
            endAt
            isSelected
            location
            locationDetail
          }
          selectedDate {
            id
            startAt
            endAt
            isSelected
            location
            locationDetail
          }
        }
        interviewScheduleDate {
          id
          startAt
          endAt
          isSelected
          location
          locationDetail
        }
        readUserIds
        senderUserName
        senderUserAvatarUrl
        isUseAdminAvatar
        fileNames
        files
        variant
        scheduleRole
        createdAt
      }
      hasNext
      hasBefore
      startCursor
      endCursor
    }
  }
`

export interface QueryEntryMessagesResponse {
  entryMessages: {
    items: Array<{
      id: string
      message: string
      senderUser: {
        id: string
      } | null
      interviewSchedule: {
        id: string
        dates: Array<{
          id: string
          startAt: Date
          endAt: Date
          isSelected: boolean
          location: InterviewLocation
          locationDetail: string
        }>
        selectedDate: {
          id: string
          startAt: Date
          endAt: Date
          isSelected: string
          location: string
          locationDetail: string
        } | null
      } | null
      interviewScheduleDate: {
        id: string
        startAt: Date
        endAt: Date
        isSelected: string
        location: InterviewLocation
        locationDetail: string
      } | null
      readUserIds: string[]
      senderUserName: string
      senderUserAvatarUrl: string
      isUseAdminAvatar: boolean
      fileNames: string[]
      files: string[]
      variant: EntryMessageVariant
      scheduleRole: EntryMessageScheduleRole
      createdAt: Date
    }>
    hasNext: boolean | null
    hasBefore: boolean | null
    startCursor: string | null
    endCursor: string | null
  }
}

export const MutationCreateMessage = gql`
  mutation createMessage($input: CreateEntryMessageInput!) {
    createEntryMessage(input: $input) {
      id
    }
  }
`

export interface MutationCreateMessageResponse {
  createEntryMessage: {
    id: string
  }
}

export const MutationCreateInterviewSchedule = gql`
  mutation createInterviewSchedule($input: CreateInterviewScheduleInput!) {
    createInterviewSchedule(input: $input) {
      id
    }
  }
`

export interface MutationCreateInterviewScheduleResponse {
  createInterviewSchedule: {
    id: string
  }
}

export const MutationSelectInterviewScheduleDate = gql`
  mutation selectInterviewScheduleDate($input: SelectInterviewScheduleDateInput!) {
    selectInterviewScheduleDate(input: $input) {
      id
      startAt
      endAt
      isSelected
      location
      locationDetail
    }
  }
`

export interface MutationSelectInterviewScheduleDateResponse {
  selectInterviewScheduleDate: {
    id: string
    startAt: Date
    endAt: Date
    isSelected: string
    location: string
    locationDetail: string
  }
}

export const MutationDeleteTemplates = gql`
  mutation deleteTemplates($templateIds: [ID!]!) {
    deleteTemplates(templateIds: $templateIds)
  }
`

export interface MutationDeleteTemplatesResponse {
  deleteTemplates: boolean
}

export const QueryEntryNavigation = gql`
  query entryNavigation($id: ID!) {
    entry(id: $id) {
      id
      entryType
      createdAt
      firstName
      lastName
      employeeNumber
      user {
        id
      }
      approveUser {
        id
      }
      entryFlow {
        id
        steps {
          id
          no
          name
          candidateVisibleName
        }
      }
      currentEntryFlowStep {
        id
        name
        candidateVisibleName
        no
      }
      position {
        id
        positionType
        jobClassification {
          id
          name
        }
        organization {
          id
          name
        }
      }
      interviews {
        id
        status
        entryFlowStep {
          id
          no
          name
          candidateVisibleName
        }
        interviewerUsers {
          id
          user {
            id
          }
        }
      }
      latestNoneCasualInterview {
        id
      }
      userPermissions
      homeOrganizationName
      election {
        id
        priority
      }
      isSuggest
      isSupportRequired
      isTemporarySaved
    }
  }
`

export interface QueryEntryNavigationResponse {
  entry: {
    id: string
    entryType: EntryType
    createdAt: Date
    firstName: string
    lastName: string
    employeeNumber: string
    user: {
      id: string
    }
    approveUser: {
      id: string
    } | null
    entryFlow: {
      steps: Array<{
        id: string
        no: GccFlowStepNo | IccFlowStepNo | JTFlowStepNo
        name: string
        candidateVisibleName: string
      }>
    }
    currentEntryFlowStep: {
      id: string
      name: string
      candidateVisibleName: string
      no: GccFlowStepNo | IccFlowStepNo | JTFlowStepNo
    }
    position: {
      id: string
      positionType: PositionType
      jobClassification: {
        name: string
      }
      organization: {
        id: string
        name: string
      }
    }
    interviews: Array<{
      id: string
      status: InterviewStatus
      entryFlowStep: {
        id: string
        no: GccFlowStepNo | IccFlowStepNo | JTFlowStepNo
        name: string
        candidateVisibleName: string
      }
      interviewerUsers: Array<{
        user: {
          id: string
        }
      }>
    }>
    latestNoneCasualInterview: {
      id: string
    } | null
    userPermissions: EntryUserPermission[]
    homeOrganizationName: string
    election: {
      id: string
      priority: ElectionPriority
    }
    isSuggest: boolean
    isSupportRequired: boolean | null
    isTemporarySaved: boolean
  }
}

export const QueryEntryDetail = gql`
  query entryDetail($id: ID, $positionId: ID) {
    entry(id: $id, positionId: $positionId) {
      id
      firstName
      firstNameKana
      lastName
      lastNameKana
      phoneNumber
      email
      homeOrganizationName
      organizationName
      travelTime
      zipCode
      address1
      address2
      address3
      currentIncome
      salaryType
      basicSalary
      overtimePay
      jobAllowance
      bonus
      currentJobDetail
      entryReason
      selfPr
      consideration
      resumeFile
      resumeFileUrl
      resumeFileName
      position {
        positionType
        id
        name
        department
        imageUrl
        isBookmarked
        workType
        mission
        pointsToLearn
        applicationRequirement
        requiredExperience
        requiredEnviroment
        workingHour
        annualIncome
        arrivalFrequency
        transfer
        socialInsurance
        capacity
        isAllowRemote
        periodStartedAt
        periodEndedAt
        nearestStation
        authorComment
        contactEmails
        detailImageUrls
        detailCaptions
        organization {
          id
          name
        }
        jobClassification {
          id
          name
        }
        offices {
          id
          name
          address
        }
        employmentTypes {
          id
          name
        }
        contactUsers {
          id
          firstName
          lastName
          avatarUrl
          email
        }
        positionTag {
          id
          tag {
            id
            name
          }
        }
      }
      approveUser {
        id
        lastName
        firstName
        email
      }
      entryType
      currentEntryFlowStep {
        id
        no
      }
      entryPledgeAgreement {
        id
      }
      userPermissions
      isTemporarySaved
      sendBackReason
      unreadOrLastEntryMessageId
      remark
    }
  }
`

export interface QueryEntryDetailResponse {
  entry: {
    id: string
    firstName: string
    firstNameKana: string
    lastName: string
    lastNameKana: string
    phoneNumber: string
    email: string
    homeOrganizationName: string
    organizationName: string
    travelTime: number
    zipCode: string
    address1: string
    address2: string
    address3: string
    currentIncome: number
    salaryType: SalaryType
    basicSalary: number | null
    overtimePay: number | null
    jobAllowance: number | null
    bonus: number | null
    currentJobDetail: string
    entryReason: string
    selfPr: string
    consideration: string
    resumeFile: string
    resumeFileUrl: string
    resumeFileName: string
    position: {
      positionType: PositionType
      id: string
      name: string
      department: string
      imageUrl: string
      isBookmarked: boolean
      workType: number
      mission: string
      pointsToLearn: string
      applicationRequirement: string
      requiredExperience: string
      requiredEnviroment: string
      workingHour: string
      annualIncome: string
      arrivalFrequency: string
      transfer: string
      socialInsurance: string
      capacity: number
      isAllowRemote: boolean
      periodStartedAt: Date
      periodEndedAt: Date
      nearestStation: string
      authorComment: string
      contactEmails: string[]
      detailImageUrls: string[]
      detailCaptions: string[]
      organization: {
        name: string
      }
      jobClassification: {
        id: string
        name: string
      } | null
      positionTag: Array<{
        tag: {
          id: string
          name: string
        }
      }>
      offices: Array<{
        id: string
        name: string
        address: string
      }>
      employmentTypes: Array<{
        id: string
        name: string
      }>
      contactUsers: Array<{
        firstName: string
        lastName: string
        avatarUrl: string
        email: string
      }>
    }
    approveUser: {
      id: string
      firstName: string
      lastName: string
      email: string
    } | null
    entryType: EntryType
    currentEntryFlowStep: {
      no: number
    }
    entryPledgeAgreement: {
      id: string
    } | null
    userPermissions: EntryUserPermission[]
    isTemporarySaved: boolean
    sendBackReason: string
    unreadOrLastEntryMessageId: string | null
    remark: string | null
  }
}

export const QueryEntryPersonnelNote = gql`
  query entryPersonnelNote($id: ID!) {
    entry(id: $id) {
      id
      entryType
      entryPersonnelNotes {
        id
        text
        createdAt
        user {
          id
          firstName
          lastName
          avatarUrl
        }
      }
    }
  }
`

export interface QueryEntryPersonnelNoteResponse {
  entry: {
    entryType: number
    entryPersonnelNotes: Array<{
      id: string
      text: string
      createdAt: Date
      user: {
        firstName: string
        lastName: string
        avatarUrl: string
      }
    }>
  }
}

export const QueryEntryActionHistories = gql`
  query entryActionHistories($id: ID!) {
    entry(id: $id) {
      id
      entryType
      entryActionHistories {
        id
        category
        createdAt
        content
        detail
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export interface QueryEntryActionHistoriesResponse {
  entry: {
    entryType: number
    entryActionHistories: Array<{
      id: string
      category: EntryActionCategory
      createdAt: string
      content: EntryActionHistoryContent
      detail: string
      user: {
        firstName: string
        lastName: string
      } | null
    }>
  }
}

export const MutationEntryPersonnelNote = gql`
  mutation entryPersonnelNote($input: CreateEntryPersonnelNoteInput!) {
    createEntryPersonnelNote(input: $input) {
      id
      text
    }
  }
`

export interface MutationEntryPersonnelNoteResponse {
  createEntryPersonnelNote: {
    id: string
    text: string
  }
}

export const QueryEntryReport = gql`
  query entryReport($id: ID!) {
    entry(id: $id) {
      user {
        id
      }
      currentEntryFlowStep {
        id
        no
      }
      kickoffFile
      kickoffFileName
      kickoffFileUrl
      kickoffFileUploadedAt
      reflectionFile
      reflectionFileName
      reflectionFileUrl
      reflectionFileUploadedAt
      entryType
      userPermissions
    }
  }
`

export interface QueryEntryReportResponse {
  entry: {
    user: {
      id: string
    }
    currentEntryFlowStep: {
      no: number
    }
    kickoffFile: string
    kickoffFileName: string
    kickoffFileUrl: string
    kickoffFileUploadedAt: string
    reflectionFile: string
    reflectionFileName: string
    reflectionFileUrl: string
    reflectionFileUploadedAt: string
    entryType: EntryType
    userPermissions: EntryUserPermission[]
  }
}

export const QueryEntryInterviews = gql`
  query entryInterviews($id: ID!) {
    entry(id: $id) {
      id
      entryType
      user {
        id
      }
      currentEntryFlowStep {
        id
        name
        no
      }
      position {
        id
        organization {
          id
        }
      }
      interviews {
        id
        status
        resultStatus
        entryFlowStep {
          id
          no
          name
        }
        interviewerUsers {
          id
          user {
            id
            firstName
            lastName
            email
          }
        }
        latestSchedule {
          id
          selectedDate {
            id
            startAt
            endAt
            location
            locationDetail
          }
        }
      }
    }
  }
`
export interface QueryEntryInterviewsResponse {
  entry: {
    id: string
    entryType: number
    user: {
      id: string
    }
    currentEntryFlowStep: {
      id: string
      name: string
      no: number
    }
    position: {
      organization: {
        id: string
      }
    }
    interviews: Array<{
      id: string
      status: InterviewStatus
      resultStatus: InterviewResultStatus
      entryFlowStep: {
        id: string
        name: string
        no: number
      }
      interviewerUsers: Array<{
        user: {
          id: string
          firstName: string
          lastName: string
          email: string
        }
      }>
      latestSchedule: {
        selectedDate: {
          startAt: string
          endAt: string
          location: InterviewLocation
          locationDetail: string
        } | null
      } | null
    }>
  }
}

export const MutationCreateInterviewAndInterviewerUser = gql`
  mutation createInterviewAndInterviewerUser($input: CreateInterviewAndInterviewerUserInput!) {
    createInterviewAndInterviewerUser(input: $input) {
      id
    }
  }
`

export interface MutationCreateInterviewAndInterviewerUserResponse {
  createInterviewAndInterviewerUser: {
    id: string
  }
}

export const MutationUpdateInterviewerUser = gql`
  mutation updateInterviewerUser($input: UpdateInterviewerUserInput!) {
    updateInterviewerUser(input: $input) {
      id
    }
  }
`

export interface MutationUpdateInterviewerUserResponse {
  updateInterviewerUser: {
    id: string
  }
}

export const MutationDeleteInterviewAndInterviewerUser = gql`
  mutation deleteInterviewAndInterviewerUser($input: DeleteInterviewAndInterviewerUserInput!) {
    deleteInterviewAndInterviewerUser(input: $input)
  }
`

export interface MutationDeleteInterviewAndInterviewerUserResponse {
  deleteInterviewAndInterviewerUser: boolean
}

export const MutationUpdateEntryFile = gql`
  mutation updateEntryFile($input: UpdateEntryFileInput!) {
    updateEntryFile(input: $input) {
      id
      kickoffFile
      kickoffFileUrl
      kickoffFileName
      kickoffFileUploadedAt
      reflectionFile
      reflectionFileUrl
      reflectionFileName
      reflectionFileUploadedAt
    }
  }
`

export interface MutationUpdateEntryFileResponse {
  updateEntryFile: {
    id: string
    kickoffFile: string
    kickoffFileUrl: string
    kickoffFileName: string
    kickoffFileUploadedAt: string
    reflectionFile: string
    reflectionFileUrl: string
    reflectionFileName: string
    reflectionFileUploadedAt: string
  }
}

export const MutationUpdateEntriesFlowStep = gql`
  mutation updateEntriesFlowStep($input: UpdateEntriesFlowStepInput!) {
    updateEntriesFlowStep(input: $input)
  }
`

export const QueryEntryInterviewResult = gql`
  query entryInterviewResult($id: ID!) {
    entry(id: $id) {
      id
      entryType
      interviews {
        id
        status
        resultStatus
        resultPros
        resultCons
        resultMemo
        userPermissions
        interviewerUsers {
          id
          user {
            id
            firstName
            lastName
          }
        }
        latestSchedule {
          id
          selectedDate {
            id
            startAt
            endAt
            location
            locationDetail
          }
        }
      }
    }
  }
`

export interface QueryEntryInterviewResultResponse {
  entry: {
    id: string
    entryType: number
    interviews: Array<{
      id: string
      status: InterviewStatus
      resultStatus: InterviewResultStatus
      resultPros: string
      resultCons: string
      resultMemo: string
      userPermissions: InterviewUserPermission[]
      interviewerUsers: Array<{
        user: {
          firstName: string
          lastName: string
        }
      }>
      latestSchedule: {
        selectedDate: {
          startAt: string
          endAt: string
          location: InterviewLocation
          locationDetail: string
        } | null
      } | null
    }>
  }
}

export interface UpdateInterviewResultInput {
  input: {
    interviewId: string
    resultStatus: InterviewResultStatus
    resultPros: string
    resultCons: string
    resultMemo: string
    updateType: InterviewResultUpdateType | null
  }
}

export const MutationUpdateInterviewResult = gql`
  mutation updateInterviewResult($input: UpdateInterviewResultInput!) {
    updateInterviewResult(input: $input) {
      id
    }
  }
`

export interface MutationUpdateInterviewResultResponse {
  updateInterviewResult: {
    id: string
  }
}

export const QueryMyEntries = gql`
  query myEntries($entryTypes: [Int!], $isIncludeClosed: Boolean, $id: ID, $offset: Int, $limit: Int) {
    entries(entryTypes: $entryTypes, isIncludeClosed: $isIncludeClosed, id: $id, offset: $offset, limit: $limit) {
      items {
        id
        entryType
        createdAt
        currentEntryFlowStep {
          id
          name: candidateVisibleName
          no
        }
        position {
          id
          name
          organization {
            id
            name
          }
          applicationEndedAt
        }
        lastEntryMessage {
          id
          message
          isRead
          interviewSchedule {
            id
          }
          createdAt
        }
        isTemporarySaved
        election {
          id
          priority
        }
        isSuggest
      }
    }
    doubleworkEntries(isIncludeClosed: $isIncludeClosed, limit: $limit) {
      items {
        id
        createdAt
        currentEntryFlowStep {
          name: candidateVisibleName
          no
        }
        position: doublework {
          id
          name
          organization {
            id
            name
          }
          applicationEndedAt
        }
        lastEntryMessage {
          id
          message
          isRead
          interviewSchedule: doubleworkInterviewSchedule {
            id
          }
          createdAt
        }
        isTemporarySaved
      }
    }
    careerScoutEntries(isIncludeClosed: $isIncludeClosed, limit: $limit) {
      items {
        id
        entryType
        createdAt
        currentEntryFlowStep {
          name: candidateVisibleName
          no
        }
        position: careerScout {
          id
          name
          organization {
            id
            name
          }
          applicationEndedAt
        }
        lastEntryMessage {
          id
          message
          isRead
          interviewSchedule: careerScoutInterviewSchedule {
            id
          }
          createdAt
        }
        enteredAt
        isTemporarySaved
        careerScoutOffer {
          id
        }
      }
    }
  }
`

export interface QueryMyEntriesResponse {
  entries: {
    items: Array<{
      id: string
      entryType: EntryType
      createdAt: string
      currentEntryFlowStep: {
        name: string
        no: number
      }
      position: {
        id: string
        name: string
        organization: {
          name: string
        }
        applicationEndedAt: string
      }
      lastEntryMessage: {
        message: string
        isRead: boolean
        interviewSchedule: {
          id: string
        } | null
        createdAt: string
      } | null
      isTemporarySaved: boolean
      election: {
        id: string
        priority: ElectionPriority
      }
      isSuggest: boolean
    }>
  }
  doubleworkEntries: {
    items: Array<{
      id: string
      entryType: EntryType
      createdAt: string
      currentEntryFlowStep: {
        name: string
        no: number
      }
      position: {
        id: string
        name: string
        organization: {
          name: string
        }
        applicationEndedAt: string
      }
      lastEntryMessage: {
        message: string
        isRead: boolean
        interviewSchedule: {
          id: string
        } | null
        createdAt: string
      } | null
      isTemporarySaved: boolean
    }>
  }
  careerScoutEntries: {
    items: Array<{
      id: string
      entryType: CareerScoutEntryType
      createdAt: string
      currentEntryFlowStep: {
        name: string
        no: number
      }
      position: {
        id: string
        name: string
        organization: {
          name: string
        }
        applicationEndedAt: string
      }
      lastEntryMessage: {
        message: string
        isRead: boolean
        interviewSchedule: {
          id: string
        } | null
        createdAt: string
      } | null
      enteredAt: string
      isTemporarySaved: boolean
      careerScoutOffer: {
        id: string
      }
    }>
  }
}

export const QueryMyDoubleworkEntriesLimitOne = gql`
  query queryMyDoubleworkEntriesLimitOne {
    doubleworkEntries(isIncludeClosed: true, limit: 1) {
      items {
        id
      }
    }
  }
`
export interface QueryMyDoubleworkEntriesLimitOneResponse {
  doubleworkEntries: {
    items: Array<{
      id: string
    }>
  }
}

export const QueryEntryUserInterviews = gql`
  query entryUserInterviews($id: ID!) {
    entry(id: $id) {
      entryType
      interviews {
        id
        status
        reflectionMemo
        entryFlowStep {
          id
          no
          name
        }
        interviewerUsers {
          id
          user {
            id
            firstName
            lastName
            email
            avatarUrl
          }
        }
        latestSchedule {
          id
          selectedDate {
            id
            startAt
            endAt
            location
            locationDetail
          }
        }
      }
      userPermissions
    }
  }
`

export interface QueryEntryUserInterviewsResponse {
  entry: {
    entryType: number
    interviews: Array<{
      id: string
      status: InterviewStatus
      reflectionMemo: string
      entryFlowStep: {
        name: string
        no: number
      }
      interviewerUsers: Array<{
        user: {
          firstName: string
          lastName: string
          email: string
          avatarUrl: string
        }
      }>
      latestSchedule: {
        selectedDate: {
          startAt: string
          endAt: string
          location: InterviewLocation
          locationDetail: string
        } | null
      } | null
    }>
    userPermissions: EntryUserPermission[]
  }
}

export const MutationUpdateInterviewReflection = gql`
  mutation updateInterviewReflection($input: UpdateInterviewReflectionInput!) {
    updateInterviewReflection(input: $input) {
      id
      reflectionMemo
    }
  }
`

export interface MutationUpdateInterviewReflectionResponse {
  updateInterviewReflection: {
    id: string
    reflectionMemo: string
  }
}

export interface UpdateInterviewReflectionInput {
  input: {
    interviewId: string
    reflectionMemo: string
  }
}

export const QueryEntryResult = gql`
  query entryResult($id: ID!) {
    entry(id: $id) {
      id
      entryType
      currentEntryFlowStep {
        id
        no
      }
      latestNoneCasualInterview {
        id
        resultPros
        resultCons
      }
      homeOrganizationName
      userPermissions
    }
  }
`

export interface QueryEntryResultResponse {
  entry: {
    entryType: EntryType
    currentEntryFlowStep: {
      no: number
    }
    latestNoneCasualInterview: {
      id: string
      resultPros: string
      resultCons: string
    } | null
    homeOrganizationName: string
    userPermissions: EntryUserPermission[]
  }
}

export const QueryConsultationCategory = gql`
  query consultationCategory {
    consultationCategories {
      id
      name
      isDetailRequired
    }
  }
`

export interface QueryConsultationCategoryRespose {
  consultationCategories: Array<{
    id: string
    name: string
    isDetailRequired: boolean
  }>
}

export interface CreateConsultationRequestInput {
  consultationCategoryIds: string[]
  categoryDescription?: string
  detail: string
  desiredDate: string
}

export const MutationCreateConsultationRequest = gql`
  mutation createConsultationRequest($input: CreateConsultationRequestInput!) {
    createConsultationRequest(input: $input) {
      id
    }
  }
`

export interface MutationCreateConsultationRequestResponse {
  createConsultationRequest: {
    id: string
  }
}

export const QueryMe = gql`
  query me {
    me {
      id
      email
      isAccountAdmin
      isDwAccountAdmin
      isScoutAccountAdmin
      firstName
      lastName
      firstNameKana
      lastNameKana
      employeeNumber
      employmentType {
        name
      }
      avatar
      avatarUrl
      isNoWebPush
      homeOrganization {
        id
        canIndividualScout
        name
      }
      organizations {
        id
        isMain
        isAdmin
        isCCAdmin
        isJTAdmin
        isDwPublisherAdmin
        isDwUserAdmin
        isScoutAdmin
        isDepartmentManager
        organization {
          id
          name
        }
      }
      userSettings {
        id
        dmName
        isDmUserAdminAvatar
        isReceiveNotificationMail
        isReceiveBulkMail
        isReceiveCcMail
        ccMail
        isVerifiedCcMail
        isReceiveWebPush
        worksheetViewId
        isReceiveGccAdminMail
        isReceiveIccAdminMail
        isReceiveJtAdminMail
        isReceiveDwAdminMail
        isReceiveScAdminMail
      }
      userWebPushSubscribes {
        id
        endPoint
        p256dhKey
        authKey
      }
    }
  }
`
export interface QueryMeResponse {
  me: {
    id: string
    email: string
    isAccountAdmin: boolean
    isDwAccountAdmin: boolean
    isScoutAccountAdmin: boolean
    firstName: string
    lastName: string
    firstNameKana: string
    lastNameKana: string
    employeeNumber: string
    employmentType: { name: string }
    avatar: string
    avatarUrl: string
    isNoWebPush: boolean
    homeOrganization: {
      id: string
      canIndividualScout: boolean
      name: string
    }
    organizations: Array<{
      id: string
      isMain: boolean
      isAdmin: boolean
      isCCAdmin: boolean
      isJTAdmin: boolean
      isDwPublisherAdmin: boolean
      isDwUserAdmin: boolean
      isScoutAdmin: boolean
      isDepartmentManager: boolean
      organization: {
        id: string
        name: string
      }
    }>
    userSettings: {
      id: string
      dmName: string
      isDmUserAdminAvatar: boolean
      isReceiveNotificationMail: boolean
      isReceiveBulkMail: boolean
      isReceiveCcMail: boolean
      ccMail: string
      isVerifiedCcMail: boolean
      isReceiveWebPush: boolean
      worksheetViewId: string | null
      isReceiveGccAdminMail: boolean
      isReceiveIccAdminMail: boolean
      isReceiveJtAdminMail: boolean
      isReceiveDwAdminMail: boolean
      isReceiveScAdminMail: boolean
    }
    userWebPushSubscribes: Array<{
      endPoint: string
      p256dhKey: string
      authKey: string
    }>
  }
}

export const MutationUpdateUserProfile = gql`
  mutation updateUserProfile($input: UpdateUserProfileInput!) {
    updateUserProfile(input: $input) {
      id
      email
      isAccountAdmin
      firstName
      lastName
      firstNameKana
      lastNameKana
      employeeNumber
      avatarUrl
    }
  }
`

export interface MutationUpdateUserProfileResponse {
  updateUserProfile: {
    id: string
    email: string
    isAccountAdmin: boolean
    firstName: string
    lastName: string
    firstNameKana: string
    lastNameKana: string
    employeeNumber: string
    avatarUrl: string
  }
}

export const MutationUpdateUserSettings = gql`
  mutation updateUserSettings($input: UpdateUserSettingsInput!) {
    updateUserSettings(input: $input) {
      id
      dmName
      isDmUserAdminAvatar
      isReceiveNotificationMail
      isReceiveBulkMail
      isReceiveCcMail
      ccMail
      isReceiveWebPush
    }
  }
`

export interface MutationUpdateUserSettingsResponse {
  updateUserSettings: {
    id: string
    dmName: string
    isDmUserAdminAvatar: boolean
    isReceiveNotificationMail: boolean
    isReceiveBulkMail: boolean
    isReceiveCcMail: boolean
    ccMail: string
    isVerifiedCcMail: boolean
    isReceiveWebPush: boolean
  }
}

export const MutationSendEmailVerificationCode = gql`
  mutation sendEmailVerificationCode($input: SendEmailVerificationCodeInput!) {
    sendEmailVerificationCode(input: $input)
  }
`

export interface MutationSendEmailVerificationCodeResponse {
  sendEmailVerificationCode: boolean
}

export const MutationVerifyEmail = gql`
  mutation verifyEmail($input: VerifyEmailInput!) {
    verifyEmail(input: $input) {
      email
      type
    }
  }
`
export interface MutationVerifyEmailResponse {
  verifyEmail: {
    email: string
    type: EmailVerificationType
  }
}

export const MutationCreateTemplate = gql`
  mutation createTemplate($input: CreateTemplateInput!) {
    createTemplate(input: $input)
  }
`
export interface MutationCreateTemplatesResponse {
  createTemplate: boolean
}

export const MutationUpdateTemplate = gql`
  mutation updateTemplate($input: UpdateTemplateInput!) {
    updateTemplate(input: $input)
  }
`
export interface MutationUpdateTemplatesResponse {
  updateTemplate: boolean
}

export const QueryOrganizations = gql`
  query organizations($ids: [ID!]) {
    organizations(ids: $ids) {
      id
      name
    }
  }
`
export interface QueryOrganizationsResponse {
  organizations: Array<{
    id: string
    name: string
  }>
}

export const QueryEntryFlows = gql`
  query entryFlows($organizationId: ID) {
    entryFlows(organizationId: $organizationId) {
      value: id
      label: name
      entryType
      steps {
        value: id
        label: name
        no
      }
    }
  }
`
export interface QueryEntryFlowsResponse {
  entryFlows: Array<
    SelectOption & {
      entryType: EntryType
      steps: Array<SelectOption & { no: number }>
    }
  >
}

export const QueryInterviewerUsers = gql`
  query interviewerUsers($name: String!) {
    interviewerUsers(name: $name) {
      id
      lastName
      firstName
      email
    }
  }
`

export interface QueryInterviwerUsersResponse {
  interviewerUsers: Array<{
    id: string
    lastName: string
    firstName: string
    email: string
  }>
}

export const QueryManageInterviewEntries = gql`
  query manageInterviewEntries(
    $entryFlowId: ID
    $entryFlowStepId: ID
    $interviewStatus: Int
    $interviewResultStatus: Int
    $interviewerUserId: ID
    $isOnlyCurrentPeriod: Boolean
    $offset: Int
    $limit: Int
  ) {
    manageInterviewEntries(
      entryFlowId: $entryFlowId
      entryFlowStepId: $entryFlowStepId
      interviewStatus: $interviewStatus
      interviewResultStatus: $interviewResultStatus
      interviewerUserId: $interviewerUserId
      isOnlyCurrentPeriod: $isOnlyCurrentPeriod
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        position {
          id
          name
        }
        currentEntryFlowStep {
          id
          name
          no
        }
        currentInterview {
          id
          status
          resultStatus
          interviewerUsers {
            id
            user {
              id
              firstName
              lastName
            }
          }
          latestSchedule {
            id
            selectedDate {
              id
              startAt
              endAt
            }
          }
        }
        employeeNumber
        firstName
        lastName
      }
      total
    }
  }
`

export interface QueryManageInterviewEntriesResponse {
  manageInterviewEntries: {
    items: Array<{
      id: string
      position: {
        name: string
      }
      currentEntryFlowStep: {
        name: string
        no: number
      }
      currentInterview: {
        id: string
        status: InterviewStatus
        resultStatus: InterviewResultStatus
        interviewerUsers: Array<{
          user: {
            firstName: string
            lastName: string
          }
        }>
        latestSchedule: {
          selectedDate: {
            startAt: Date
            endAt: Date
          } | null
        } | null
      }
      employeeNumber: string
      firstName: string
      lastName: string
    }>
    total: number
  }
}

export interface UpdateInterviewStatusInput {
  input: {
    entryIds: string[]
    interviewStatus: number
  }
}

export const MutationUpdateInterviews = gql`
  mutation updateInterviews($input: UpdateInterviewStatusInput!) {
    updateInterviewStatus(input: $input)
  }
`
export interface MutationUpdateInterviewsResponse {
  updateInterviewStatus: boolean
}

export const QueryManagedEntries = gql`
  query manageEntries(
    $entryFlowId: ID
    $entryFlowStepId: ID
    $employeeNumber: String
    $interviewStatus: Int
    $electStatus: Int
    $period: String
    $isOnlySupportRequired: Boolean
    $id: ID
    $offset: Int
    $limit: Int
  ) {
    manageEntries(
      entryFlowId: $entryFlowId
      entryFlowStepId: $entryFlowStepId
      employeeNumber: $employeeNumber
      interviewStatus: $interviewStatus
      electStatus: $electStatus
      period: $period
      isOnlySupportRequired: $isOnlySupportRequired
      id: $id
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        entryType
        kickoffFile
        reflectionFile
        currentEntryFlowStep {
          id
          name
          no
        }
        position {
          id
          name
          organization {
            name
          }
        }
        employeeNumber
        firstName
        lastName
        user {
          id
        }
        approveUser {
          id
        }
        homeOrganizationName
        organizationName
        employmentTypeName
        hasUnreadEntryUserMessage
        election {
          id
          status
          priority
        }
        isSuggest
        isSupportRequired
        userPermissions
      }
      total
    }
  }
`

export interface QueryManagedEntriesResponse {
  manageEntries: {
    items: Array<{
      id: string
      entryType: EntryType
      kickoffFile: string
      reflectionFile: string
      currentEntryFlowStep: {
        name: string
        no: JTFlowStepNo | GccFlowStepNo | IccFlowStepNo
      }
      position: {
        id: string
        name: string
      }
      employeeNumber: string
      firstName: string
      lastName: string
      user: {
        id: string
      }
      approveUser: {
        id: string
      }
      homeOrganizationName: string
      organizationName: string
      employmentTypeName: string
      hasUnreadEntryUserMessage: boolean
      election: {
        status: ElectionStatus
        priority: ElectionPriority
      } | null
      isSuggest: boolean
      isSupportRequired: boolean | null
      userPermissions: EntryUserPermission[]
    }>
    total: number
  }
}

export interface AcceptOrDeclineOfferInput {
  entryId: string
  isAccepted: boolean
  declineReasonCategories: EntryDeclineReasonCategory[] | null
  declineReasonDetail: string | null
}
export interface MutationAcceptOrDeclineOfferResponse {
  acceptOrDeclineOffer: {
    id: string
    firstName: string
    firstNameKana: string
    lastName: string
    lastNameKana: string
    phoneNumber: string
    email: string
    travelTime: number
    zipCode: string
    address1: string
    address2: string
    address3: string
    currentIncome: number
    currentJobDetail: string
    entryReason: string
    selfPr: string
    consideration: string
    resumeFileUrl: string
    resumeFileName: string
    position: {
      positionType: PositionType
    }
    approveUser: {
      firstName: string
      lastName: string
    } | null
    entryType: EntryType
    currentEntryFlowStep: {
      no: number
    }
    userPermissions: EntryUserPermission[]
  }
}

export const MutationAcceptOrDeclineOffer = gql`
  mutation acceptOrDeclineOffer($input: AcceptOrDeclineOfferInput!) {
    acceptOrDeclineOffer(input: $input) {
      id
      firstName
      firstNameKana
      lastName
      lastNameKana
      phoneNumber
      email
      travelTime
      zipCode
      address1
      address2
      address3
      currentIncome
      currentJobDetail
      entryReason
      selfPr
      consideration
      resumeFileUrl
      resumeFileName
      position {
        positionType
      }
      approveUser {
        firstName
        lastName
      }
      entryType
      currentEntryFlowStep {
        no
      }
      userPermissions
    }
  }
`

export interface BulkCreateEntryMessageInput {
  entryIds: string[]
  message: string
  files: UploadFile[]
}
export const MutationBulkCreateEntryMessages = gql`
  mutation bulkCreateEntryMessages($input: BulkCreateEntryMessageInput!) {
    bulkCreateEntryMessages(input: $input)
  }
`
export interface MutationBulkCreateEntryMessagesResponse {
  bulkCreateEntryMessages: boolean
}

export const MutationBulkExportEntries = gql`
  mutation bulkExportEntries($input: BulkExportPositionsArgs!) {
    bulkExportEntries(input: $input)
  }
`

export interface MutationBulkExportEntriesResponse {
  bulkExportEntries: boolean
}

export const QueryInformations = gql`
  query informations($limit: Int) {
    informations(limit: $limit) {
      id
      title
      openAt
      content
    }
  }
`

export interface QueryInformationsResponse {
  informations: Array<{
    id: string
    title: string
    openAt: string
    content: string
  }>
}

export const QueryManageInformations = gql`
  query manageInformations($id: ID) {
    manageInformations(id: $id) {
      id
      title
      openAt
      content
      status
      createdAt
      modifiedAt
    }
  }
`

export interface QueryManageInformationsResponse {
  manageInformations: Array<{
    id: string
    title: string
    openAt: string
    content: string
    status: InformationStatus
    createdAt: string
    modifiedAt: string
  }>
}

export const MutationSuspendInformations = gql`
  mutation suspendInformations($informationIds: [ID!]!) {
    suspendInformations(informationIds: $informationIds)
  }
`

export interface MutationSuspendInformationsResponse {
  suspendInformations: boolean
}

export const MutationCreateInformations = gql`
  mutation createInformation($input: CreateInformationInput!) {
    createInformation(input: $input) {
      id
    }
  }
`

export interface MutationCreateInformationsResponse {
  createInformation: {
    id: string
  }
}

export const MutationUpdateInformations = gql`
  mutation updateInformation($input: UpdateInformationInput!) {
    updateInformation(input: $input) {
      id
    }
  }
`

export interface MutationUpdateInformationsResponse {
  updateInformation: {
    id: string
  }
}

export const MutationDeleteEntries = gql`
  mutation deleteEntries($entryIds: [ID!]!) {
    deleteEntries(entryIds: $entryIds)
  }
`

export interface MutationDeleteEntriesResponse {
  deleteEntries: boolean
}

export const QueryEntryMessageNotifications = gql`
  query entryMessageNotifications($after: String, $before: String, $limit: Int) {
    entryMessageNotifications(after: $after, before: $before, limit: $limit) {
      items {
        id
        entryMessage {
          id
          senderUserAvatarUrl
          isUseAdminAvatar
          isRead
        }
        doubleworkEntryMessage {
          id
          senderUserAvatarUrl
          isUseAdminAvatar
          isRead
        }
        careerScoutEntryMessage {
          id
          senderUserAvatarUrl
          isUseAdminAvatar
          isRead
        }
        message
        url
        createdAt
      }
      hasNext
      startCursor
      endCursor
    }
  }
`

export interface QueryEntryMessageNotificationsResponse {
  entryMessageNotifications: {
    items: Array<{
      id: string
      entryMessage: {
        id: string
        senderUserAvatarUrl: string
        isUseAdminAvatar: boolean
        isRead: boolean
      } | null
      doubleworkEntryMessage: {
        id: string
        senderUserAvatarUrl: string
        isUseAdminAvatar: boolean
        isRead: boolean
      } | null
      careerScoutEntryMessage: {
        id: string
        senderUserAvatarUrl: string
        isUseAdminAvatar: boolean
        isRead: boolean
      } | null
      message: string
      url: string
      createdAt: Date
    }>
    hasNext: boolean
    endCursor: string
    startCursor: string
  }
}

export const SubscriptionNewEntryMessageNotification = gql`
  subscription newEntryMessageNotification {
    newEntryMessageNotification {
      id
      entryMessage {
        id
        entry {
          id
        }
        isRead
        senderUserAvatarUrl
        isUseAdminAvatar
      }
      doubleworkEntryMessage {
        id
        doubleworkEntry {
          id
        }
        isRead
        senderUserAvatarUrl
        isUseAdminAvatar
      }
      careerScoutEntryMessage {
        id
        careerScoutEntry {
          id
        }
        isRead
        senderUserAvatarUrl
        isUseAdminAvatar
      }
      message
      url
      createdAt
    }
  }
`

export interface SubscriptionNewEntryMessageNotificationResponse {
  newEntryMessageNotification: {
    id: string
    entryMessage: {
      id: string
      entry: {
        id: string
      }
      isRead: boolean
      senderUserAvatarUrl: string
      isUseAdminAvatar: boolean
    } | null
    doubleworkEntryMessage: {
      id: string
      doubleworkEntry: {
        id: string
      }
      isRead: boolean
      senderUserAvatarUrl: string
      isUseAdminAvatar: boolean
    } | null
    careerScoutEntryMessage: {
      id: string
      careerScoutEntry: {
        id: string
      }
      isRead: boolean
      senderUserAvatarUrl: string
      isUseAdminAvatar: boolean
    } | null
    message: string
    url: string
    createdAt: Date
  }
}

export const QueryNotifications = gql`
  query notifications($after: String, $before: String, $limit: Int) {
    notifications(after: $after, before: $before, limit: $limit) {
      items {
        id
        type
        senderUser {
          id
          avatarUrl
        }
        isRead
        message
        url
        fileKey
        createdAt
      }
      hasNext
      startCursor
      endCursor
    }
  }
`

export const QueryMypage = gql`
  query mypage($entryTypes: [Int!], $isIncludeClosed: Boolean, $entriesLimit: Int, $informationsLimit: Int) {
    localState {
      pa @client
    }
    me {
      id
      email
      userSettings {
        id
        settableEmail
      }
    }
    entries(entryTypes: $entryTypes, isIncludeClosed: $isIncludeClosed, limit: $entriesLimit) {
      total
      items {
        id
        createdAt
        entryType
        isTemporarySaved
        currentEntryFlowStep {
          id
          no
          candidateVisibleName
        }
        position {
          id
          name
          organization {
            id
            name
          }
        }
        lastEntryMessage {
          id
          createdAt
        }
        currentInterview {
          id
          status
          latestSchedule {
            id
            selectedDate {
              id
              startAt
              endAt
              location
              locationDetail
            }
          }
        }
        election {
          id
          priority
        }
        isSuggest
      }
    }
    doubleworkEntries(isIncludeClosed: $isIncludeClosed, limit: $entriesLimit) {
      items {
        id
        createdAt
        isTemporarySaved
        doublework {
          id
          name
          organization {
            id
            name
          }
        }
        currentEntryFlowStep {
          no
          candidateVisibleName
        }
        lastEntryMessage {
          id
          createdAt
        }
        latestSchedule {
          id
          selectedDate {
            id
            startAt
            endAt
            location
            locationDetail
          }
        }
      }
    }
    careerScoutEntries(isIncludeClosed: $isIncludeClosed, limit: $entriesLimit) {
      items {
        id
        createdAt
        entryType
        isTemporarySaved
        currentEntryFlowStep {
          no
          candidateVisibleName
        }
        careerScout {
          id
          name
          organization {
            id
            name
          }
        }
        lastEntryMessage {
          id
          createdAt
        }
        currentInterview {
          id
          status
          latestSchedule {
            id
            selectedDate {
              id
              startAt
              endAt
              location
              locationDetail
            }
          }
        }
        enteredAt
        careerScoutOffer {
          id
        }
      }
    }
    informations(limit: $informationsLimit) {
      id
      title
      openAt
    }
    myTask {
      entryUserUnreadMessagesTask
      entryUserScheduleAdjustmentTask
      entryUserInterviewScheduleTask
      entryUserKickoffTask
      entryUserOfferTask
      entryUserSurveyTask
      entryUserSurvey2Task
      entryUserAgreementTask
      entryUserAgreementSecondmentTask
      entryUserReflectionTask
      adminPositionApprovalTask
      adminUnreadMessagesTask
      adminInterviewerSettingTask
      adminScheduleAdjustmentTask
      adminOfferTask
      adminAgreementTask
      interviewerUnreadMessagesTask
      interviewerScheduleAdjustmentTask
      interviewerInterviewScheduleTask
      interviewerEvaluationTask
      approveUserEntryApprovalTask
      workshopTask
      doubleworkApprovalTask
      doubleworkEntryApprovalTask
      doubleworkEntryUserUnreadMessagesTask
      doubleworkAdminUnreadMessagesTask
      doubleworkEntryUserScheduleAdjustmentTask
      doubleworkEntryUserAgreementTask
      doubleworkEntryContractConfirmationTask
      doubleworkEntryContractContinuationUnapprovedTask
      doubleworkEntryTerminationPreparationTask
      careerScoutAdminAgreementTask
      careerScoutAdminInterviewerSettingTask
      careerScoutAdminOfferTask
      careerScoutAdminPositionApprovalTask
      careerScoutAdminScheduleAdjustmentTask
      careerScoutAdminUnreadMessagesTask
      careerScoutAdminUnreadOfferMessagesTask
      careerScoutEntryUserGcsAgreementTask
      careerScoutEntryUserIcsAgreementTask
      careerScoutEntryUserGcsAgreementSecondmentTask
      careerScoutEntryUserInterviewScheduleTask
      careerScoutEntryUserOfferTask
      careerScoutEntryUserScheduleAdjustmentTask
      careerScoutEntryUserUnreadMessagesTask
      careerScoutEntryUserUnreadOfferMessagesTask
      careerScoutEntryUserUnopenedOfferTask
      careerScoutInterviewerEvaluationTask
      careerScoutInterviewerInterviewScheduleTask
      careerScoutInterviewerScheduleAdjustmentTask
      careerScoutInterviewerUnreadMessagesTask
    }
  }
`

export interface QueryMypageResponse {
  localState: LocalState
  me: {
    id: string
    email: string
    userSettings: {
      id: string
      settableEmail: string
    }
  }
  entries: {
    total: number
    items: Array<{
      id: string
      createdAt: string
      entryType: EntryType
      isTemporarySaved: boolean
      currentEntryFlowStep: {
        no: number
        candidateVisibleName: string
      }
      position: {
        id: string
        name: string
        organization: {
          name: string
        }
      }
      lastEntryMessage: {
        id: string
        createdAt: string
      }
      currentInterview: {
        status: InterviewStatus
        latestSchedule: {
          selectedDate: {
            startAt: Date
            endAt: Date
            location: InterviewLocation
            locationDetail: string
          } | null
        } | null
      } | null
      election: {
        id: string
        priority: ElectionPriority
      }
      isSuggest: boolean
    }>
  }
  doubleworkEntries: {
    items: Array<{
      id: string
      createdAt: string
      isTemporarySaved: boolean
      doublework: {
        id: string
        name: string
        organization: {
          id: string
          name: string
        }
      }
      currentEntryFlowStep: {
        no: number
        candidateVisibleName: string
      }
      lastEntryMessage: {
        id: string
        createdAt: string
      }
      latestSchedule: {
        selectedDate: {
          startAt: Date
          endAt: Date
          location: InterviewLocation
          locationDetail: string
        } | null
      } | null
    }>
  }
  careerScoutEntries: {
    items: Array<{
      id: string
      createdAt: string
      isTemporarySaved: boolean
      entryType: CareerScoutEntryType
      currentEntryFlowStep: {
        no: number
        candidateVisibleName: string
      }
      careerScout: {
        id: string
        name: string
        organization: {
          name: string
        }
      }
      lastEntryMessage: {
        id: string
        createdAt: string
      }
      currentInterview: {
        status: InterviewStatus
        latestSchedule: {
          selectedDate: {
            startAt: Date
            endAt: Date
            location: InterviewLocation
            locationDetail: string
          } | null
        } | null
      } | null
      enteredAt: string
      careerScoutOffer: {
        id: string
      }
    }>
  }
  informations: Array<{
    id: string
    title: string
    openAt: Date
  }>
  myTask: {
    entryUserUnreadMessagesTask: {
      count: number
      entries: Array<{
        id: string
        positionName: string
        avatarUrl: string
        isUseAdminAvatar: boolean
      }>
    }
    entryUserScheduleAdjustmentTask: {
      entries: Array<{
        id: string
        positionName: string
      }>
    }
    entryUserInterviewScheduleTask: {
      entries: Array<{
        id: string
        interviewStartAt: Date
      }>
    }
    entryUserKickoffTask: MyEntriesIdTask
    entryUserOfferTask: MyEntriesIdTask
    entryUserSurveyTask: MyEntriesIdTask
    entryUserSurvey2Task: MyEntriesIdTask
    entryUserAgreementTask: MyEntriesIdTask
    entryUserReflectionTask: MyEntriesIdTask
    adminPositionApprovalTask: {
      count: number
      positions: Array<{
        id: string
        name: string
        positionType: PositionType
      }>
    }
    adminUnreadMessagesTask: MyEntriesCountAndDetailTask
    adminInterviewerSettingTask: MyEntriesCountAndDetailTask
    adminScheduleAdjustmentTask: MyEntriesCountAndDetailTask
    adminOfferTask: MyEntriesCountAndDetailTask
    adminAgreementTask: MyEntriesCountAndDetailTask
    interviewerUnreadMessagesTask: MyEntriesCountAndDetailTask
    interviewerScheduleAdjustmentTask: MyEntriesCountAndDetailTask
    interviewerInterviewScheduleTask: MyEntryInterviewsTask
    interviewerEvaluationTask: MyEntryInterviewsTask
    approveUserEntryApprovalTask: {
      count: number
      entries: Array<{
        id: string
        positionId: string
        avatarUrl: string
        userName: string
      }>
    }
    workshopTask: {
      ids: string[]
    }
    doubleworkApprovalTask: {
      count: number
      doubleworks: Array<{
        id: string
        name: string
      }>
    }
    doubleworkEntryApprovalTask: {
      count: number
      doubleworkEntries: Array<{
        id: string
        avatarUrl: string
        userName: string
      }>
    }
    doubleworkEntryUserUnreadMessagesTask: {
      count: number
      doubleworkEntries: Array<{
        id: string
        doubleworkName: string
        avatarUrl: string
        isUseAdminAvatar: boolean
      }>
    }
    doubleworkAdminUnreadMessagesTask: {
      count: number
      doubleworkEntries: Array<{
        id: string
        avatarUrl: string
        userName: string
      }>
    }
    doubleworkEntryUserScheduleAdjustmentTask: {
      doubleworkEntries: Array<{
        id: string
        doubleworkName: string
      }>
    }
    doubleworkEntryUserAgreementTask: {
      doubleworkEntries: Array<{
        id: string
      }>
    }
    doubleworkEntryContractConfirmationTask: {
      doubleworkEntries: Array<{
        id: string
      }>
    }
    doubleworkEntryContractContinuationUnapprovedTask: {
      count: number
      doubleworkEntries: Array<{
        id: string
        avatarUrl: string
        userName: string
      }>
    }
    doubleworkEntryTerminationPreparationTask: {
      count: number
      doubleworkEntries: Array<{
        id: string
        avatarUrl: string
        userName: string
      }>
    }
    careerScoutAdminAgreementTask: {
      count: number
      careerScoutEntries: Array<{
        id: string
        avatarUrl: string
        userName: string
      }>
    }
    careerScoutAdminInterviewerSettingTask: {
      count: number
      careerScoutEntries: Array<{
        id: string
        avatarUrl: string
        userName: string
      }>
    }
    careerScoutAdminOfferTask: {
      count: number
      careerScoutEntries: Array<{
        id: string
        avatarUrl: string
        userName: string
      }>
    }
    careerScoutAdminPositionApprovalTask: {
      count: number
      careerScouts: Array<{
        id: string
        name: string
      }>
    }
    careerScoutAdminScheduleAdjustmentTask: {
      count: number
      careerScoutEntries: Array<{
        id: string
        avatarUrl: string
        userName: string
      }>
    }
    careerScoutAdminUnreadMessagesTask: {
      count: number
      careerScoutEntries: Array<{
        id: string
        avatarUrl: string
        userName: string
      }>
    }
    careerScoutAdminUnreadOfferMessagesTask: {
      count: number
      careerScoutOffers: Array<{
        id: string
        avatarUrl: string
        userName: string
      }>
    }
    careerScoutEntryUserGcsAgreementTask: {
      careerScoutEntries: Array<{
        id: string
      }>
    }
    careerScoutEntryUserIcsAgreementTask: {
      careerScoutEntries: Array<{
        id: string
      }>
    }
    careerScoutEntryUserGcsAgreementSecondmentTask: {
      careerScoutEntries: Array<{
        id: string
      }>
    }
    careerScoutEntryUserInterviewScheduleTask: {
      careerScoutEntries: Array<{
        id: string
        interviewStartAt: Date
      }>
    }
    careerScoutEntryUserOfferTask: {
      careerScoutEntries: Array<{
        id: string
      }>
    }
    careerScoutEntryUserScheduleAdjustmentTask: {
      careerScoutEntries: Array<{
        id: string
        careerScoutName: string
      }>
    }
    careerScoutEntryUserUnreadMessagesTask: {
      count: number
      careerScoutEntries: Array<{
        id: string
        avatarUrl: string
        careerScoutName: string
        isUseAdminAvatar?: boolean
      }>
    }
    careerScoutEntryUserUnreadOfferMessagesTask: {
      count: number
      careerScoutOffers: Array<{
        id: string
        avatarUrl: string
        careerScoutName: string
      }>
    }
    careerScoutEntryUserUnopenedOfferTask: {
      count: number
      careerScoutOffers: Array<{
        id: string
        avatarUrl: string
        careerScoutName: string
      }>
    }
    careerScoutInterviewerEvaluationTask: {
      count: number
      careerScoutEntries: Array<{
        id: string
        avatarUrl: string
        userName: string
        interviewId: string
      }>
    }
    careerScoutInterviewerInterviewScheduleTask: {
      count: number
      careerScoutEntries: Array<{
        id: string
        avatarUrl: string
        userName: string
        interviewId: string
      }>
    }
    careerScoutInterviewerScheduleAdjustmentTask: {
      count: number
      careerScoutEntries: Array<{
        id: string
        avatarUrl: string
        userName: string
      }>
    }
    careerScoutInterviewerUnreadMessagesTask: {
      count: number
      careerScoutEntries: Array<{
        id: string
        avatarUrl: string
        userName: string
      }>
    }
  }
}

interface MyEntriesIdTask {
  entries: Array<{
    id: string
  }>
}

interface MyEntriesCountAndDetailTask {
  count: number
  entries: Array<{
    id: string
    avatarUrl: string
    userName: string
    isUseAdminAvatar?: boolean
  }>
}

interface MyEntryInterviewsTask {
  count: number
  entries: Array<{
    id: string
    avatarUrl: string
    userName: string
    interviewId: string
  }>
}

export interface QueryNotificationsResponse {
  notifications: {
    items: Array<{
      id: string
      type: NotificationType
      senderUser: {
        avatarUrl: string
      } | null
      isRead: boolean
      message: string
      url: string
      fileKey: string
      createdAt: Date
    }>
    hasNext: boolean
    endCursor: string
    startCursor: string
  }
}

export const SubscriptionNewNotification = gql`
  subscription newNotification {
    newNotification {
      id
      type
      senderUser {
        avatarUrl
      }
      isRead
      message
      url
      fileKey
      createdAt
    }
  }
`

export interface SubscriptionNewNotificationResponse {
  newNotification: {
    id: string
    type: NotificationType
    senderUser: {
      avatarUrl: string
    } | null
    isRead: boolean
    message: string
    url: string
    fileKey: string
    createdAt: Date
  }
}

export const SubscriptionReloadBrowser = gql`
  subscription reloadBrowserSubscription {
    reloadBrowserSubscription
  }
`

export interface SubscriptionReloadBrowserResponse {
  reloadBrowserSubscription: string
}

export interface MarkAsReadNotificationsInput {
  notificationIds: string[]
}

export const MutationMarkAsReadNotifications = gql`
  mutation markAsReadNotifications($input: MarkAsReadNotificationsInput!) {
    markAsReadNotifications(input: $input)
  }
`

export interface MutationMarkAsReadNotificationsResponse {
  markAsReadNotifications: boolean
}

export const MutationCheckCanEntry = gql`
  mutation checkCanEntry($positionId: ID!) {
    checkCanEntry(positionId: $positionId)
  }
`
export interface MutationCheckCanEntryResponse {
  canEntry: boolean
}

export const MutationBulkMarkAsReadEntryMessages = gql`
  mutation bulkMarkAsReadEntryMessages {
    bulkMarkAsReadEntryMessages
  }
`

export interface MutationBulkMarkAsReadEntryMessagesResponse {
  bulkMarkAsReadEntryMessages: string[]
}

export const MutationBulkMarkAsReadNotifications = gql`
  mutation bulkMarkAsReadNotifications {
    bulkMarkAsReadNotifications
  }
`

export const MutationBulkMarkAsReadDoubleworkEntryMessages = gql`
  mutation bulkMarkAsReadDoubleworkEntryMessages {
    bulkMarkAsReadDoubleworkEntryMessages
  }
`

export interface MutationBulkMarkAsReadDoubleworkEntryMessagesResponse {
  bulkMarkAsReadDoubleworkEntryMessages: string[]
}

export interface MutationBulkMarkAsReadNotificationsResponse {
  bulkMarkAsReadNotifications: string[]
}

export const QueryAnnualSchedule = gql`
  query annualSchedule {
    annualSchedule {
      id
      name
      period
      startAt
      endAt
      targetRoles
      annualScheduleType
      organization {
        id
        name
      }
    }
  }
`

export interface QueryAnnualScheduleResponse {
  annualSchedule: AnnualSchedule[]
}

export const QueryManageAnnualSchedule = gql`
  query manageAnnualSchedule($id: ID) {
    manageAnnualSchedule(id: $id) {
      id
      name
      period
      startAt
      endAt
      targetRoles
      annualScheduleType
      organization {
        id
        name
      }
    }
  }
`

export interface QueryManageAnnualScheduleResponse {
  manageAnnualSchedule: AnnualSchedule[]
}

export const MutationCreateAnnualSchedule = gql`
  mutation createAnnualSchedule($input: CreateAnnualScheduleInput!) {
    createAnnualSchedule(input: $input) {
      id
      name
      period
      startAt
      endAt
      targetRoles
      annualScheduleType
    }
  }
`

export interface MutationCreateAnnualScheduleResponse {
  createAnnualSchedule: {
    id: string
    name: string
    period: string
    startAt: string
    endAt: string
    targetRoles: AuthRole[]
    annualScheduleType: AnnualScheduleType
  }
}

export const MutationUpdateAnnualSchedule = gql`
  mutation updateAnnualSchedule($input: UpdateAnnualScheduleInput!) {
    updateAnnualSchedule(input: $input) {
      id
    }
  }
`

export interface MutationUpdateAnnualScheduleResponse {
  updateAnnualSchedule: {
    id: string
  }
}

export const MutationDeleteAnnualSchedule = gql`
  mutation deleteAnnualSchedule($input: DeleteAnnualScheduleInput!) {
    deleteAnnualSchedule(input: $input)
  }
`

export interface MutationDeleteAnnualScheduleResponse {
  deleteAnnualSchedule: boolean
}

export const QueryAdminFiles = gql`
  query adminFiles {
    adminFiles {
      id
      key
      name
      modifiedAt
    }
  }
`

export interface QueryAdminFilesResponse {
  adminFiles: Array<{
    key: string
    name: string
    modifiedAt: string
  }>
}

export const MutationCreateAdminFile = gql`
  mutation createAdminFile($input: CreateAdminFileInput!) {
    createAdminFile(input: $input) {
      key
      name
    }
  }
`

export interface MutationCreateAdminFileResponse {
  createAdminFile: {
    key: string
    name: string
  }
}

export const MutationUpdateAdminFile = gql`
  mutation updateAdminFile($input: UpdateAdminFileInput!) {
    updateAdminFile(input: $input) {
      key
      name
    }
  }
`

export interface MutationUpdateAdminFileResponse {
  updateAdminFile: {
    key: string
    name: string
  }
}

export const MutationDeleteAdminFiles = gql`
  mutation deleteAdminFiles($input: DeleteAdminFilesInput!) {
    deleteAdminFiles(input: $input)
  }
`

export interface MutationDeleteAdminFilesResponse {
  deleteAdminFiles: boolean
}

export const MutationUpdateElectionPriority = gql`
  mutation updateElectionPriority($input: UpdateElectionPriorityInput!) {
    updateElectionPriority(input: $input) {
      id
      priority
    }
  }
`
export interface MutationUpdateElectionPriorityResponse {
  updateElectionPriority: {
    id: string
    priority: ElectionPriority
  }
}

export const QueryElectionPositions = gql`
  query electionPositions($priority: Int, $includeStatus: Int, $remnant: Int) {
    electionPositions(priority: $priority, includeStatus: $includeStatus, remnant: $remnant) {
      id
      capacity
      electionCounts {
        elected
        excluded
        unEvaluated
        rejected
        declined
      }
      name
      organization {
        id
        name
      }
    }
  }
`

export interface QueryElectionPositionsResponse {
  electionPositions: Array<{
    id: string
    capacity: number
    electionCounts: Array<{
      elected: number
      excluded: number
      unEvaluated: number
      rejected: number
      declined: number
    }>
    name: string
    organization: {
      name: string
    }
  }>
}

export const QueryElectinPositionsForManageElectionEntries = gql`
  query electionPositions {
    electionPositions {
      id
      capacity
      electionCounts {
        elected
        excluded
        unEvaluated
        rejected
        declined
      }
      name
    }
  }
`

export interface QueryElectinPositionsForManageElectionEntriesResponse {
  electionPositions: Array<{
    id: string
    capacity: number
    electionCounts: Array<{
      elected: number
      excluded: number
      unEvaluated: number
      rejected: number
      declined: number
    }>
    name: string
  }>
}

export const MutationAutoElect = gql`
  mutation autoElect($input: AutoElectInput!) {
    autoElect(input: $input)
  }
`

export interface MutationAutoElectResponse {
  autoElect: boolean
}

export const MutationResetElect = gql`
  mutation resetElect($input: ResetElectInput!) {
    resetElect(input: $input)
  }
`

export interface MutationResetElectResponse {
  resetElect: boolean
}

export const MutationUpdatePositionCapacity = gql`
  mutation updatePositionCapacity($input: UpdatePositionCapacityInput!) {
    updatePositionCapacity(input: $input) {
      id
    }
  }
`

export interface MutationUpdatePositionCapacityResponse {
  updatePositionCapacity: {
    id: string
  }
}

export const MutationUpdateElectionStatus = gql`
  mutation updateElectionStatus($input: UpdateElectionStatusInput!) {
    updateElectionStatus(input: $input) {
      id
      status
      entry {
        id
      }
    }
  }
`

export interface MutationUpdateElectionStatusResponse {
  updateElectionStatus: Array<{
    id: string
    status: ElectionStatus
    entry: {
      id: string
    }
  }>
}

export const QueryElections = gql`
  query elections(
    $positionId: ID
    $entryIds: [ID!]
    $status: Int
    $userElected: Int
    $priority: Int
    $reAdjustment: Int
  ) {
    elections(
      positionId: $positionId
      entryIds: $entryIds
      status: $status
      userElected: $userElected
      priority: $priority
      reAdjustment: $reAdjustment
    ) {
      id
      priority
      status
      evaluation
      userElectedPriority
      userExperienceCount
      organizationEntryCount
      needReAdjustmentPositionName
      entry {
        id
        organizationName
        firstName
        firstNameKana
        lastName
        lastNameKana
        homeOrganizationName
        employeeNumber
        phoneNumber
        email
        travelTime
        entryReason
        selfPr
        consideration
        remark
        approveUser {
          id
          firstName
          lastName
          email
        }
        position {
          id
          name
          capacity
          organization {
            id
            name
          }
        }
      }
    }
  }
`

export interface QueryElectionsResponse {
  elections: Array<{
    priority: ElectionPriority
    status: ElectionStatus
    evaluation: string
    userElectedPriority: ElectionPriority | null
    userExperienceCount: number
    organizationEntryCount: number
    needReAdjustmentPositionName: string
    entry: {
      id: string
      organizationName: string
      firstName: string
      firstNameKana: string
      lastName: string
      lastNameKana: string
      homeOrganizationName: string
      employeeNumber: string
      phoneNumber: string
      email: string
      travelTime: number
      entryReason: string
      selfPr: string
      consideration: string
      remark: string | null
      approveUser: {
        firstName: string
        lastName: string
        email: string
      }
      position: {
        id: string
        name: string
        capacity: number
        organization: {
          name: string
        }
      }
    }
  }>
}

export const MutationUpdateElectionEvaluation = gql`
  mutation updateElectionEvaluation($input: UpdateElectionEvaluationInput!) {
    updateElectionEvaluation(input: $input) {
      id
    }
  }
`

export const QueryWorkshopSchedules = gql`
  query workshopSchedules($period: String!, $isIncludeDisable: Boolean) {
    workshopSchedules(period: $period, isIncludeDisable: $isIncludeDisable) {
      id
      startAt
      workshopType {
        id
        name
        isManager
        ageFrom
        ageTo
        isDisable
      }
    }
  }
`

export interface QueryWorkshopSchedulesResponse {
  workshopSchedules: Array<{
    id: string
    startAt: string
    workshopType: {
      id: string
      name: string
      isManager: boolean
      ageFrom: number
      ageTo: number
      isDisable: boolean
    }
  }>
}

export const QueryManageWorkshopEntries = gql`
  query manageWorkshopEntries(
    $period: String!
    $workshopTypeId: ID
    $workshopScheduleId: ID
    $status: Int
    $organizationId: ID
    $group: String
    $employeeNumber: ID
    $isOnlyUnWatchedVideo: Boolean
  ) {
    manageWorkshopEntries(
      period: $period
      workshopTypeId: $workshopTypeId
      workshopScheduleId: $workshopScheduleId
      status: $status
      organizationId: $organizationId
      group: $group
      employeeNumber: $employeeNumber
      isOnlyUnWatchedVideo: $isOnlyUnWatchedVideo
    ) {
      id
      workshopSchedule {
        id
        workshopType {
          id
          shortName
          isManager
        }
        startAt
      }
      status
      group
      groupRole
      user {
        id
        employeeNumber
        lastName
        firstName
        organizations {
          isMain
          organization {
            id
            name
          }
        }
      }
      firstName
      lastName
      firstNameKana
      lastNameKana
      age
      organizationName
      departmentName
      gradeName
      jobTitle
      activity {
        id
        isWatchedVideo
        worksheetFile
      }
      contactEmail
      reason
      consideration
      adminNote
      preferScheduleIds
      jobClassificationName
      firstPreferWorkshopType {
        id
      }
      considerationType
      workshopSurvey
      worksheet {
        worksheetProgressRate {
          total
        }
      }
    }
  }
`

export interface QueryManageWorkshopEntriesResponse {
  manageWorkshopEntries: Array<{
    id: string
    workshopSchedule: {
      id: string
      workshopType: {
        id: string
        shortName: string
        isManager: boolean
      }
      startAt: string
    } | null
    status: WorkshopEntryStatus
    group: string
    groupRole: string
    user: {
      id: string
      employeeNumber: string
      lastName: string
      firstName: string
      organizations: Array<{
        isMain: boolean
        organization: {
          id: string
          name: string
        }
      }>
    }
    firstName: string
    lastName: string
    firstNameKana: string
    lastNameKana: string
    age: string
    organizationName: string
    departmentName: string
    gradeName: string
    jobTitle: string
    activity: {
      isWatchedVideo: boolean
      worksheetFile: string
    } | null
    contactEmail: string
    reason: string
    consideration: string
    adminNote: string
    preferScheduleIds: string[]
    jobClassificationName: string
    firstPreferWorkshopType: {
      id: string
    } | null
    considerationType: ConsiderationType
    workshopSurvey: WorkshopSurveyV1 | WorkshopSurveyV2
    worksheet: {
      worksheetProgressRate: {
        total: number
      }
    }
  }>
}

export const MutationBulkExportWorkshopEntries = gql`
  mutation bulkExportWorkshopEntries($workshopEntryIds: [ID!]!) {
    bulkExportWorkshopEntries(workshopEntryIds: $workshopEntryIds)
  }
`

export interface MutationBulkExportWorkshopEntriesResponse {
  bulkExportWorkshopEntries: string
}

export const MutationUpdateWorkshopEntriesStatus = gql`
  mutation updateWorkshopEntriesStatus($input: UpdateWorkshopEntriesStatusInput!) {
    updateWorkshopEntriesStatus(input: $input) {
      id
    }
  }
`

export interface MutationUpdateWorkshopEntriesStatusResponse {
  updateWorkshopEntriesStatus: Array<{
    id: string
  }>
}

export const MutationDeleteWorkshopEntries = gql`
  mutation deleteWorkshopEntries($workshopEntryIds: [ID!]!) {
    deleteWorkshopEntries(workshopEntryIds: $workshopEntryIds)
  }
`

export interface MutationDeleteWorkshopEntriesResponse {
  deleteWorkshopEntries: boolean
}

export const MutationUpdateWorkshopEntriesGroup = gql`
  mutation updateWorkshopEntriesGroup($input: UpdateWorkshopEntriesGroupInput!) {
    updateWorkshopEntriesGroup(input: $input) {
      id
    }
  }
`

export interface MutationUpdateWorkshopEntriesGroupResponse {
  updateWorkshopEntriesGroup: Array<{
    id: string
  }>
}

export const MutationBulkExportWorksheets = gql`
  mutation bulkExportWorksheets($input: BulkExportWorksheetsInput!) {
    bulkExportWorksheets(input: $input)
  }
`

export interface MutationBulkExportWorksheetsResponse {
  bulkExportWorksheets: boolean
}

export const QueryWorkshopEntries = gql`
  query workshopEntries($ids: [ID!], $limit: Int) {
    workshopEntries(ids: $ids, limit: $limit) {
      id
      period
      workshopSchedule {
        id
        workshopType {
          id
          isManager
          shortName
        }
        startAt
        endAt
      }
      status
      worksheet {
        id
        name
        isLatest
      }
    }
  }
`

export interface QueryWorkshopEntriesResponse {
  workshopEntries: Array<{
    id: string
    period: string
    workshopSchedule: {
      id: string
      workshopType: {
        id: string
        isManager: boolean
        shortName: string
      }
      startAt: string
      endAt: string
    } | null
    status: WorkshopEntryStatus
    worksheet: {
      id: string
      name: string
      isLatest: boolean
    }
  }>
}

export const QueryWorkshopEntryDetail = gql`
  query workshopEntries($ids: [ID!], $period: String) {
    workshopEntries(ids: $ids, period: $period) {
      id
      group
      status
      period
      workshopSchedule {
        id
        videoUrl
        startAt
        endAt
        postSurveyVersion
        halfYearLaterSurveyVersion
        workshopType {
          id
          isManager
          name
          shortName
        }
      }
      groupMembers {
        avatar
        groupRole
        isSelf
        firstName
        lastName
        workshopEntryId
        worksheetViewId
      }
      workshopActivity: activity {
        id
        worksheetFileName
        worksheetFileUploadedAt
      }
      currentWorksheet: worksheet {
        id
        name
        isLatest
        createdAt
        modifiedAt
      }
      postSurvey
      halfYearLaterSurvey
    }
    worksheets {
      id
      name
      isLatest
      createdAt
      modifiedAt
    }
  }
`

export interface QueryWorkshopEntryDetailResponse {
  workshopEntries: Array<{
    id: string
    group: string
    status: WorkshopEntryStatus
    period: string
    workshopSchedule: {
      id: string
      videoUrl: string
      startAt: string
      endAt: string
      postSurveyVersion: string
      halfYearLaterSurveyVersion: string
      workshopType: {
        id: string
        isManager: boolean
        name: string
        shortName: string
      }
    } | null
    groupMembers: {
      avatar: string
      groupRole: string
      isSelf: boolean
      firstName: string
      lastName: string
      workshopEntryId: string | null
      worksheetViewId: string | null
    }
    workshopActivity: {
      id: string
      worksheetFileName: string
      worksheetFileUploadedAt: string
    } | null
    currentWorksheet: {
      id: string
      name: string
      isLatest: boolean
      createdAt: string
      modifiedAt: string
    }
    postSurvey: PostOrHalfYearLaterSurveyValues | null
    halfYearLaterSurvey: PostOrHalfYearLaterSurveyValues | null
  }>
  worksheets: Array<{
    id: string
    name: string
    isLatest: boolean
    createdAt: string
    modifiedAt: string
  }>
}

export const MutationUpdateWorkshopEntryWorksheet = gql`
  mutation updateWorkshopEntryWorksheet($input: UpdateWorkshopEntryWorksheetInput!) {
    updateWorkshopEntryWorksheet(input: $input) {
      id
      worksheet {
        id
        name
        createdAt
        modifiedAt
      }
    }
  }
`

export interface MutationUpdateWorkshopEntryWorksheetResponse {
  createOrUpdateWorkshopActivity: {
    id: string
    worksheet: {
      id: string
      name: string
      isLatest: boolean
      createdAt: string
      modifiedAt: string
    }
  }
}

export const QueryWorkshopEntryDownloadFile = gql`
  query workshopEntries($ids: [ID!]) {
    workshopEntries(ids: $ids) {
      workshopSchedule {
        id
        documentUrl
        worksheetUrl
        presentationUrl
      }
      workshopActivity: activity {
        id
        worksheetUrl
      }
    }
  }
`

export interface QueryWorkshopEntryDownloadFileResponse {
  workshopEntries: Array<{
    workshopSchedule: {
      documentUrl: string
      worksheetUrl: string
      presentationUrl: string
    } | null
    workshopActivity: {
      worksheetUrl: string
    } | null
  }>
}

export const MutationCreateOrUpdateWorkshopActivity = gql`
  mutation createOrUpdateWorkshopActivity(
    $workshopTypeId: ID!
    $period: String!
    $isWatchedVideo: Boolean
    $worksheetFile: Upload
  ) {
    createOrUpdateWorkshopActivity(
      workshopTypeId: $workshopTypeId
      period: $period
      isWatchedVideo: $isWatchedVideo
      worksheetFile: $worksheetFile
    ) {
      id
    }
  }
`

export interface MutationCreateOrUpdateWorkshopActivityResponse {
  createOrUpdateWorkshopActivity: {
    id: string
  }
}

export const MutationCheckCognitoUserExistByEmail = gql`
  mutation checkCognitoUserExistByEmail($email: String!) {
    checkCognitoUserExistByEmail(email: $email)
  }
`
export interface MutationCheckCognitoUserExistByEmailResponse {
  checkCognitoUserExistByEmail: boolean
}

export const MutationUpdateWorkshopEntry = gql`
  mutation updateWorkshopEntry($input: UpdateWorkshopEntryInput!) {
    updateWorkshopEntry(input: $input) {
      id
      status
    }
  }
`

export interface MutationUpdateWorkshopEntryResponse {
  updateWorkshopEntry: {
    id: string
    status: number
  }
}

export const MutationSyncWebPushEndpoint = gql`
  mutation syncWebPushEndpoint($input: SyncWebPushEndpointInput!) {
    syncWebPushEndpoint(input: $input)
  }
`

export interface MutationSyncWebPushEndpointResponse {
  syncWebPushEndpoint: boolean
}

export const QueryAvailablePositionTags = gql`
  query availablePositionTags(
    $positionType: Int!
    $employmentTypeIds: [ID!]
    $jobClassificationId: ID
    $workingLocationId: ID
    $organizationId: ID
    $isAllowRemotes: [Boolean!]
    $workTypes: [Int!]
    $isIncludeClosed: Boolean
    $tagIds: [ID!]
    $keywords: [String!]
    $isOnlyBookmark: Boolean
    $sortType: Int
    $seed: String
    $after: String
    $limit: Int
  ) {
    availablePositionTags(
      positionType: $positionType
      employmentTypeIds: $employmentTypeIds
      jobClassificationId: $jobClassificationId
      workingLocationId: $workingLocationId
      organizationId: $organizationId
      isAllowRemotes: $isAllowRemotes
      workTypes: $workTypes
      isIncludeClosed: $isIncludeClosed
      tagIds: $tagIds
      keywords: $keywords
      isOnlyBookmark: $isOnlyBookmark
      sortType: $sortType
      seed: $seed
      after: $after
      limit: $limit
    ) {
      value: id
      label: name
    }
  }
`

export interface QueryAvailablePositionTagsResponse {
  availablePositionTags: SelectOption[]
}

export const QuerySuggestPositions = gql`
  query suggestPositions($entryId: ID!, $keyword: String!) {
    suggestPositions(entryId: $entryId, keyword: $keyword) {
      id
      positionType
      name
    }
  }
`

export interface QuerySuggestPositionsResponse {
  suggestPositions: Position[]
}

export const MutationCreateSuggestEntry = gql`
  mutation createSuggestEntry($input: CreateSuggestEntryInput!) {
    createSuggestEntry(input: $input) {
      id
      firstName
      firstNameKana
      lastName
      lastNameKana
      phoneNumber
      email
      travelTime
      zipCode
      address1
      address2
      address3
      currentIncome
      currentJobDetail
      entryReason
      selfPr
      consideration
      resumeFile
      resumeFileName
      resumeFileUrl
      approveUser {
        id
      }
    }
  }
`

export interface MutationCreateSuggestEntryResponse {
  createSuggestEntry: {
    id: string
    firstName: string
    firstNameKana: string
    lastName: string
    lastNameKana: string
    phoneNumber: string
    email: string
    travelTime: number
    zipCode: string
    address1: string
    address2: string
    address3: string
    currentIncome: number
    currentJobDetail: string
    entryReason: string
    selfPr: string
    consideration: string
    resumeFile: string
    resumeFileName: string
    resumeFileUrl: string
    approveUser: {
      id: string
    } | null
  }
}

export const MutationCheckIpAllowed = gql`
  mutation checkIpAllowed {
    checkIpAllowed
  }
`

export interface MutationCheckIpAllowedResponse {
  checkIpAllowed: boolean
}

export const MutationUpdateEntryIsSupportRequired = gql`
  mutation updateEntryIsSupportRequired($input: UpdateEntryIsSupportRequiredInput!) {
    updateEntryIsSupportRequired(input: $input)
  }
`

export interface MutationUpdateEntryIsSupportRequiredResponse {
  updateEntryIsSupportRequired: boolean
}

export const MutationUpdateEntryRemark = gql`
  mutation updateEntryRemark($input: UpdateEntryRemarkInput!) {
    updateEntryRemark(input: $input) {
      id
      remark
    }
  }
`

export interface MutationUpdateEntryRemarkResponse {
  updateEntryRemark: {
    id: string
    remark: string | null
  }
}

export const QueryWorksheets = gql`
  query worksheets($ids: [ID!], $isLatest: Boolean) {
    worksheets(ids: $ids, isLatest: $isLatest) {
      id
      version
      name
      isLatest
      createdAt
      modifiedAt
      lifeLineChart
      valueCheck
      lookingBackOnGrowth
      inventoryOfOwnResources
      nextStep
      vectorAndVision
      worksheetProgressRate {
        lifeLineChart
        valueCheck
        vectorAndVision
        nextStep
        inventoryOfOwnResources
      }
    }
  }
`

export interface QueryWorksheetsResponse {
  worksheets: Array<{
    id: string
    version: WorksheetVersion
    name: string
    isLatest: boolean
    createdAt: string
    modifiedAt: string
    lifeLineChart: LifeLineChartV1 | LifeLineChartV2
    valueCheck: ValueCheckV1
    lookingBackOnGrowth: LookingBackOnGrowthV1
    inventoryOfOwnResources: InventoryOfOwnResourcesV1
    nextStep: NextStepV1 | NextStepV2
    vectorAndVision: VectorAndVisionV1 | VectorAndVisionV2
    worksheetProgressRate: {
      lifeLineChart: number
      valueCheck: number
      vectorAndVision: number
      nextStep: number
      inventoryOfOwnResources: number
    }
  }>
}

export const QueryWorksheetForView = gql`
  query worksheetForView($worksheetViewId: String!, $workshopEntryId: ID!) {
    worksheetForView(worksheetViewId: $worksheetViewId, workshopEntryId: $workshopEntryId) {
      id
      version
      lifeLineChart
      valueCheck
      lookingBackOnGrowth
      inventoryOfOwnResources
      nextStep
      vectorAndVision
      userAvatarUrl
    }
  }
`

export interface QueryWorksheetForViewResponse {
  worksheetForView: {
    id: string
    version: WorksheetVersion
    lifeLineChart: LifeLineChartV1 | LifeLineChartV2
    valueCheck: ValueCheckV1
    lookingBackOnGrowth: LookingBackOnGrowthV1
    inventoryOfOwnResources: InventoryOfOwnResourcesV1
    nextStep: NextStepV1 | NextStepV2
    vectorAndVision: VectorAndVisionV1 | VectorAndVisionV2
    userAvatarUrl: string
  }
}

export const MutationCreateWorksheetHistory = gql`
  mutation createWorksheetHistory {
    createWorksheetHistory {
      id
    }
  }
`

export interface MutationCreateWorksheetHistoryResponse {
  createWorksheetHistory: {
    id: string
  }
}

export const MutationUpdateWorksheetName = gql`
  mutation updateWorksheetName($input: UpdateWorksheetNameInput!) {
    updateWorksheetName(input: $input) {
      id
    }
  }
`

export interface MutationUpdateWorksheetNameResponse {
  updateWorksheetName: {
    id: string
  }
}

export const MutationDeleteWorksheet = gql`
  mutation deleteWorksheet($id: ID!) {
    deleteWorksheet(id: $id)
  }
`

export interface MutationDeleteWorksheetResponse {
  deleteWorksheet: boolean
}

export const MutationUpdateWorksheetData = gql`
  mutation updateWorksheetData($input: UpdateWorksheetDataInput!) {
    updateWorksheetData(input: $input)
  }
`

export interface MutationUpdateWorksheetDataResponse {
  updateWorksheetData: boolean
}

export const MutationUpdateWorksheetViewId = gql`
  mutation updateWorksheetViewId($input: UpdateWorksheetViewIdInput!) {
    updateWorksheetViewId(input: $input)
  }
`

export interface MutationUpdateWorksheetViewIdResponse {
  updateWorksheetViewId: boolean
}

export const QueryManageDoubleworks = gql`
  query queryManageDoubleworks(
    $id: ID
    $organizationIds: [ID!]
    $responsibleUserName: String
    $name: String
    $openStartedAt: DateTime
    $openEndedAt: DateTime
    $status: Int
    $offset: Int
    $limit: Int
  ) {
    manageDoubleworks(
      id: $id
      organizationIds: $organizationIds
      responsibleUserName: $responsibleUserName
      name: $name
      openStartedAt: $openStartedAt
      openEndedAt: $openEndedAt
      status: $status
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        createdAt
        modifiedAt
        placeholderSampleId
        organization {
          id
          name
        }
        authorUser {
          id
          email
        }
        openStartedAt
        openEndedAt
        applicationStartedAt
        applicationEndedAt
        officeIds
        # offices
        name
        isSbuManaged
        department
        workLargeCategory {
          id
          name
        }
        doubleworkWorkMediumCategories {
          id
          workMediumCategory {
            id
            name
          }
        }
        doubleworkWorkSubCategories {
          id
          workSubCategory {
            id
            name
            workMediumCategory {
              id
              name
              workLargeCategory {
                id
                name
              }
            }
          }
        }
        overview
        purposeAndBackground
        periodStartedAt
        periodEndedAt
        workingLocationType
        workingAddress
        rewardAmountType
        rewardAmount
        estimatedBusinessHours
        estimatedRewardAmount
        trainerUser {
          id
          firstName
          lastName
        }
        responsibleUserIds
        responsibleUsers {
          id
          firstName
          lastName
          email
        }
        contactUserIds
        contactUsers {
          email
        }
        contactEmails
        authorComment
        status
        doubleworkTag {
          tag {
            id
            name
          }
        }
      }
      total
    }
  }
`

export interface QueryManageDoubleworksResponse {
  manageDoubleworks: {
    items: ManageDoubleworkItem[]
    total: number
  }
}

export interface ManageDoubleworkItem {
  id: string
  createdAt: string
  modifiedAt: string
  placeholderSampleId: DoubleworkPlaceholderSample
  organization: {
    id: string
    name: string
  }
  authorUser: {
    id: string
    email: string
  }
  openStartedAt: Date
  openEndedAt: Date
  applicationStartedAt: Date
  applicationEndedAt: Date
  officeIds: string[]
  // offices
  name: string
  isSbuManaged: boolean
  department: string
  workLargeCategory: {
    id: string
    name: string
  } | null
  doubleworkWorkMediumCategories: Array<{
    id: string
    workMediumCategory: {
      id: string
      name: string
    }
  }>
  doubleworkWorkSubCategories: Array<{
    id: string
    workSubCategory: {
      id: string
      name: string
      workMediumCategory: {
        id: string
        name: string
        workLargeCategory: {
          id: string
          name: string
        }
      }
    }
  }>
  overview: string
  purposeAndBackground: string
  periodStartedAt: string
  periodEndedAt: string
  workingLocationType: WorkingLocationType
  workingAddress: string
  rewardAmountType: RewardAmountType
  rewardAmount: number
  estimatedBusinessHours: number
  estimatedRewardAmount: number
  trainerUser: {
    id: string
    firstName: string
    lastName: string
  }
  responsibleUserIds: string[]
  responsibleUsers: [
    {
      id: string
      firstName: string
      lastName: string
      email: string
    },
  ]
  contactUserIds: string[]
  contactUsers: {
    email: string
  }
  contactEmails: string[]
  authorComment: string
  status: number
  // pledgeAgreement
  doubleworkTag: Array<{
    tag: {
      id: string
      name: string
    }
  }>
}

export const MutationDeleteDoubleworks = gql`
  mutation deleteDoubleworks($doubleworkIds: [ID!]!) {
    deleteDoubleworks(doubleworkIds: $doubleworkIds)
  }
`

export interface MutationDeleteDoubleworksResponse {
  deleteDoubleworks: boolean
}

export const MutationSuspendDoubleworks = gql`
  mutation suspendDoubleworks($doubleworkIds: [ID!]!) {
    suspendDoubleworks(doubleworkIds: $doubleworkIds)
  }
`

export interface MutationSuspendDoubleworksResponse {
  suspendDoubleworks: boolean
}

export const MutationApproveDoubleworks = gql`
  mutation approveDoubleworks($doubleworkIds: [ID!]!) {
    approveDoubleworks(doubleworkIds: $doubleworkIds)
  }
`

export interface MutationApproveDoubleworksResponse {
  approveDoubleworks: boolean
}

export const MutationBulkExportDoubleworks = gql`
  mutation bulkExportDoubleworks($doubleworkIds: [ID!]!) {
    bulkExportDoubleworks(doubleworkIds: $doubleworkIds)
  }
`

export interface MutationBulkExportDoubleworksResposne {
  bulkExportDoubleworks: string
}

export const QuerySearchManagedDoubleworkMasters = gql`
  query searchManagedDoubleworkMasters {
    organizations {
      value: id
      label: name
    }
  }
`
export interface QuerySearchManagedDoubleworkMastersResponse {
  organizations: SelectOption[]
}

export const MutationCreateDoublework = gql`
  mutation createDoublework($input: CreateDoubleworkInput!) {
    createDoublework(input: $input) {
      id
    }
  }
`

export interface MutationCreateDoubleworkResponse {
  createDoublework: {
    id: string
  }
}

export const MutationUpdateDoublework = gql`
  mutation updateDoublework($input: UpdateDoubleworkInput!) {
    updateDoublework(input: $input) {
      id
    }
  }
`

export interface MutationUpdateDoubleworkResponse {
  updateDoublework: {
    id: string
  }
}

export const QueryDoubleworkMasters = gql`
  query doubleworkMasters($organizationId: ID!) {
    organizations {
      value: id
      label: name
    }
    doubleworkResponsibleUsers(organizationIds: [$organizationId]) {
      id
      firstName
      lastName
      email
      avatarUrl
      organizations {
        id
        isManager
        organization {
          id
        }
      }
      employmentType {
        id
        name
      }
    }
    offices(organizationId: $organizationId) {
      value: id
      label: name
      address
    }
    workLargeCategories {
      id
      name
      workMediumCategories {
        id
        name
        workSubCategories {
          id
          externalId
          name
        }
      }
    }
  }
`

export interface QueryDoubleworkMastersResponse {
  organizations: SelectOption[]
  doubleworkResponsibleUsers: Array<{
    id: string
    firstName: string
    lastName: string
    email: string
    avatarUrl: string
    organizations: Array<{
      id: string
      isManager: boolean
      organization: {
        id: string
      }
    }>
    employmentType: {
      id: string
      name: string
    }
  }>
  offices: Array<{
    value: string
    label: string
    address: string
  }>
  workLargeCategories: Array<{
    id: string
    name: string
    workMediumCategories: Array<{
      id: string
      name: string
      workSubCategories: Array<{
        id: string
        externalId: string | null
        name: string
      }>
    }>
  }>
}

export const QueryDoubleworkTags = gql`
  query doubleworkTags {
    tags(type: ${TagType.Doublework}) {
      value: id
      label: name
    }
  }
`

export interface QueryDoubleworkTagsResponse {
  tags: SelectOption[]
}

export const MutationToggleDoubleworkBookmark = gql`
  mutation toggleDoubleworkBookmark($doubleworkId: ID!) {
    toggleDoubleworkBookmark(doubleworkId: $doubleworkId)
  }
`

export interface MutationToggleDoubleworkBookmarkResponse {
  toggleDoubleworkBookmark: boolean
}

export const QueryWorkshopMasterForEntry = gql`
  query workshopMasterForEntry($period: String!) {
    workshopMasters(period: $period) {
      isInsideApplicationPeriod
    }
    me {
      id
      email
      firstName
      lastName
      firstNameKana
      lastNameKana
      isSSOUser
      organizations {
        id
        isMain
        organization {
          id
          name
        }
      }
      userRawData {
        csvRow
      }
    }
    workshopEntries(period: $period, isIncludeUnconfirmed: true) {
      id
      period
      firstPreferWorkshopType {
        id
        isManager
      }
    }
    workshopSchedules(period: $period) {
      id
      startAt
      workshopType {
        id
      }
    }
    workshopTypes(isIncludeDisable: false) {
      id
      name
      isManager
      ageFrom
      ageTo
    }
  }
`

export interface QueryWorkshopMasterForEntryResponse {
  workshopMasters: Array<{
    isInsideApplicationPeriod: boolean
  }>
  me: {
    id: string
    email: string
    firstName: string
    lastName: string
    firstNameKana: string
    lastNameKana: string
    isSSOUser: boolean
    organizations: Array<{
      id: string
      isMain: boolean
      organization: {
        id: string
        name: string
      }
    }>
    userRawData: {
      csvRow: string[]
    }
  }
  workshopEntries: Array<{
    id: string
    period: string
    firstPreferWorkshopType: {
      id: string
      isManager: boolean
    }
  }>
  workshopSchedules: Array<{
    id: string
    startAt: string
    workshopType: {
      id: string
      name: string
    }
  }>
  workshopTypes: Array<{
    id: string
    name: string
    isManager: boolean
    ageFrom: number | null
    ageTo: number | null
  }>
}

export const QueryDoublework = gql`
  query doublework(
    $workLargeCategoryId: ID
    $workMediumCategoryId: ID
    $workSubCategoryId: ID
    $organizationId: ID
    $isIncludeClosed: Boolean
    $tagIds: [ID!]
    $keywords: [String!]
    $isOnlyBookmark: Boolean
    $sortType: Int
    $id: ID!
    $seed: String
  ) {
    doublework(
      workLargeCategoryId: $workLargeCategoryId
      workMediumCategoryId: $workMediumCategoryId
      workSubCategoryId: $workSubCategoryId
      organizationId: $organizationId
      isIncludeClosed: $isIncludeClosed
      tagIds: $tagIds
      keywords: $keywords
      isOnlyBookmark: $isOnlyBookmark
      sortType: $sortType
      id: $id
      seed: $seed
    ) {
      doublework {
        id
        name
        organization {
          id
          name
        }
        department
        overview
        purposeAndBackground
        periodStartedAt
        periodEndedAt
        difficulty
        estimatedBusinessHours
        estimatedRewardAmount
        offices {
          name
          address
        }
        contactUsers {
          firstName
          lastName
          avatarUrl
          email
        }
        contactEmails
        authorComment
        doubleworkTag {
          tag {
            name
          }
        }
        isBookmarked
        openEndedAt
        status
        workingLocationType
        workingAddress
        doubleworkWorkSubCategories {
          workSubCategory {
            name
            workMediumCategory {
              name
              workLargeCategory {
                name
              }
            }
          }
        }
      }
      beforeId
      afterId
      isValidSearchCondition
    }
  }
`

export interface QueryDoubleworkResponseItem {
  id: string
  name: string
  organization: {
    id: string
    name: string
  }
  department: string
  overview: string
  purposeAndBackground: string
  periodStartedAt: string
  periodEndedAt: string
  difficulty: Difficulty
  estimatedBusinessHours: number
  estimatedRewardAmount: number
  offices: Array<{
    name: string
    address: string
  }>
  contactUsers: Array<{
    firstName: string
    lastName: string
    avatarUrl: string
    email: string
  }>
  contactEmails: string[]
  authorComment: string
  doubleworkTag: Array<{
    tag: {
      name: string
    }
  }>
  isBookmarked: boolean
  openEndedAt: string
  status: DoubleworkStatus
  workingLocationType: WorkingLocationType
  workingAddress: string
  doubleworkWorkSubCategories: Array<{
    workSubCategory: {
      name: string
      workMediumCategory: {
        name: string
        workLargeCategory: {
          name: string
        }
      }
    }
  }>
}

export interface QueryDoubleworkResponse {
  doublework: {
    doublework: QueryDoubleworkResponseItem | null
    beforeId: string | null
    afterId: string | null
    isValidSearchCondition: boolean
  }
}

export const QueryAvailableDoubleworkTags = gql`
  query availableDoubleworkTags(
    $workLargeCategoryId: ID
    $workMediumCategoryId: ID
    $workSubCategoryId: ID
    $organizationId: ID
    $isIncludeClosed: Boolean
    $tagIds: [ID!]
    $keywords: [String!]
    $isOnlyBookmark: Boolean
    $sortType: Int
    $seed: String
    $after: String
    $limit: Int
  ) {
    availableDoubleworkTags(
      workLargeCategoryId: $workLargeCategoryId
      workMediumCategoryId: $workMediumCategoryId
      workSubCategoryId: $workSubCategoryId
      organizationId: $organizationId
      isIncludeClosed: $isIncludeClosed
      tagIds: $tagIds
      keywords: $keywords
      isOnlyBookmark: $isOnlyBookmark
      sortType: $sortType
      seed: $seed
      after: $after
      limit: $limit
    ) {
      value: id
      label: name
    }
  }
`

export interface QueryAvailableDoubleworkTagsResponse {
  availableDoubleworkTags: SelectOption[]
}

export const QueryDoubleworks = gql`
  query doubleworks(
    $workLargeCategoryId: ID
    $workMediumCategoryId: ID
    $workSubCategoryId: ID
    $organizationId: ID
    $isIncludeClosed: Boolean
    $tagIds: [ID!]
    $keywords: [String!]
    $isOnlyBookmark: Boolean
    $sortType: Int
    $seed: String
    $self: String
    $before: String
    $after: String
    $limit: Int
  ) {
    doubleworks(
      workLargeCategoryId: $workLargeCategoryId
      workMediumCategoryId: $workMediumCategoryId
      workSubCategoryId: $workSubCategoryId
      organizationId: $organizationId
      isIncludeClosed: $isIncludeClosed
      tagIds: $tagIds
      keywords: $keywords
      isOnlyBookmark: $isOnlyBookmark
      sortType: $sortType
      seed: $seed
      self: $self
      before: $before
      after: $after
      limit: $limit
    ) {
      items {
        id
        name
        organization {
          id
          name
        }
        department
        openStartedAt
        openEndedAt
        applicationStartedAt
        applicationEndedAt
        doubleworkTag {
          tag {
            id
            name
          }
        }
        isBookmarked
        status
        doubleworkWorkSubCategories {
          workSubCategory {
            name
            workMediumCategory {
              name
              workLargeCategory {
                name
              }
            }
          }
        }
      }
      total
      hasBefore
      hasNext
      startCursor
      endCursor
    }
  }
`

export interface QueryDoubleworksResponse {
  doubleworks: {
    items: Array<{
      id: string
      name: string
      organization: {
        id: string
        name: string
      }
      department: string
      openStartedAt: string
      openEndedAt: string
      applicationStartedAt: string
      applicationEndedAt: string
      doubleworkTag: Array<{
        tag: {
          id: string
          name: string
        }
      }>
      isBookmarked: boolean
      status: DoubleworkStatus
      doubleworkWorkSubCategories: Array<{
        workSubCategory: {
          name: string
          workMediumCategory: {
            name: string
            workLargeCategory: {
              name: string
            }
          }
        }
      }>
    }>
    total: number
    hasBefore: boolean | null
    hasNext: boolean | null
    startCursor: string | null
    endCursor: string | null
  }
}

export const QuerySearchDoubleworkMasters = gql`
  query searchDoubleworkMasters {
    organizations {
      value: id
      label: name
      externalId
    }
    workLargeCategories {
      id
      name
      workMediumCategories {
        id
        name
        workSubCategories {
          id
          name
          externalId
        }
      }
    }
  }
`

export interface QuerySearchDoubleworkMastersResponse {
  organizations: Array<SelectOption & { externalId: string }>
  workLargeCategories: Array<{
    id: string
    name: string
    workMediumCategories: Array<{
      id: string
      name: string
      workSubCategories: Array<{
        id: string
        name: string
        externalId: string | null
      }>
    }>
  }>
}

export interface CreateDoubleworkEntryInput {
  doubleworkId: string
  firstName: string
  firstNameKana: string
  lastName: string
  lastNameKana: string
  phoneNumber: string
  companyEmail: string
  privateEmail: string
  career: string
  skill: string
  selfPr: string
  resumeFile: string
  resumeFileName: string
  isTemporarySaved: boolean
  pledgeVersion: string
}

export const MutationCreateDoubleworkEntry = gql`
  mutation createDoubleworkEntry($input: CreateDoubleworkEntryInput!) {
    createDoubleworkEntry(input: $input) {
      id
      createdAt
      modifiedAt
      firstName
      firstNameKana
      lastName
      lastNameKana
      phoneNumber
      companyEmail
      privateEmail
      career
      skill
      selfPr
      resumeFile
      resumeFileName
      resumeFileUrl
      isTemporarySaved
      isDecided
      selectorUserIds
    }
  }
`

export interface MutationCreateDoubleworkEntryResponse {
  createDoubleworkEntry: {
    id: string
    createdAt: string
    modifiedAt: string
    firstName: string
    firstNameKana: string
    lastName: string
    lastNameKana: string
    phoneNumber: string
    companyEmail: string
    privateEmail: string
    career: string
    skill: string
    selfPr: string
    resumeFile: string
    resumeFileName: string
    resumeFileUrl: string
    isTemporarySaved: boolean
    isDecided: boolean
    selectorUserIds: string[]
  }
}

export interface UpdateDoubleworkEntryInput extends CreateDoubleworkEntryInput {
  id: string
}

export const MutationUpdateDoubleworkEntry = gql`
  mutation updateDoubleworkEntry($input: UpdateDoubleworkEntryInput!) {
    updateDoubleworkEntry(input: $input) {
      id
      createdAt
      modifiedAt
      firstName
      firstNameKana
      lastName
      lastNameKana
      phoneNumber
      companyEmail
      privateEmail
      career
      skill
      selfPr
      resumeFile
      resumeFileName
      resumeFileUrl
      isTemporarySaved
      isDecided
      selectorUserIds
    }
  }
`

export interface MutationUpdateDoubleworkEntryResponse {
  updateDoubleworkEntry: {
    id: string
    createdAt: string
    modifiedAt: string
    firstName: string
    firstNameKana: string
    lastName: string
    lastNameKana: string
    phoneNumber: string
    companyEmail: string
    privateEmail: string
    career: string
    skill: string
    selfPr: string
    resumeFile: string
    resumeFileName: string
    resumeFileUrl: string
    isTemporarySaved: boolean
    isDecided: boolean
    selectorUserIds: string[]
  }
}

export const MutationDeleteDoubleworkEntries = gql`
  mutation deleteDoubleworkEntries($ids: [ID!]!) {
    deleteDoubleworkEntries(ids: $ids)
  }
`

export interface MutationDeleteDoubleworkEntriesResponse {
  deleteDoubleworkEntries: boolean
}

export const QueryDoubleworkEntry = gql`
  query doubleworkEntry($id: ID, $doubleworkId: ID) {
    doubleworkEntry(id: $id, doubleworkId: $doubleworkId) {
      id
      createdAt
      modifiedAt
      user {
        id
      }
      doublework {
        id
        name
        organization {
          id
          name
        }
        doubleworkWorkSubCategories {
          workSubCategory {
            name
            workMediumCategory {
              name
              workLargeCategory {
                name
              }
            }
          }
        }
      }
      firstName
      firstNameKana
      lastName
      lastNameKana
      phoneNumber
      companyEmail
      privateEmail
      career
      skill
      selfPr
      resumeFile
      resumeFileName
      resumeFileUrl
      isTemporarySaved
      currentEntryFlowStep {
        no
        name
        candidateVisibleName
      }
      isDecided
      selectorUserIds
      selectorUsers {
        id
      }
      entryPledgeAgreement {
        id
      }
    }
  }
`

export interface QueryDoubleworkEntryResponse {
  doubleworkEntry: {
    id: string
    createdAt: string
    modifiedAt: string
    user: {
      id: string
    }
    doublework: {
      id: string
      name: string
      organization: {
        id: string
        name: string
      }
      doubleworkWorkSubCategories: Array<{
        workSubCategory: {
          name: string
          workMediumCategory: {
            name: string
            workLargeCategory: {
              name: string
            }
          }
        }
      }>
    }
    firstName: string
    firstNameKana: string
    lastName: string
    lastNameKana: string
    phoneNumber: string
    companyEmail: string
    privateEmail: string
    career: string
    skill: string
    selfPr: string
    resumeFile: string
    resumeFileName: string
    resumeFileUrl: string
    isTemporarySaved: boolean
    currentEntryFlowStep: {
      no: number
      name: string
      candidateVisibleName: string
    }
    isDecided: boolean
    selectorUserIds: string[]
    selectorUsers: {
      id: string
    }
    entryPledgeAgreement: {
      id: string
    }
  }
}

export const MutationUpdateDoubleworkEntriesFlowStep = gql`
  mutation updateDoubleworkEntriesFlowStep($input: UpdateDoubleworkEntriesFlowStepInput!) {
    updateDoubleworkEntriesFlowStep(input: $input)
  }
`

export interface MutationUpdateDoubleworkEntriesFlowStepResponse {
  updateDoubleworkEntriesFlowStep: boolean
}

export const MutationBulkExportDoubleworkEntries = gql`
  mutation bulkExportDoubleworkEntries($ids: [ID!]!, $exportOptionId: Int!) {
    bulkExportDoubleworkEntries(ids: $ids, exportOptionId: $exportOptionId)
  }
`

export interface MutationBulkExportDoubleworkEntriesResponse {
  bulkExportDoubleworkEntries: boolean
}

export const QueryManageDoubleworkEntries = gql`
  query manageDoubleworkEntries(
    $employeeNumber: String
    $entryFlowStepNos: [Int!]
    $doubleworkId: ID
    $entryUserOrganizationIds: [ID!]
    $doubleworkOrganizationIds: [ID!]
    $offset: Int
    $limit: Int
  ) {
    manageDoubleworkEntries(
      employeeNumber: $employeeNumber
      entryFlowStepNos: $entryFlowStepNos
      doubleworkId: $doubleworkId
      entryUserOrganizationIds: $entryUserOrganizationIds
      doubleworkOrganizationIds: $doubleworkOrganizationIds
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        currentEntryFlowStep {
          no
          name
        }
        doublework {
          id
          name
          organization {
            name
          }
        }
        employeeNumber
        firstName
        lastName
        organizationName
        hasUnreadEntryUserMessage
        isDecided
        userPermissions
      }
      total
    }
  }
`

export interface QueryManageDoubleworkEntriesResponse {
  manageDoubleworkEntries: {
    items: Array<{
      id: string
      currentEntryFlowStep: {
        name: string
        no: DoubleworkFlowStepNo
      }
      doublework: {
        name: string
        organization: {
          name: string
        }
      }
      employeeNumber: string
      firstName: string
      lastName: string
      organizationName: string
      hasUnreadEntryUserMessage: boolean
      isDecided: boolean
      userPermissions: DoubleworkEntryUserPermission[]
    }>
    total: number
  }
}

export const QueryDoubleworkEntryNavigation = gql`
  query doubleworkEntryNavigation($id: ID!) {
    doubleworkEntry(id: $id) {
      id
      createdAt
      isTemporarySaved
      firstName
      lastName
      employeeNumber
      user {
        id
      }
      currentEntryFlowStep {
        no
        name
        candidateVisibleName
      }
      doublework {
        id
        organization {
          id
          name
        }
        doubleworkWorkSubCategories {
          workSubCategory {
            name
            workMediumCategory {
              name
              workLargeCategory {
                name
              }
            }
          }
        }
      }
      doubleworkEntryMonitorings {
        id
      }
      userPermissions
      unreadOrLastEntryMessageId
      applicationDocumentNumber
      applicationDocumentIssuedAt
    }
  }
`

export interface QueryDoubleworkEntryNavigationResponse {
  doubleworkEntry: {
    id: string
    createdAt: string
    isTemporarySaved: boolean
    firstName: string
    lastName: string
    employeeNumber: string
    user: {
      id: string
    }
    currentEntryFlowStep: {
      no: DoubleworkFlowStepNo
      name: string
      candidateVisibleName: string
    }
    doublework: {
      id: string
      organization: {
        id: string
        name: string
      }
      doubleworkWorkSubCategories: Array<{
        workSubCategory: {
          name: string
          workMediumCategory: {
            name: string
            workLargeCategory: {
              name: string
            }
          }
        }
      }>
    }
    doubleworkEntryMonitorings: Array<{
      id: string
    }>
    userPermissions: EntryUserPermission[]
    unreadOrLastEntryMessageId: string | null
    applicationDocumentNumber: string
    applicationDocumentIssuedAt: string | null
  }
}

export const MutationCreateWorkshopEntry = gql`
  mutation createWorkshopEntry($input: CreateWorkshopEntryInput!) {
    createWorkshopEntry(input: $input)
  }
`
export interface MutationCreateWorkshopEntryResponse {
  createWorkshopEntry: boolean
}

export const MutationConfirmWorkshopUnconfirmedEntries = gql`
  mutation confirmWorkshopUnconfirmedEntries($input: ConfirmWorkshopUnconfirmedEntriesInput!) {
    confirmWorkshopUnconfirmedEntries(input: $input)
  }
`
export interface MutationConfirmWorkshopUnconfirmedEntriesResponse {
  confirmWorkshopUnconfirmedEntries: boolean
}

export const QueryUsers = gql`
  query users($employeeNumber: String!) {
    users(employeeNumber: $employeeNumber) {
      id
      lastName
      firstName
      email
      employeeNumber
      isSSOUser
      userRawData {
        csvRow
      }
      organizations {
        id
        isMain
        organization {
          id
          name
        }
      }
    }
  }
`

export interface QueryUsersResponse {
  users: Array<{
    id: string
    lastName: string
    firstName: string
    email: string
    employeeNumber: string
    isSSOUser: boolean
    userRawData: {
      csvRow: string[]
    }
    organizations: Array<{
      id: string
      isMain: boolean
      organization: {
        id: string
        name: string
      }
    }>
  }>
}

export const MutationDoubleworkAcceptOrDeclineOffer = gql`
  mutation DoubleworkacceptOrDeclineOffer($input: DoubleworkAcceptOrDeclineOfferInput!) {
    doubleworkAcceptOrDeclineOffer(input: $input) {
      id
      firstName
      firstNameKana
      lastName
      lastNameKana
      phoneNumber
      selfPr
      resumeFileUrl
      resumeFileName
      currentEntryFlowStep {
        no
      }
      userPermissions
    }
  }
`

export interface MutationDoubleworkAcceptOrDeclineOfferResponse {
  doubleworkAcceptOrDeclineOffer: {
    id: string
    firstName: string
    firstNameKana: string
    lastName: string
    lastNameKana: string
    phoneNumber: string
    selfPr: string
    resumeFileUrl: string
    resumeFileName: string
    currentEntryFlowStep: {
      no: DoubleworkFlowStepNo
    }
    userPermissions: DoubleworkEntryUserPermission[]
  }
}

export const QueryDoubleworkEntryDetail = gql`
  query doubleworkEntryDetail($id: ID!) {
    doubleworkEntry(id: $id) {
      id
      firstName
      firstNameKana
      lastName
      lastNameKana
      phoneNumber
      companyEmail
      privateEmail
      organizationName
      career
      skill
      selfPr
      resumeFileUrl
      resumeFileName
      doublework {
        id
        name
        organization {
          id
          name
        }
        department
        overview
        purposeAndBackground
        periodStartedAt
        periodEndedAt
        difficulty
        estimatedBusinessHours
        estimatedRewardAmount
        offices {
          id
          name
          address
        }
        contactUsers {
          id
          firstName
          lastName
          avatarUrl
          email
        }
        contactEmails
        authorComment
        doubleworkTag {
          id
          tag {
            id
            name
          }
        }
        workingLocationType
        workingAddress
        doubleworkWorkSubCategories {
          workSubCategory {
            name
            workMediumCategory {
              name
              workLargeCategory {
                name
              }
            }
          }
        }
      }
      currentEntryFlowStep {
        no
      }
      entryPledgeAgreement {
        id
      }
      userPermissions
      isTemporarySaved
    }
  }
`

export interface QueryDoubleworkEntryDetailResponse {
  doubleworkEntry: {
    id: string
    firstName: string
    firstNameKana: string
    lastName: string
    lastNameKana: string
    phoneNumber: string
    companyEmail: string
    privateEmail: string
    organizationName: string
    career: string
    skill: string
    selfPr: string
    resumeFileUrl: string
    resumeFileName: string
    doublework: {
      id: string
      name: string
      department: string
      overview: string
      purposeAndBackground: string
      periodStartedAt: string
      periodEndedAt: string
      difficulty: Difficulty
      estimatedBusinessHours: number
      estimatedRewardAmount: number
      offices: Array<{
        name: string
        address: string
      }>
      contactUsers: Array<{
        id: string
        firstName: string
        lastName: string
        avatarUrl: string
        email: string
      }>
      doubleworkTag: Array<{
        id: string
        tag: {
          id: string
          name: string
        }
      }>
      workingLocationType: WorkingLocationType
      workingAddress: string
      doubleworkWorkSubCategories: Array<{
        workSubCategory: {
          name: string
          workMediumCategory: {
            name: string
            workLargeCategory: {
              name: string
            }
          }
        }
      }>
    }
    currentEntryFlowStep: {
      no: DoubleworkFlowStepNo
    }
    entryPledgeAgreement: {
      id: string
    }
    userPermissions: DoubleworkEntryUserPermission[]
    isTemporarySaved: boolean
    unreadOrLastEntryMessageId: string
  }
}

export const QueryDoubleworkEntryActionHistories = gql`
  query doubleworkEntryActionHistories($id: ID!) {
    doubleworkEntry(id: $id) {
      id
      userPermissions
      doubleworkEntryActionHistories {
        id
        category
        createdAt
        content
        detail
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export interface QueryDoubleworkEntryActionHistoriesResponse {
  doubleworkEntry: {
    id: string
    userPermissions: DoubleworkEntryUserPermission[]
    doubleworkEntryActionHistories: Array<{
      id: string
      category: DoubleworkEntryActionCategory
      createdAt: string
      content: DoubleworkEntryActionHistoryContent
      detail: string
      user: {
        firstName: string
        lastName: string
      } | null
    }>
  }
}

export const QueryDoubleworkEntrySelectors = gql`
  query doubleworkEntrySelectors($id: ID!) {
    doubleworkEntry(id: $id) {
      id
      user {
        id
      }
      selectorUsers {
        id
        firstName
        lastName
        email
      }
    }
  }
`

export interface QueryDoubleworkEntrySelectorsResponse {
  doubleworkEntry: {
    id: string
    user: {
      id: string
    }
    selectorUsers: Array<{
      id: string
      firstName: string
      lastName: string
      email: string
    }>
  }
}

export const QuerySelectorCandidates = gql`
  query selectorCandidates($entryId: ID!, $name: String!) {
    selectorCandidates(entryId: $entryId, name: $name) {
      id
      lastName
      firstName
      email
    }
  }
`

export interface QuerySelectorCandidatesResponse {
  selectorCandidates: Array<{
    id: string
    firstName: string
    lastName: string
    email: string
  }>
}

export const MutationAssignSelector = gql`
  mutation assignSelector($input: AssignSelectorInput!) {
    assignSelector(input: $input)
  }
`

export interface MutationAssignSelectorResponse {
  assignSelector: boolean
}

export const MutationCreateDoubleworkEntryNote = gql`
  mutation($input: CreateDoubleworkEntryNoteInput!) {
    createDoubleworkEntryNote(input: $input) {
      id
      text
      user {
        id
      }
    }
  }
`

export interface MutationCreateDoubleworkEntryNoteResponse {
  createDoubleworkEntryNote: {
    id: string
    text: string
    user: {
      id: string
    }
  }
}

export const QueryDoubleworkEntrylNotes = gql`
  query doubleworkEntryNotes($id: ID!) {
    doubleworkEntry(id: $id) {
      id
      doubleworkEntryNotes {
        id
        user {
          id
          firstName
          lastName
          avatarUrl
        }
        text
        createdAt
      }
      isDecided
    }
  }
`

export interface QueryDoubleworkEntryNotesResponse {
  doubleworkEntry: {
    id: string
    doubleworkEntryNotes: Array<{
      id: string
      user: {
        id: string
        firstName: string
        lastName: string
        avatarUrl: string
      }
      text: string
      createdAt: string
    }>
    isDecided: boolean
  }
}

export const QueryDoubleworkEntryProcedures = gql`
  query doubleworkEntryProcedures($id: ID!) {
    doubleworkEntry(id: $id) {
      id
      currentEntryFlowStep {
        no
      }
      procedures
      userPermissions
      applicationDocumentNumber
    }
  }
`

export interface QueryDoubleworkEntryProceduresResponse {
  doubleworkEntry: {
    id: string
    currentEntryFlowStep: {
      no: DoubleworkFlowStepNo
    }
    procedures: DoubleworkProceduresChecks | null
    userPermissions: DoubleworkEntryUserPermission[]
    applicationDocumentNumber: string
  }
}

export const MutationUpdateDoubleworkEntryProcedures = gql`
  mutation updateDoubleworkEntryProcedures($input: UpdateDoubleworkEntryProceduresInput!) {
    updateDoubleworkEntryProcedures(input: $input)
  }
`

export interface MutationUpdateDoubleworkEntryProceduresResponse {
  updateDoubleworkEntryProcedures: boolean
}

export const MutationUpdateDoubleworkEntryIsDecided = gql`
  mutation updateDoubleworkEntryIsDecided($id: ID!, $isDecided: Boolean!) {
    updateDoubleworkEntryIsDecided(id: $id, isDecided: $isDecided) {
      id
      isDecided
    }
  }
`

export interface MutationUpdateDoubleworkEntryIsDecidedResponse {
  updateDoubleworkEntryIsDecided: boolean
}

export const MutationBulkImportDoubleworks = gql`
  mutation bulkImportDoubleworks($file: Upload!) {
    bulkImportDoubleworks(file: $file) {
      created
      updated
      errors
    }
  }
`

export interface MutationBulkImportDoubleworksResponse {
  bulkImportDoubleworks: {
    created: number
    updated: number
    errors: Array<{
      row: number | null
      column: string | null
      value: string | null
      message: string
    }>
  }
}

export const MutationDoubleworkCheckCanEntry = gql`
  mutation doubleworkCheckCanEntry($doubleworkId: ID!) {
    doubleworkCheckCanEntry(doubleworkId: $doubleworkId)
  }
`
export interface MutationDoubleworkCheckCanEntryResponse {
  doubleworkCheckCanEntry: boolean
}

export const QueryDoubleworkEntryMessages = gql`
  query doubleworkEntryMessages($entryId: ID!, $before: String, $after: String, $limit: Int, $self: String) {
    entryMessages: doubleworkEntryMessages(
      entryId: $entryId
      before: $before
      after: $after
      limit: $limit
      self: $self
    ) {
      items {
        id
        message
        senderUser {
          id
        }
        interviewSchedule: doubleworkInterviewSchedule {
          id
          dates {
            id
            startAt
            endAt
            isSelected
            location
            locationDetail
          }
          selectedDate {
            id
            startAt
            endAt
            isSelected
            location
            locationDetail
          }
        }
        interviewScheduleDate: doubleworkInterviewScheduleDate {
          id
          startAt
          endAt
          isSelected
          location
          locationDetail
        }
        readUserIds
        senderUserName
        senderUserAvatarUrl
        isUseAdminAvatar
        fileNames
        files
        variant
        scheduleRole
        createdAt
      }
      hasNext
      hasBefore
      startCursor
      endCursor
    }
  }
`
export interface QueryDoubleworkEntryMessagesResponse {
  entryMessages: {
    items: Array<{
      id: string
      message: string
      senderUser: {
        id: string
      } | null
      interviewSchedule: {
        id: string
        dates: Array<{
          id: string
          startAt: Date
          endAt: Date
          isSelected: boolean
          location: InterviewLocation
          locationDetail: string
        }>
        selectedDate: {
          id: string
          startAt: Date
          endAt: Date
          isSelected: string
          location: string
          locationDetail: string
        } | null
      } | null
      interviewScheduleDate: {
        id: string
        startAt: Date
        endAt: Date
        isSelected: string
        location: InterviewLocation
        locationDetail: string
      } | null
      readUserIds: string[]
      senderUserName: string
      senderUserAvatarUrl: string
      isUseAdminAvatar: boolean
      fileNames: string[]
      files: string[]
      variant: EntryMessageVariant
      scheduleRole: EntryMessageScheduleRole
      createdAt: Date
    }>
    hasNext: boolean | null
    hasBefore: boolean | null
    startCursor: string | null
    endCursor: string | null
  }
}

export const MutationCreateDoubleworkEntryMessage = gql`
  mutation createDoubleworkEntryMessage($input: CreateDoubleworkEntryMessageInput!) {
    createDoubleworkEntryMessage(input: $input) {
      id
    }
  }
`

export interface MutationCreateDoubleworkEntryMessageResponse {
  createDoubleworkEntryMessage: {
    id: string
  }
}

export const MutationCreateDoubleworkInterviewSchedule = gql`
  mutation createDoubleworkInterviewSchedule($input: CreateDoubleworkInterviewScheduleInput!) {
    createDoubleworkInterviewSchedule(input: $input) {
      id
    }
  }
`

export const MutationAdminCreateWorkshopEntry = gql`
  mutation adminCreateWorkshopEntry($input: AdminCreateWorkshopEntryInput!) {
    adminCreateWorkshopEntry(input: $input) {
      id
    }
  }
`

export interface MutationCreateDoubleworkInterviewScheduleResponse {
  createDoubleworkInterviewSchedule: {
    id: string
  }
}

export interface MutationAdminCreateWorkshopEntryResponse {
  adminCreateWorkshopEntry: {
    id: string
  }
}

export const MutationSelectDoubleworkInterviewScheduleDate = gql`
  mutation selectDoubleworkInterviewScheduleDate($input: SelectDoubleworkInterviewScheduleDateInput!) {
    selectDoubleworkInterviewScheduleDate(input: $input) {
      id
      startAt
      endAt
      isSelected
      location
      locationDetail
    }
  }
`

export interface MutationSelectDoubleworkInterviewScheduleDateResponse {
  SelectDoubleworkInterviewScheduleDate: {
    id: string
    startAt: Date
    endAt: Date
    isSelected: string
    location: string
    locationDetail: string
  }
}
export interface BulkCreateDoubleworkEntryMessageInput {
  entryIds: string[]
  message: string
  files: UploadFile[]
}

export const MutationBulkCreateDoubleworkEntryMessages = gql`
  mutation bulkCreateDoubleworkEntryMessages($input: BulkCreateDoubleworkEntryMessageInput!) {
    bulkCreateDoubleworkEntryMessages(input: $input)
  }
`

export interface MutationBulkCreateDoubleworkEntryMessagesResponse {
  bulkCreateDoubleworkEntryMessages: boolean
}

export const MutationAdminUpdateWorkshopEntry = gql`
  mutation adminUpdateWorkshopEntry($input: AdminUpdateWorkshopEntryInput!) {
    adminUpdateWorkshopEntry(input: $input) {
      id
    }
  }
`
export interface MutationAdminUpdateWorkshopEntryResponse {
  adminUpdateWorkshopEntry: {
    id: string
  }
}

export const MutationEntryMessageFileSignedUrl = gql`
  mutation entryMessageFileSignedUrl($messageId: ID!, $fileKey: String!) {
    entryMessageFileSignedUrl(messageId: $messageId, fileKey: $fileKey)
  }
`

export interface MutationEntryMessageFileSignedUrlResponse {
  entryMessageFileSignedUrl: string
}

export const MutationDoubleworkEntryMessageFileSignedUrl = gql`
  mutation doubleworkEntryMessageFileSignedUrl($messageId: ID!, $fileKey: String!) {
    doubleworkEntryMessageFileSignedUrl(messageId: $messageId, fileKey: $fileKey)
  }
`

export interface MutationDoubleworkEntryMessageFileSignedUrlResponse {
  doubleworkEntryMessageFileSignedUrl: string
}

export const QueryManageSurveys = gql`
  query manageSurveys($period: String!, $surveyType: Int!) {
    manageSurveys(period: $period, surveyType: $surveyType) {
      period
      workshopType {
        id
        name
      }
      surveyType
      responseRate
    }
  }
`

export interface QueryManageSurveysResponse {
  manageSurveys: Array<{
    period: string
    workshopType: {
      id: string
      name: string
    }
    surveyType: SurveyType
    responseRate: number
  }>
}

export const MutationBulkExportSurveys = gql`
  mutation bulkExportSurveys($period: String!, $surveyType: Int!, $workshopTypeIds: [ID!]!) {
    bulkExportSurveys(period: $period, surveyType: $surveyType, workshopTypeIds: $workshopTypeIds)
  }
`

export interface MutationBulkExportSurveysResponse {
  bulkExportSurveys: string
}

export const MutationUpdatePostSurvey = gql`
  mutation updatePostSurvey($input: UpdatePostSurveyInput!) {
    updatePostSurvey(input: $input) {
      id
      postSurvey
    }
  }
`

export interface MutationUpdatePostSurveyResponse {
  updatePostSurvey: {
    id: string
    postSurvey: PostOrHalfYearLaterSurveyValues | null
  }
}

export const MutationUpdateHalfYearLaterSurvey = gql`
  mutation updateHalfYearLaterSurvey($input: UpdateHalfYearLaterSurveyInput!) {
    updateHalfYearLaterSurvey(input: $input) {
      id
      halfYearLaterSurvey
    }
  }
`

export interface MutationUpdateHalfYearLaterSurveyResponse {
  updateHalfYearLaterSurvey: {
    id: string
    halfYearLaterSurvey: PostOrHalfYearLaterSurveyValues | null
  }
}

export const QueryDoubleworkEntryMonitoring = gql`
  query doubleworkEntryMonitoring($id: ID!) {
    doubleworkEntry(id: $id) {
      id
      dialogClosedUser {
        id
      }
      user {
        id
      }
      currentEntryFlowStep {
        no
      }
      doubleworkEntryMonitorings {
        id
        doubleworkEntryMonitoringWorkingHours {
          id
          period
          hour
        }
        doubleworkEntryMonitoringMessages {
          id
          user {
            id
            firstName
            lastName
            avatarUrl
          }
          message
          createdAt
        }
      }
      userPermissions
    }
  }
`

export interface QueryDoubleworkEntryMonitoringResponse {
  doubleworkEntry: {
    id: string
    dialogClosedUser: {
      id: string
    } | null
    user: {
      id: string
    }
    currentEntryFlowStep: {
      no: DoubleworkFlowStepNo
    }
    doubleworkEntryMonitorings: Array<{
      id: string
      doubleworkEntryMonitoringWorkingHours: Array<{
        id: string
        period: string
        hour: number | null
      }>
      doubleworkEntryMonitoringMessages: Array<{
        id: string
        user: {
          id: string
          firstName: string
          lastName: string
          avatarUrl: string
        }
        message: string
        createdAt: string
      }>
    }>
    userPermissions: DoubleworkEntryUserPermission[]
  }
}

export const MutationUpdateDoubleworkEntryMonitoring = gql`
  mutation updateDoubleworkEntryMonitoring($input: UpdateDoubleworkEntryMonitoringInput!) {
    updateDoubleworkEntryMonitoring(input: $input)
  }
`

export interface MutationUpdateDoubleworkEntryMonitoringResponse {
  updateDoubleworkEntryMonitoring: boolean
}

export const MutationUpdateDoubleworkEntryDialogClosedUserId = gql`
  mutation updateDoubleworkEntryDialogClosedUserId($input: UpdateDoubleworkEntryDialogClosedUserIdInput!) {
    updateDoubleworkEntryDialogClosedUserId(input: $input)
  }
`

export interface MutationUpdateDoubleworkEntryDialogClosedUserIdResponse {
  updateDoubleworkEntryDialogClosedUserId: boolean
}

export const QueryUserResume = gql`
  query userResume($employeeNumber: String) {
    userResume(employeeNumber: $employeeNumber) {
      id
      isOpenToGroupScout
      isOpenToIndividualScout
      firstName
      lastName
      employeeNumber
      email
      organizationName
      homeOrganizationName
      departmentName
      jobClassificationName
      jobTitle
      gradeName
      selfPr
      prospect
      currentJobClassifications {
        id
        name
      }
      jobClassifications {
        id
        name
      }
      workingLocations {
        id
        name
      }
      userResumeCareers {
        id
        careerType
        startedAt
        endedAt
        organizationName
        departmentName
        detail
        userResumeCareerActivities {
          id
          isOpen
          startedAt
          endedAt
          title
          overview
          image
          youTubeUrl
          url
          file
          fileName
          imageUrl
          fileUrl
          youTubeId
        }
      }
      avatarUrl
      imageUrl
      isResumeOpen
      avatar
      image
      address
      birthDate
      income
      facebookUrl
      twitterUrl
      githubUrl
      linkedinUrl
      introduction
      isSelfPrOpen
      isJobClassificationsOpen
      isWorkingLocationsOpen
      userResumeAchievements {
        id
        file
        fileName
        url
        title
        description
        fileUrl
      }
      userResumeSkills {
        id
        skill {
          id
          name
        }
      }
      backgroundPositionY
      currentJobClassificationIds
      jobClassificationIds
      workingLocationIds
      userResumeQualificationGrades {
        id
        qualificationGrade {
          id
          qualification {
            id
            name
            qualificationCategory {
              id
              externalId
            }
          }
          languageType {
            id
            name
          }
          isScore
          name
        }
        score
        acquisitionDate
      }
      userResumeBlockOrganizations {
        id
        blockOrganization {
          id
          name
        }
      }
      userResumeBlockUsers {
        id
        blockUser {
          id
          employeeNumber
          firstName
          lastName
        }
      }
    }
  }
`
export interface QueryUserResumeResponse {
  userResume: {
    id: string
    isOpenToGroupScout: boolean
    isOpenToIndividualScout: boolean
    firstName: string
    lastName: string
    employeeNumber: string
    email: string
    organizationName: string
    homeOrganizationName: string
    departmentName: string
    jobClassificationName: string
    jobTitle: string
    gradeName: string
    selfPr: string
    prospect: string
    currentJobClassifications: Array<{
      id: string
      name: string
    }>
    jobClassifications: Array<{
      id: string
      name: string
    }>
    workingLocations: Array<{
      id: string
      name: string
    }>
    userResumeCareers: Array<{
      id: string
      careerType: CareerType
      startedAt: string
      endedAt: string
      organizationName: string
      departmentName: string
      detail: string
      userResumeCareerActivities: Array<{
        id: string
        isOpen: boolean
        startedAt: string
        endedAt: string
        title: string
        overview: string
        image: string
        youTubeUrl: string
        url: string
        file: string
        fileName: string
        imageUrl: string
        fileUrl: string
        youTubeId: string
      }>
    }>
    avatarUrl: string
    imageUrl: string
    isResumeOpen: boolean
    avatar: string
    image: string
    address: string
    birthDate: string | null
    income: number | null
    facebookUrl: string
    twitterUrl: string
    githubUrl: string
    linkedinUrl: string
    introduction: string
    isSelfPrOpen: boolean
    isJobClassificationsOpen: boolean
    isWorkingLocationsOpen: boolean
    userResumeAchievements: Array<{
      id: string
      file: string
      fileName: string
      url: string
      title: string
      description: string
      fileUrl: string
    }>
    userResumeSkills: Array<{
      id: string
      skill: {
        id: string
        name: string
      }
    }>
    backgroundPositionY: string
    currentJobClassificationIds: string[]
    jobClassificationIds: string[]
    workingLocationIds: string[]
    userResumeQualificationGrades: Array<{
      id: string
      qualificationGrade: {
        id: string
        qualification: {
          id: string
          name: string
          qualificationCategory: {
            id: string
            externalId: string // externalIdが6なら語学関連資格
          }
        }
        languageType: {
          id: string
          name: string
        } | null
        isScore: boolean
        name: string
      }
      score: number | null
      acquisitionDate: string
    }>
    userResumeBlockOrganizations: Array<{
      id: string
      blockOrganization: {
        id: string
        name: string
      }
    }>
    userResumeBlockUsers: Array<{
      id: string
      blockUser: {
        id: string
        employeeNumber: string
        firstName: string
        lastName: string
      }
    }>
  }
}

export const QueryUserResumeMasters = gql`
  query userResumeMasters {
    organizations {
      value: id
      label: name
    }
    jobClassifications {
      value: id
      label: name
    }
    workingLocations {
      value: id
      label: name
    }
    skills {
      id
      name
    }
    languageTypes {
      id
      name
    }
    qualificationGrades {
      id
      qualification {
        id
        name
        qualificationCategory {
          id
          externalId
        }
      }
      languageType {
        id
        name
      }
      name
      isScore
    }
  }
`

export interface QueryUserResumeMastersResponse {
  organizations: SelectOption[]
  jobClassifications: SelectOption[]
  workingLocations: SelectOption[]
  skills: Array<{
    id: string
    name: string
  }>
  languageTypes: Array<{
    id: string
    name: string
  }>
  qualificationGrades: Array<{
    id: string
    qualification: {
      id: string
      name: string
      qualificationCategory: {
        id: string
        externalId: string
      }
    }
    languageType: {
      id: string
      name: string
    }
    name: string
    isScore: boolean
  }>
}

export const MutationUpdateUserResume = gql`
  mutation updateUserResume($input: UpdateUserResumeInput!) {
    updateUserResume(input: $input) {
      id
      isOpenToGroupScout
      isOpenToIndividualScout
      firstName
      lastName
      employeeNumber
      email
      organizationName
      departmentName
      jobClassificationName
      jobTitle
      gradeName
      selfPr
      prospect
      jobClassifications {
        id
        name
      }
      workingLocations {
        id
        name
      }
      avatarUrl
      imageUrl
      isResumeOpen
      avatar
      image
      address
      birthDate
      income
      facebookUrl
      twitterUrl
      githubUrl
      linkedinUrl
      introduction
      isSelfPrOpen
      jobClassificationIds
      isJobClassificationsOpen
      workingLocationIds
      isWorkingLocationsOpen
    }
  }
`
export interface MutationUpdateUserResumeResponse {
  updateUserResume: {
    id: string
    isOpenToGroupScout: boolean
    isOpenToIndividualScout: boolean
    firstName: string
    lastName: string
    employeeNumber: string
    email: string
    organizationName: string
    departmentName: string
    jobClassificationName: string
    jobTitle: string
    gradeName: string
    selfPr: string
    prospect: string
    jobClassifications: Array<{
      id: string
      name: string
    }>
    workingLocations: Array<{
      id: string
      name: string
    }>
    avatarUrl: string
    imageUrl: string
    isResumeOpen: boolean
    avatar: string
    image: string
    address: string
    birthDate: string | null
    income: string | null
    facebookUrl: string
    twitterUrl: string
    githubUrl: string
    linkedinUrl: string
    introduction: string
    isSelfPrOpen: boolean
    jobClassificationIds: string[]
    isJobClassificationsOpen: boolean
    workingLocationIds: string[]
    isWorkingLocationsOpen: boolean
  }
}

export const MutationCreateOrUpdateUserResumeCareer = gql`
  mutation createOrUpdateUserResumeCareer($input: CreateOrUpdateUserResumeCareerInput!) {
    createOrUpdateUserResumeCareer(input: $input) {
      id
      careerType
      startedAt
      endedAt
      organizationName
      departmentName
      detail
    }
  }
`
export interface MutationCreateOrUpdateUserResumeCareerResponse {
  createOrUpdateUserResumeCareer: {
    id: string
    careerType: CareerType
    startedAt: string
    endedAt: string
    organizationName: string
    departmentName: string
    detail: string
  }
}

export const MutationDeleteUserResumeCareer = gql`
  mutation deleteUserResumeCareer($id: ID!) {
    deleteUserResumeCareer(id: $id)
  }
`
export interface MutationDeleteUserResumeCareerResponse {
  deleteUserResumeCareer: boolean
}

export const MutationCreateOrUpdateUserResumeCareerActivity = gql`
  mutation($input: CreateOrUpdateUserResumeCareerActivityInput!) {
    createOrUpdateUserResumeCareerActivity(input: $input) {
      id
      isOpen
      startedAt
      endedAt
      title
      overview
      image
      youTubeUrl
      url
      file
      fileName
      fileUrl
    }
  }
`
export interface MutationCreateOrUpdateUserResumeCareerActivityResponse {
  createOrUpdateUserResumeCareerActivity: {
    id: string
    isOpen: boolean
    startedAt: string
    endedAt: string
    title: string
    overview: string
    image: string
    youTubeUrl: string
    url: string
    file: string
    fileName: string
    fileUrl: string
  }
}

export const MutationDeleteUserResumeCareerActivity = gql`
  mutation deleteUserResumeCareerActivity($id: ID!) {
    deleteUserResumeCareerActivity(id: $id)
  }
`
export interface MutationDeleteUserResumeCareerActivityResponse {
  deleteUserResumeCareerActivity: boolean
}

export const MutationUpdateUserResumeSkills = gql`
  mutation updateUserResumeSkills($input: UpdateUserResumeSkillsInput!) {
    updateUserResumeSkills(input: $input) {
      id
      userResumeSkills {
        skill {
          id
          name
        }
      }
    }
  }
`
export interface MutationUpdateUserResumeSkillsResponse {
  updateUserResumeSkills: {
    id: string
    userResumeSkills: Array<{
      skill: {
        id: string
        name: string
      }
    }>
  }
}

export const QuerySkills = gql`
  query skills {
    skills {
      value: id
      label: name
    }
  }
`
export interface QuerySkillsResponse {
  skills: SelectOption[]
}

export const MutationCreateOrUpdateUserResumeQualificationGrade = gql`
  mutation($input: CreateOrUpdateUserResumeQualificationGradeInput!) {
    createOrUpdateUserResumeQualificationGrade(input: $input) {
      id
      score
      acquisitionDate
      qualificationGrade {
        id
        name
      }
    }
  }
`
export interface MutationCreateOrUpdateUserResumeQualificationGradeResponse {
  createOrUpdateUserResumeQualificationGrade: {
    id: string
    score: string
    acquisitionDate: string
    qualificationGrade: {
      id: string
      name: string
    }
  }
}

export const MutationDeleteUserResumeQualificationGrade = gql`
  mutation($input: DeleteUserResumeQualificationGradeInput!) {
    deleteUserResumeQualificationGrade(input: $input)
  }
`
export interface MutationDeleteUserResumeQualificationGradeResponse {
  deleteUserResumeQualificationGrade: boolean
}

export const MutationCreateOrUpdateUserResumeAchievement = gql`
  mutation($input: CreateOrUpdateUserResumeAchievementInput!) {
    createOrUpdateUserResumeAchievement(input: $input) {
      id
      file
      fileName
      url
      title
      description
    }
  }
`
export interface MutationCreateOrUpdateUserResumeAchievementResponse {
  createOrUpdateUserResumeAchievement: {
    id: string
    file: string
    fileName: string
    url: string
    title: string
    description: string
  }
}

export const MutationDeleteUserResumeAchievement = gql`
  mutation deleteUserResumeAchievement($id: ID!) {
    deleteUserResumeAchievement(id: $id)
  }
`
export interface MutationDeleteUserResumeAchievementResponse {
  deleteUserResumeAchievement: boolean
}

export const MutationToggleUserResumeBookmark = gql`
  mutation($userResumeId: ID!) {
    toggleUserResumeBookmark(userResumeId: $userResumeId)
  }
`

export interface MutationToggleUserResumeBookmarkResponse {
  toggleUserResumeBookmark: boolean
}

export const QueryManageUserResume = gql`
  query manageUserResume(
    $authOrganizationId: ID
    $isOpenToGroupScout: Boolean
    $isOpenToIndividualScout: Boolean
    $positionId: ID
    $jobClassificationId: ID
    $workingLocationId: ID
    $incomeFrom: Int
    $incomeTo: Int
    $qualificationGrades: [QualificationGradeIdAndScore!]
    $skillIds: [ID!]
    $keywords: [String!]
    $isOnlyBookmarked: Boolean
    $excludeOffered: Boolean
    $sortType: Int
    $seed: String
    $id: ID!
  ) {
    manageUserResume(
      authOrganizationId: $authOrganizationId
      isOpenToGroupScout: $isOpenToGroupScout
      isOpenToIndividualScout: $isOpenToIndividualScout
      positionId: $positionId
      jobClassificationId: $jobClassificationId
      workingLocationId: $workingLocationId
      incomeFrom: $incomeFrom
      incomeTo: $incomeTo
      qualificationGrades: $qualificationGrades
      skillIds: $skillIds
      keywords: $keywords
      isOnlyBookmark: $isOnlyBookmarked
      excludeOffered: $excludeOffered
      sortType: $sortType
      seed: $seed
      id: $id
    ) {
      userResume {
        id
        userId
        isOpenToGroupScout
        isOpenToIndividualScout
        firstName
        lastName
        employeeNumber
        email
        organizationName
        homeOrganizationName
        departmentName
        jobClassificationName
        jobTitle
        gradeName
        selfPr
        prospect
        currentJobClassifications {
          id
          name
        }
        jobClassifications {
          id
          name
        }
        workingLocations {
          id
          name
        }
        userResumeCareers {
          id
          careerType
          startedAt
          endedAt
          organizationName
          departmentName
          detail
          userResumeCareerActivities {
            id
            isOpen
            startedAt
            endedAt
            title
            overview
            image
            youTubeUrl
            url
            file
            fileName
            imageUrl
            fileUrl
            youTubeId
          }
        }
        avatarUrl
        imageUrl
        isResumeOpen
        avatar
        image
        address
        birthDate
        income
        facebookUrl
        twitterUrl
        githubUrl
        linkedinUrl
        introduction
        isSelfPrOpen
        isJobClassificationsOpen
        isWorkingLocationsOpen
        userResumeAchievements {
          id
          file
          fileName
          url
          title
          description
          fileUrl
        }
        userResumeSkills {
          id
          skill {
            id
            name
          }
        }
        backgroundPositionY
        currentJobClassificationIds
        jobClassificationIds
        workingLocationIds
        userResumeQualificationGrades {
          id
          qualificationGrade {
            id
            qualification {
              id
              name
              qualificationCategory {
                id
                externalId
              }
            }
            languageType {
              id
              name
            }
            isScore
            name
          }
          score
          acquisitionDate
        }
        isBookmarked
      }
      beforeId
      afterId
      isValidSearchCondition
    }
  }
`

export interface QueryManageUserResumeResponse {
  manageUserResume: {
    userResume: {
      id: string
      userId: string
      isOpenToGroupScout: boolean
      isOpenToIndividualScout: boolean
      firstName: string
      lastName: string
      employeeNumber: string
      email: string
      organizationName: string
      homeOrganizationName: string
      departmentName: string
      jobClassificationName: string
      jobTitle: string
      gradeName: string
      selfPr: string
      prospect: string
      currentJobClassifications: Array<{
        id: string
        name: string
      }>
      jobClassifications: Array<{
        id: string
        name: string
      }>
      workingLocations: Array<{
        id: string
        name: string
      }>
      userResumeCareers: Array<{
        id: string
        careerType: CareerType
        startedAt: string
        endedAt: string
        organizationName: string
        departmentName: string
        detail: string
        userResumeCareerActivities: Array<{
          id: string
          isOpen: boolean
          startedAt: string
          endedAt: string
          title: string
          overview: string
          image: string
          youTubeUrl: string
          url: string
          file: string
          fileName: string
          imageUrl: string
          fileUrl: string
          youTubeId: string
        }>
      }>
      avatarUrl: string
      imageUrl: string
      isResumeOpen: boolean
      avatar: string
      image: string
      address: string
      birthDate: string | null
      income: number | null
      facebookUrl: string
      twitterUrl: string
      githubUrl: string
      linkedinUrl: string
      introduction: string
      isSelfPrOpen: boolean
      isJobClassificationsOpen: boolean
      isWorkingLocationsOpen: boolean
      userResumeAchievements: Array<{
        id: string
        file: string
        fileName: string
        url: string
        title: string
        description: string
        fileUrl: string
      }>
      userResumeSkills: Array<{
        id: string
        skill: {
          id: string
          name: string
        }
      }>
      backgroundPositionY: string
      currentJobClassificationIds: string[]
      jobClassificationIds: string[]
      workingLocationIds: string[]
      userResumeQualificationGrades: Array<{
        id: string
        qualificationGrade: {
          id: string
          qualification: {
            id: string
            name: string
            qualificationCategory: {
              id: string
              externalId: string // externalIdが6なら語学関連資格
            }
          }
          languageType: {
            id: string
            name: string
          } | null
          isScore: boolean
          name: string
        }
        score: number | null
        acquisitionDate: string
      }>
      isBookmarked: boolean
    }
    beforeId: string | null
    afterId: string | null
    isValidSearchCondition: boolean
  }
}

export const QueryUserResumes = gql`
  query userResumes(
    $authOrganizationId: ID
    $isOpenToGroupScout: Boolean
    $isOpenToIndividualScout: Boolean
    $positionId: ID
    $jobClassificationId: ID
    $workingLocationId: ID
    $incomeFrom: Int
    $incomeTo: Int
    $qualificationGrades: [QualificationGradeIdAndScore!]
    $skillIds: [ID!]
    $keywords: [String!]
    $isOnlyBookmarked: Boolean
    $excludeOffered: Boolean
    $sortType: Int
    $seed: String
    $after: String
    $before: String
    $self: String
    $limit: Int
  ) {
    userResumes(
      authOrganizationId: $authOrganizationId
      isOpenToGroupScout: $isOpenToGroupScout
      isOpenToIndividualScout: $isOpenToIndividualScout
      positionId: $positionId
      jobClassificationId: $jobClassificationId
      workingLocationId: $workingLocationId
      incomeFrom: $incomeFrom
      incomeTo: $incomeTo
      qualificationGrades: $qualificationGrades
      skillIds: $skillIds
      keywords: $keywords
      isOnlyBookmark: $isOnlyBookmarked
      excludeOffered: $excludeOffered
      sortType: $sortType
      seed: $seed
      after: $after
      before: $before
      self: $self
      limit: $limit
    ) {
      items {
        id
        employeeNumber
        firstName
        lastName
        avatarUrl
        isOpenToGroupScout
        isOpenToIndividualScout
        organizationName
        homeOrganizationName
        departmentName
        address
        jobClassifications {
          id
          name
        }
        jobTitle
        userResumeSkills {
          id
          skill {
            id
            name
          }
        }
        isBookmarked
        sendCareerScoutOfferCount
      }
      total
      hasBefore
      hasNext
      startCursor
      endCursor
    }
  }
`

export interface QueryUserResumesResponse {
  userResumes: {
    items: Array<{
      id: string
      employeeNumber: string
      firstName: string
      lastName: string
      avatarUrl: string
      isOpenToGroupScout: boolean
      isOpenToIndividualScout: boolean
      organizationName: string
      homeOrganizationName: string
      departmentName: string
      address: string
      jobClassifications: Array<{
        id: string
        name: string
      }>
      jobTitle: string
      userResumeSkills: Array<{
        id: string
        skill: {
          id: string
          name: string
        }
      }>
      isBookmarked: boolean
      sendCareerScoutOfferCount: number | null
    }>
    total: number
    hasBefore: boolean | null
    hasNext: boolean | null
    startCursor: string | null
    endCursor: string | null
  }
}

export const MutationDeleteCareerScouts = gql`
  mutation deleteCareerScouts($careerScoutIds: [ID!]!) {
    deleteCareerScouts(careerScoutIds: $careerScoutIds)
  }
`

export interface MutationDeleteCareerScoutsResponse {
  deleteCareerScouts: boolean
}

export const MutationSuspendCareerScouts = gql`
  mutation suspendCareerScouts($careerScoutIds: [ID!]!) {
    suspendCareerScouts(careerScoutIds: $careerScoutIds)
  }
`

export interface MutationSuspendCareerScoutsResponse {
  suspendCareerScouts: boolean
}

export const MutationApproveCareerScouts = gql`
  mutation approveCareerScouts($careerScoutIds: [ID!]!) {
    approveCareerScouts(careerScoutIds: $careerScoutIds)
  }
`

export interface MutationApproveCareerScoutsResponse {
  approveCareerScouts: boolean
}

export const MutationBulkExportCareerScouts = gql`
  mutation bulkExportCareerScouts($careerScoutIds: [ID!]!) {
    bulkExportCareerScouts(careerScoutIds: $careerScoutIds)
  }
`

export interface MutationBulkExportCareerScoutsResponse {
  bulkExportCareerScouts: string
}

export const QuerySearchManagedCareerScoutMasters = gql`
  query searchManagedCareerScoutMasters {
    organizations {
      value: id
      label: name
    }
  }
`
export interface QuerySearchManagedCareerScoutMastersResponse {
  organizations: SelectOption[]
}

export const QueryManageCareerScouts = gql`
  query manageCareerScouts(
    $isOpenToGroup: Boolean!
    $isOpenToOrganization: Boolean!
    $id: ID
    $organizationIds: [ID!]
    $responsibleUserName: String
    $name: String
    $status: Int
    $period: String
    $offset: Int
    $limit: Int
  ) {
    manageCareerScouts(
      isOpenToGroup: $isOpenToGroup
      isOpenToOrganization: $isOpenToOrganization
      id: $id
      organizationIds: $organizationIds
      responsibleUserName: $responsibleUserName
      name: $name
      status: $status
      period: $period
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        createdAt
        modifiedAt
        organization {
          id
          name
        }
        authorUser {
          id
          email
        }
        applicationStartedAt
        applicationEndedAt
        name
        responsibleUsers {
          id
          firstName
          lastName
          email
        }
        contactUserIds
        contactUsers {
          id
          email
          firstName
          lastName
          avatarUrl
        }
        contactEmails
        detailImages
        detailImageUrls
        detailCaptions
        isOpenToGroup
        isOpenToOrganization
        status
        arrivalFrequency
        transfer
        personnelComment
        requiredExperience
        isOpenToGroup
        employmentTypeIds
        jobClassification {
          id
          name
        }
        department
        mission
        officeIds
        workingLocationIds
        workingHour
        authorComment
        careerScoutTag {
          tag {
            id
            name
          }
        }
        image
        imageUrl
        detailImages
        detailImageUrls
        annualIncome
        applicationRequirement
        socialInsurance
        selectionFlow
        availableScouts
        employmentTypes {
          id
          name
        }
        offices {
          id
          name
          address
        }
      }
      total
    }
  }
`

export interface QueryManageCareerScoutsResponse {
  manageCareerScouts: {
    items: CareerScoutItem[]
    total: number
  }
}

export interface CareerScoutItem {
  id: string
  createdAt: Date
  modifiedAt: Date
  isOpenToGroup: boolean
  isOpenToOrganization: boolean
  applicationStartedAt: Date
  applicationEndedAt: Date
  organization: {
    id: string
    name: string
  }
  name: string
  mission: string
  jobClassification: {
    id: string
    name: string
  } | null
  department: string
  careerScoutTag: Array<{
    tag: {
      id: string
      name: string
    }
  }>
  applicationRequirement: string
  employmentTypeIds: string[]
  requiredExperience: string
  workingHour: string
  annualIncome: string
  workingLocationIds: string[]
  officeIds: string[]
  arrivalFrequency: string
  transfer: string
  socialInsurance: string
  selectionFlow: string
  responsibleUsers: [
    {
      id: string
      firstName: string
      lastName: string
      email: string
    },
  ]
  contactUserIds: string[]
  contactUsers: Array<{
    id: string
    email: string
    firstName: string
    lastName: string
    avatarUrl: string
  }>
  contactEmails: string[]
  authorComment: string
  personnelComment: string
  image: string
  imageUrl: string
  detailImages: string[]
  detailImageUrls: string[]
  detailCaptions: string[]
  authorUser: {
    id: string
    email: string
  }
  status: number
  availableScouts: number
  employmentTypes: Array<{
    id: string
    name: string
  }>
  offices: Array<{
    id: string
    name: string
    address: string
  }>
}

export const MutationBulkImportCareerScouts = gql`
  mutation bulkImportCareerScouts($files: [Upload!]!) {
    bulkImportCareerScouts(files: $files) {
      created
      updated
      errors
    }
  }
`

export interface MutationBulkImportCareerScoutsResponse {
  bulkImportCareerScouts: {
    created: number
    updated: number
    errors: Array<{
      row: number | null
      column: string | null
      value: string | null
      message: string
    }>
  }
}

export const QueryCareerScoutMasters = gql`
  query careerScoutMasters {
    organizations {
      value: id
      label: name
      canIndividual: canIndividualScout
    }
    employmentTypes {
      id
      name
    }
    jobClassifications {
      value: id
      label: name
    }
    workingLocations {
      value: id
      label: name
    }
  }
`

export interface QueryCareerScoutMastersResponse {
  organizations: Array<{
    value: string
    label: string
    canIndividual: boolean // 個社スカウト可能かどうかの判定用
  }>
  employmentTypes: Array<{
    id: string
    name: string
  }>
  jobClassifications: SelectOption[]
  workingLocations: SelectOption[]
}

export const QueryCareerScoutTags = gql`
  query careerScoutTags {
    tags(type: ${TagType.Position}) {
      value: id
      label: name
    }
  }
`

export interface QueryCareerScoutTagsResponse {
  tags: SelectOption[]
}

export const QueryCareerScoutOrganizationMasters = gql`
  query QueryCareerScoutOrganizationMasters($organizationId: ID!) {
    careerScoutResponsibleUsers(organizationIds: [$organizationId]) {
      id
      firstName
      lastName
      email
      avatarUrl
    }
    offices(organizationId: $organizationId) {
      value: id
      label: name
      address
    }
  }
`

export interface QueryCareerScoutOrganizationMastersResponse {
  careerScoutResponsibleUsers: Array<{
    id: string
    firstName: string
    lastName: string
    email: string
    avatarUrl: string
  }>
  offices: Array<{
    value: string
    label: string
    address: string
  }>
}

export interface CreateCareerScoutInput {
  isOpenToGroup?: boolean
  isOpenToOrganization?: boolean
  applicationStartedAt?: Date
  applicationEndedAt?: Date
  organizationId?: string
  name?: string
  mission?: string
  jobClassificationId?: string | null
  department?: string
  tagNames?: string[]
  applicationRequirement?: string
  employmentTypeIds?: string[]
  requiredExperience?: string
  workingHour?: string
  annualIncome?: string
  workingLocationIds?: string[]
  officeIds?: string[]
  arrivalFrequency?: string
  transfer?: string
  socialInsurance?: string
  selectionFlow?: string
  responsibleUserIds?: string[]
  contactUserIds?: string[]
  contactEmails?: string[]
  authorComment?: string
  personnelComment?: string
  image?: string
  detailImages?: string[]
  detailCaptions?: string[]
  status: CareerScoutStatus
}

export const MutationCreateCareerScout = gql`
  mutation createCareerScout($input: CreateCareerScoutInput!) {
    createCareerScout(input: $input) {
      id
      image
      imageUrl
      detailImages
      detailImageUrls
    }
  }
`

export interface MutationCreateCareerScoutResponse {
  createCareerScout: {
    id: string
    image: string
    imageUrl: string
    detailImages: string[]
    detailImageUrls: string[]
  }
}

export type UpdateCareerScoutInput = CreateCareerScoutInput & {
  id: string
}

export const MutationUpdateCareerScout = gql`
  mutation updateCareerScout($input: UpdateCareerScoutInput!) {
    updateCareerScout(input: $input) {
      id
      image
      imageUrl
      detailImages
      detailImageUrls
    }
  }
`

export interface MutationUpdateCareerScoutResponse {
  updateCareerScout: {
    id: string
    image: string
    imageUrl: string
    detailImages: string[]
    detailImageUrls: string[]
  }
}

export const QueryManageCareerScoutsOption = gql`
  query manageCareerScouts(
    $organizationIds: [ID!]
    $period: String
  ) {
    manageCareerScouts(
      isOpenToGroup: true
      isOpenToOrganization: true
      organizationIds: $organizationIds
      status: ${CareerScoutStatus.AfterApproval}
      period: $period
      limit: 999999
    ) {
      items {
        id
        name
        availableScouts
      }
    }
  }
`

export interface QueryManageCareerScoutsOptionResponse {
  manageCareerScouts: {
    items: Array<{
      id: string
      name: string
      availableScouts: number | null
    }>
  }
}

export const MutationCheckCanOffer = gql`
  mutation checkCanOffer($careerScoutId: ID!, $recipientUserId: ID!) {
    checkCanOffer(careerScoutId: $careerScoutId, recipientUserId: $recipientUserId) {
      id
      message
      files
      fileNames
    }
  }
`

export interface MutationCheckCanOfferResponse {
  checkCanOffer: {
    id: string
    message: string
    files: string[]
    fileNames: string[]
  } | null
}

export const MutationCreateOrUpdateOfferMessage = gql`
  mutation createOrUpdateCareerScoutOfferAndMessage($input: CreateCareerScoutOfferInput!) {
    createOrUpdateCareerScoutOfferAndMessage(input: $input)
  }
`

export interface MutationCreateOrUpdateOfferMessageResponse {
  createOrUpdateCareerScoutOfferAndMessage: boolean
}

export const QueryBlockUsers = gql`
  query users($employeeNumber: String!) {
    users(employeeNumber: $employeeNumber) {
      id
      lastName
      firstName
      employeeNumber
    }
  }
`

export interface QueryBlockUsersResponse {
  users: Array<{
    id: string
    lastName: string
    firstName: string
    employeeNumber: string
  }>
}

export const QueryManageCareerScoutOffers = gql`
  query manageCareerScoutOffers(
    $id: ID
    $careerScoutId: ID
    $organizationId: ID
    $employeeNumber: String
    $senderStatus: Int
    $period: String
    $isSenderUserFlagged: Boolean
    $readStatus: Int
    $offset: Int
    $limit: Int
  ) {
    manageCareerScoutOffers(
      id: $id
      careerScoutId: $careerScoutId
      organizationId: $organizationId
      employeeNumber: $employeeNumber
      senderStatus: $senderStatus
      period: $period
      isSenderUserFlagged: $isSenderUserFlagged
      readStatus: $readStatus
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        offeredAt
        careerScout {
          id
          name
          applicationEndedAt
        }
        careerScoutOfferMessages {
          id
          senderUser {
            id
            firstName
            lastName
            avatarUrl
          }
          senderOrganizationName
          message
          files
          fileNames
          sendAt
          isRead
          fileUrls
          modifiedAt
        }
        isSenderUserFlagged
        senderStatus
        recipientUser {
          id
        }
        recipientUserDetail {
          avatarUrl
          employeeNumber
          firstName
          lastName
          email
          homeOrganizationName
          organizationName
          departmentName
          jobClassificationName
          jobTitle
        }
        recipientUserResume {
          id
          isOpenToGroupScout
          isOpenToIndividualScout
          firstName
          lastName
          employeeNumber
          email
          organizationName
          homeOrganizationName
          departmentName
          jobClassificationName
          jobTitle
          gradeName
          selfPr
          prospect
          currentJobClassifications {
            id
            name
          }
          jobClassifications {
            id
            name
          }
          workingLocations {
            id
            name
          }
          userResumeCareers {
            id
            careerType
            startedAt
            endedAt
            organizationName
            departmentName
            detail
            userResumeCareerActivities {
              id
              isOpen
              startedAt
              endedAt
              title
              overview
              image
              youTubeId
              youTubeUrl
              url
              file
              fileName
              imageUrl
              fileUrl
            }
          }
          avatarUrl
          imageUrl
          isResumeOpen
          avatar
          image
          address
          birthDate
          income
          facebookUrl
          twitterUrl
          githubUrl
          linkedinUrl
          introduction
          isSelfPrOpen
          isJobClassificationsOpen
          isWorkingLocationsOpen
          userResumeAchievements {
            id
            file
            fileName
            url
            title
            description
            fileUrl
          }
          userResumeSkills {
            id
            skill {
              id
              name
            }
          }
          backgroundPositionY
          currentJobClassificationIds
          jobClassificationIds
          workingLocationIds
          userResumeQualificationGrades {
            id
            qualificationGrade {
              id
              qualification {
                id
                name
                qualificationCategory {
                  id
                  externalId
                }
              }
              languageType {
                id
                name
              }
              isScore
              name
            }
            score
            acquisitionDate
          }
          userResumeBlockOrganizations {
            id
            blockOrganization {
              id
              name
            }
          }
          userResumeBlockUsers {
            id
            blockUser {
              id
              employeeNumber
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`

export interface QueryManageCareerScoutOffersResponse {
  manageCareerScoutOffers: {
    items: Array<{
      id: string
      offeredAt: string | null
      careerScout: {
        id: string
        name: string
        applicationEndedAt: string
      }
      careerScoutOfferMessages: Array<{
        id: string
        senderUser: {
          id: string
          firstName: string
          lastName: string
          avatarUrl: string
        }
        senderOrganizationName: string
        message: string
        files: string[]
        fileNames: string[]
        sendAt: string
        isRead: boolean
        fileUrls: string[]
        modifiedAt: string
      }>
      isSenderUserFlagged: boolean
      senderStatus: SenderStatus
      recipientUser: {
        id: string
      }
      recipientUserDetail: {
        avatarUrl: string
        employeeNumber: string
        firstName: string
        lastName: string
        email: string
        homeOrganizationName: string
        organizationName: string
        departmentName: string
        jobClassificationName: string
        jobTitle: string
      }
      recipientUserResume: {
        id: string
        isOpenToGroupScout: boolean
        isOpenToIndividualScout: boolean
        firstName: string
        lastName: string
        employeeNumber: string
        email: string
        organizationName: string
        homeOrganizationName: string
        departmentName: string
        jobClassificationName: string
        jobTitle: string
        gradeName: string
        selfPr: string
        prospect: string
        currentJobClassifications: Array<{
          id: string
          name: string
        }>
        jobClassifications: Array<{
          id: string
          name: string
        }>
        workingLocations: Array<{
          id: string
          name: string
        }>
        userResumeCareers: Array<{
          id: string
          careerType: CareerType
          startedAt: string
          endedAt: string
          organizationName: string
          departmentName: string
          detail: string
          userResumeCareerActivities: Array<{
            id: string
            isOpen: boolean
            startedAt: string
            endedAt: string
            title: string
            overview: string
            image: string
            youTubeId: string
            youTubeUrl: string
            url: string
            file: string
            fileName: string
            imageUrl: string
            fileUrl: string
          }>
        }>
        avatarUrl: string
        imageUrl: string
        isResumeOpen: boolean
        avatar: string
        image: string
        address: string
        birthDate: string | null
        income: number | null
        facebookUrl: string
        twitterUrl: string
        githubUrl: string
        linkedinUrl: string
        introduction: string
        isSelfPrOpen: boolean
        isJobClassificationsOpen: boolean
        isWorkingLocationsOpen: boolean
        userResumeAchievements: Array<{
          id: string
          file: string
          fileName: string
          url: string
          title: string
          description: string
          fileUrl: string
        }>
        userResumeSkills: Array<{
          id: string
          skill: {
            id: string
            name: string
          }
        }>
        backgroundPositionY: string
        currentJobClassificationIds: string[]
        jobClassificationIds: string[]
        workingLocationIds: string[]
        userResumeQualificationGrades: Array<{
          id: string
          qualificationGrade: {
            id: string
            qualification: {
              id: string
              name: string
              qualificationCategory: {
                id: string
                externalId: string // externalIdが6なら語学関連資格
              }
            }
            languageType: {
              id: string
              name: string
            } | null
            isScore: boolean
            name: string
          }
          score: number | null
          acquisitionDate: string
        }>
        userResumeBlockOrganizations: Array<{
          id: string
          blockOrganization: {
            id: string
            name: string
          }
        }>
        userResumeBlockUsers: Array<{
          id: string
          blockUser: {
            id: string
            employeeNumber: string
            firstName: string
            lastName: string
          }
        }>
      } | null
    }>
  }
}

export const MutationToggleCareerScoutOfferSenderUserFlag = gql`
  mutation toggleCareerScoutOfferSenderUserFlag($input: ToggleCareerScoutOfferSenderUserFlagInput!) {
    toggleCareerScoutOfferSenderUserFlag(input: $input)
  }
`

export interface MutationToggleCareerScoutOfferSenderUserFlagResponse {
  toggleCareerScoutOfferSenderUserFlag: boolean
}

export const MutationMarkAsReadCareerScoutOfferMessage = gql`
  mutation markAsReadCareerScoutOfferMessage($input: MarkAsReadCareerScoutOfferMessageInput!) {
    markAsReadCareerScoutOfferMessage(input: $input)
  }
`

export interface MutationMarkAsReadCareerScoutOfferMessageResponse {
  markAsReadCareerScoutOfferMessage: boolean
}

export const MutationDeleteCareerScoutOfferMessage = gql`
  mutation deleteCareerScoutOfferMessage($id: ID!) {
    deleteCareerScoutOfferMessage(id: $id)
  }
`

export interface MutationDeleteCareerScoutOfferMessageResponse {
  deleteCareerScoutOfferMessage: boolean
}

export const QueryCareerScoutOffers = gql`
  query careerScoutOffers(
    $id: ID
    $recipientStatus: Int
    $isOnlyCurrentPeriod: Boolean
    $isRecipientUserFlagged: Boolean
    $isOnlyUnread: Boolean
    $offset: Int
    $limit: Int
  ) {
    careerScoutOffers(
      id: $id
      recipientStatus: $recipientStatus
      isOnlyCurrentPeriod: $isOnlyCurrentPeriod
      isRecipientUserFlagged: $isRecipientUserFlagged
      isOnlyUnread: $isOnlyUnread
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        careerScout {
          id
          name
          jobClassification {
            id
            name
          }
          organization {
            id
            name
          }
        }
        careerScoutOfferMessages {
          id
          senderUser {
            id
            firstName
            lastName
            avatarUrl
          }
          senderOrganizationName
          message
          files
          fileNames
          sendAt
          isRead
          fileUrls
          modifiedAt
        }
        isRecipientUserFlagged
        recipientUser {
          id
        }
        recipientUserDetail {
          avatarUrl
          employeeNumber
          firstName
          lastName
          email
          homeOrganizationName
          organizationName
          departmentName
          jobClassificationName
          jobTitle
        }
        senderOrganization {
          name
        }
        latestCareerScoutOfferMessage {
          message
          isRead
          sendAt
        }
        hasDraftMessage
        isRecipientUserFlagged
        careerScoutEntry {
          id
          isTemporarySaved
        }
      }
      total
    }
  }
`

export interface QueryCareerScoutOffersResponse {
  careerScoutOffers: {
    items: Array<{
      id: string
      careerScout: {
        id: string
        name: string
        jobClassification: {
          id: string
          name: string
        }
        organization: {
          id: string
          name: string
        }
      }
      careerScoutOfferMessages: Array<{
        id: string
        senderUser: {
          id: string
          firstName: string
          lastName: string
          avatarUrl: string
        }
        senderOrganizationName: string
        message: string
        files: string[]
        fileNames: string[]
        sendAt: string
        isRead: boolean
        fileUrls: string[]
        modifiedAt: string
      }>
      isRecipintUserFlagged: boolean
      recipientUser: {
        id: string
      }
      recipientUserDetail: {
        avatarUrl: string
        employeeNumber: string
        firstName: string
        lastName: string
        email: string
        homeOrganizationName: string
        organizationName: string
        departmentName: string
        jobClassificationName: string
        jobTitle: string
      }
      senderOrganization: {
        name: string
      }
      latestCareerScoutOfferMessage: {
        message: string
        isRead: boolean
        sendAt: string
      }
      hasDraftMessage: boolean
      isRecipientUserFlagged: boolean
      careerScoutEntry: {
        id: string
        isTemporarySaved: boolean
      } | null
    }>
    total: number
  }
}

export const QueryCareerScoutOffersForCareerScoutDetail = gql`
  query careerScoutOffers(
    $id: ID
    $recipientStatus: Int
    $isOnlyCurrentPeriod: Boolean
    $isRecipientUserFlagged: Boolean
    $isOnlyUnread: Boolean
    $offset: Int
    $limit: Int
  ) {
    careerScoutOffers(
      id: $id
      recipientStatus: $recipientStatus
      isOnlyCurrentPeriod: $isOnlyCurrentPeriod
      isRecipientUserFlagged: $isRecipientUserFlagged
      isOnlyUnread: $isOnlyUnread
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        careerScout {
          id
          createdAt
          modifiedAt
          organization {
            id
            name
          }
          authorUser {
            id
            email
          }
          applicationStartedAt
          applicationEndedAt
          name
          responsibleUsers {
            id
            firstName
            lastName
            email
          }
          contactUserIds
          contactUsers {
            id
            email
            firstName
            lastName
            avatarUrl
          }
          contactEmails
          detailImages
          detailImageUrls
          detailCaptions
          isOpenToGroup
          isOpenToOrganization
          status
          arrivalFrequency
          transfer
          personnelComment
          requiredExperience
          isOpenToGroup
          employmentTypeIds
          jobClassification {
            id
            name
          }
          department
          mission
          officeIds
          workingLocationIds
          workingHour
          authorComment
          careerScoutTag {
            tag {
              id
              name
            }
          }
          image
          imageUrl
          detailImages
          detailImageUrls
          annualIncome
          applicationRequirement
          socialInsurance
          selectionFlow
          availableScouts
          employmentTypes {
            id
            name
          }
          offices {
            id
            name
            address
          }
        }
      }
      total
    }
  }
`

export interface QueryCareerScoutOffersForCareerScoutDetailResponse {
  careerScoutOffers: {
    items: Array<{
      id: string
      careerScout: CareerScoutItem
    }>
    total: number
  }
}

export const QueryManageCareerScoutOffersList = gql`
  query manageCareerScoutOffers(
    $id: ID
    $careerScoutId: ID
    $organizationId: ID
    $employeeNumber: String
    $senderStatus: Int
    $period: String
    $isSenderUserFlagged: Boolean
    $readStatus: Int
    $offset: Int
    $limit: Int
  ) {
    manageCareerScoutOffers(
      id: $id
      careerScoutId: $careerScoutId
      organizationId: $organizationId
      employeeNumber: $employeeNumber
      senderStatus: $senderStatus
      period: $period
      isSenderUserFlagged: $isSenderUserFlagged
      readStatus: $readStatus
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        careerScout {
          id
          name
        }
        recipientUser {
          id
          firstName
          lastName
        }
        isSenderUserFlagged
        recipientOrganization {
          id
          name
        }
        senderStatus
        latestCareerScoutOfferMessage {
          id
          message
          sendAt
          isRead
        }
        hasDraftMessage
      }
      total
    }
  }
`

export interface QueryManageCareerScoutOffersListResponse {
  manageCareerScoutOffers: {
    items: Array<{
      id: string
      careerScout: {
        id: string
        name: string
      }
      recipientUser: {
        id: string
        firstName: string
        lastName: string
      }
      isSenderUserFlagged: boolean
      recipientOrganization: {
        id: string
        name: string
      }
      senderStatus: SenderStatus
      latestCareerScoutOfferMessage: {
        id: string
        message: string
        sendAt: string
        isRead: boolean
      }
      hasDraftMessage: boolean
    }>
    total: number
  }
}

export const QueryManageCareerScoutsIdandName = gql`
  query manageCareerScouts(
    $isOpenToGroup: Boolean!
    $isOpenToOrganization: Boolean!
    $organizationIds: [ID!]
    $status: Int
    $period: String
    $limit: Int
  ) {
    manageCareerScouts(
      isOpenToGroup: $isOpenToGroup
      isOpenToOrganization: $isOpenToOrganization
      organizationIds: $organizationIds
      status: $status
      period: $period
      limit: $limit
    ) {
      items {
        id
        name
      }
    }
  }
`

export interface QueryManageCareerScoutsIdandNameResponse {
  manageCareerScouts: {
    items: Array<{
      id: string
      name: string
    }>
  }
}

export const MutationToggleCareerScoutOfferRecipientUserFlag = gql`
  mutation toggleCareerScoutOfferRecipientUserFlag($input: ToggleCareerScoutOfferRecipientUserFlagInput!) {
    toggleCareerScoutOfferRecipientUserFlag(input: $input)
  }
`

export interface MutationToggleCareerScoutOfferRecipientUserFlagResponse {
  toggleCareerScoutOfferRecipientUserFlag: boolean
}

export const QueryCareerScoutTemplates = gql`
  query careerScoutTemplates($id: ID) {
    careerScoutTemplates(id: $id) {
      id
      name
      text
      organization {
        id
      }
    }
  }
`

export interface QueryCareerScoutTemplatesResponse {
  careerScoutTemplates: Array<{
    id: string
    name: string
    text: string
    organization: {
      id: string
    } | null
  }>
}

export const MutationDeleteCareerScoutTemplates = gql`
  mutation deleteCareerScoutTemplates($templateIds: [ID!]!) {
    deleteCareerScoutTemplates(templateIds: $templateIds)
  }
`

export interface MutationDeleteCareerScoutTemplatesResponse {
  deleteCareerScoutTemplates: boolean
}

export const MutationCreateCareerScoutTemplate = gql`
  mutation createCareerScoutTemplate($input: CreateCareerScoutTemplateInput!) {
    createCareerScoutTemplate(input: $input)
  }
`

export interface MutationCreateCareerScoutTemplateResponse {
  createCareerScoutTemplate: boolean
}

export const MutationUpdateCareerScoutTemplate = gql`
  mutation updateCareerScoutTemplate($input: UpdateCareerScoutTemplateInput!) {
    updateCareerScoutTemplate(input: $input)
  }
`
export interface MutationUpdateCareerScoutTemplateResponse {
  updateCareerScoutTemplate: boolean
}

export const MutationCreateCareerScoutEntry = gql`
  mutation createCareerScoutEntry($input: CreateCareerScoutEntryInput!) {
    createCareerScoutEntry(input: $input) {
      id
      firstName
      firstNameKana
      lastName
      lastNameKana
      phoneNumber
      email
      zipCode
      address1
      address2
      address3
      currentIncome
      currentJobDetail
      selfPr
      entryReason
      consideration
      resumeFile
      resumeFileName
    }
  }
`

export interface MutationCreateCareerScoutEntryResponse {
  createCareerScoutEntry: {
    id: string
    firstName: string
    firstNameKana: string
    lastName: string
    lastNameKana: string
    phoneNumber: string
    email: string
    zipCode: string
    address1: string
    address2: string
    address3: string
    currentIncome: number
    currentJobDetail: string
    selfPr: string
    entryReason: string
    consideration: string
    resumeFile: string
    resumeFileName: string
    resumeFileUrl: string
  }
}

export const MutationUpdateCareerScoutEntry = gql`
  mutation updateCareerScoutEntry($input: UpdateCareerScoutEntryInput!) {
    updateCareerScoutEntry(input: $input) {
      id
      firstName
      firstNameKana
      lastName
      lastNameKana
      phoneNumber
      email
      zipCode
      address1
      address2
      address3
      currentIncome
      currentJobDetail
      selfPr
      entryReason
      consideration
      resumeFile
      resumeFileName
    }
  }
`

export interface MutationUpdateCareerScoutEntryResponse {
  updateCareerScoutEntry: {
    id: string
    firstName: string
    firstNameKana: string
    lastName: string
    lastNameKana: string
    phoneNumber: string
    email: string
    zipCode: string
    address1: string
    address2: string
    address3: string
    currentIncome: number
    currentJobDetail: string
    selfPr: string
    entryReason: string
    consideration: string
    resumeFile: string
    resumeFileName: string
    resumeFileUrl: string
  }
}

export const MutationDeleteCareerScoutEntries = gql`
  mutation deleteCareerScoutEntries($ids: [ID!]!) {
    deleteCareerScoutEntries(ids: $ids)
  }
`

export interface MutationDeleteCareerScoutEntriesResponse {
  deleteCareerScoutEntries: boolean
}

export const QueryCareerScoutEntries = gql`
  query careerScoutEntries($entryTypes: [Int!], $isIncludeClosed: Boolean, $id: ID, $offset: Int, $limit: Int) {
    careerScoutEntries(
      entryTypes: $entryTypes
      isIncludeClosed: $isIncludeClosed
      id: $id
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        firstName
        firstNameKana
        lastName
        lastNameKana
        phoneNumber
        email
        zipCode
        address1
        address2
        address3
        currentIncome
        currentJobDetail
        selfPr
        entryReason
        consideration
        resumeFile
        resumeFileName
        resumeFileUrl
        enteredAt
        entryPledgeAgreement {
          id
          pledgeVersion
        }
        entryType
        salaryType
        basicSalary
        overtimePay
        jobAllowance
        bonus
      }
      total
    }
  }
`

export interface QueryCareerScoutEntriesResponse {
  careerScoutEntries: {
    items: Array<{
      id: string
      firstName: string
      firstNameKana: string
      lastName: string
      lastNameKana: string
      phoneNumber: string
      email: string
      zipCode: string
      address1: string
      address2: string
      address3: string
      currentIncome: string
      currentJobDetail: string
      selfPr: string
      entryReason: string
      consideration: string
      resumeFile: string
      resumeFileName: string
      resumeFileUrl: string
      enteredAt: string
      entryPledgeAgreement: {
        id: string
        pledgeVersion: string
      } | null
      entryType: CareerScoutEntryType
      salaryType: SalaryType
      basicSalary: number | null
      overtimePay: number | null
      jobAllowance: number | null
      bonus: number | null
    }>
    total: number
  }
}

export const QueryCareerScoutEntry = gql`
  query careerScoutEntry($id: ID, $offerId: ID) {
    careerScoutEntry(id: $id, offerId: $offerId) {
      id
      firstName
      firstNameKana
      lastName
      lastNameKana
      phoneNumber
      email
      zipCode
      address1
      address2
      address3
      currentIncome
      currentJobDetail
      selfPr
      entryReason
      consideration
      resumeFile
      resumeFileName
      resumeFileUrl
      enteredAt
      entryPledgeAgreement {
        id
        pledgeVersion
      }
      entryType
      salaryType
      basicSalary
      overtimePay
      jobAllowance
      bonus
      userResumeSnapshot {
        id
        careerScoutEntry {
          id
        }
        isOpenToGroupScout
        isOpenToIndividualScout
        isResumeOpen
        avatar
        avatarUrl
        image
        imageUrl
        backgroundPositionY
        address
        birthDate
        income
        facebookUrl
        twitterUrl
        linkedinUrl
        githubUrl
        introduction
        selfPr
        isSelfPrOpen
        prospect
        isJobClassificationsOpen
        isWorkingLocationsOpen
        firstName
        lastName
        employeeNumber
        email
        organizationName
        homeOrganizationName
        departmentName
        jobClassificationName
        jobTitle
        gradeName
        careers
        achievements
        currentJobClassifications
        jobClassifications
        workingLocations
        skills
        qualificationGrades
      }
      careerScout {
        id
        name
        organization {
          id
          name
        }
        department
        jobClassification {
          id
          name
        }
        careerScoutTag {
          id
          tag {
            id
            name
          }
        }
        imageUrl
        mission
        applicationRequirement
        employmentTypes {
          name
        }
        requiredExperience
        detailImageUrls
        detailCaptions
        workingHour
        annualIncome
        arrivalFrequency
        transfer
        socialInsurance
        offices {
          id
          name
          address
        }
        contactUsers {
          id
          firstName
          lastName
          avatarUrl
          email
        }
        contactEmails
        authorComment
        selectionFlow
      }
    }
  }
`

export interface QueryCareerScoutEntryResponse {
  careerScoutEntry: {
    id: string
    firstName: string
    firstNameKana: string
    lastName: string
    lastNameKana: string
    phoneNumber: string
    email: string
    zipCode: string
    address1: string
    address2: string
    address3: string
    currentIncome: string
    currentJobDetail: string
    selfPr: string
    entryReason: string
    consideration: string
    resumeFile: string
    resumeFileName: string
    resumeFileUrl: string
    enteredAt: string
    entryPledgeAgreement: {
      id: string
      pledgeVersion: string
    } | null
    entryType: CareerScoutEntryType
    salaryType: SalaryType
    basicSalary: number | null
    overtimePay: number | null
    jobAllowance: number | null
    bonus: number | null
    userResumeSnapshot: {
      id: string
      careerScoutEntry: {
        id: string
      }
      isOpenToGroupScout: boolean
      isOpenToIndividualScout: boolean
      isResumeOpen: boolean
      avatar: string
      avatarUrl: string
      image: string
      imageUrl: string
      backgroundPositionY: string
      address: string
      birthDate: string | null
      income: string | null
      facebookUrl: string
      twitterUrl: string
      githubUrl: string
      linkedinUrl: string
      introduction: string
      selfPr: string
      isSelfPrOpen: boolean
      prospect: string
      isJobClassificationsOpen: boolean
      isWorkingLocationsOpen: boolean
      firstName: string
      lastName: string
      employeeNumber: string
      email: string
      organizationName: string
      homeOrganizationName: string
      departmentName: string
      jobClassificationName: string
      jobTitle: string
      gradeName: string
      careers: ResumeSnapshotCareers[]
      achievements: ResumeSnapshotAchievement[]
      currentJobClassifications: ResumeSnapshotMaster[]
      jobClassifications: ResumeSnapshotMaster[]
      workingLocations: ResumeSnapshotMaster[]
      skills: ResumeSnapshotSkill[]
      qualificationGrades: ResumeSnapshotQualificationGrade[]
    }
    careerScout: {
      id: string
      name: string
      organization: {
        name: string
      }
      department: string
      jobClassification: {
        name: string
      }
      careerScoutTag: Array<{
        tag: {
          name: string
        }
      }>
      imageUrl: string
      mission: string
      applicationRequirement: string
      employmentTypes: Array<{
        name: string
      }>
      requiredExperience: string
      detailImageUrls: string[]
      detailCaptions: string[]
      workingHour: string
      annualIncome: string
      arrivalFrequency: string
      transfer: string
      socialInsurance: string
      offices: Array<{
        name: string
        address: string
      }>
      contactUsers: Array<{
        firstName: string
        lastName: string
        avatarUrl: string
        email: string
      }>
      contactEmails: string[]
      authorComment: string
      selectionFlow: string
    }
  }
}

export const MutationCheckCanCareerScoutEntry = gql`
  mutation checkCanCareerScoutEntry($careerScoutOfferId: ID!) {
    checkCanCareerScoutEntry(careerScoutOfferId: $careerScoutOfferId)
  }
`
export interface MutationCheckCanCareerScoutEntryResponse {
  checkCanCareerScoutEntry: boolean
}

export interface CareerScoutAcceptOrDeclineOfferInput {
  entryId: string
  isAccepted: boolean
  declineReasonCategories: EntryDeclineReasonCategory[] | null
  declineReasonDetail: string | null
}
export interface MutationCareerScoutAcceptOrDeclineOfferResponce {
  careerScoutAcceptOrDeclineOffer: {
    id: string
    firstName: string
    firstNameKana: string
    lastName: string
    lastNameKana: string
    phoneNumber: string
    email: string
    zipCode: string
    address1: string
    address2: string
    address3: string
    currentIncome: number
    currentJobDetail: string
    entryReason: string
    selfPr: string
    consideration: string
    resumeFileUrl: string
    resumeFileName: string
    entryType: EntryType
    currentEntryFlowStep: {
      no: number
    }
    userPermissions: EntryUserPermission[]
  }
}
export const MutationCareerScoutAcceptOrDeclineOffer = gql`
  mutation careerScoutAcceptOrDeclineOffer($input: CareerScoutAcceptOrDeclineOfferInput!) {
    careerScoutAcceptOrDeclineOffer(input: $input) {
      id
      firstName
      firstNameKana
      lastName
      lastNameKana
      phoneNumber
      email
      zipCode
      address1
      address2
      address3
      currentIncome
      currentJobDetail
      entryReason
      selfPr
      consideration
      resumeFileUrl
      resumeFileName
      entryType
      currentEntryFlowStep {
        no
      }
      userPermissions
    }
  }
`

export const MutationCareerScoutCheckCanEntry = gql`
  mutation careerScoutCheckCanEntry($positionId: ID!) {
    careerScoutCheckCanEntry(positionId: $positionId)
  }
`
export interface MutationCareerScoutCheckCanEntryResponse {
  canEntry: boolean
}

export const MutationUpdateCareerScoutEntryRemark = gql`
  mutation updateCareerScoutEntryRemark($input: UpdateCareerScoutEntryRemarkInput!) {
    updateCareerScoutEntryRemark(input: $input) {
      id
      remark
    }
  }
`
export interface MutationUpdateCareerScoutEntryRemarkResponse {
  updateCareerScoutEntryRemark: {
    id: string
    remark: string | null
  }
}

export const QueryCareerScoutEntryDetail = gql`
  query careerScoutEntryDetail($id: ID, $offerId: ID) {
    careerScoutEntry(id: $id, offerId: $offerId) {
      id
      entryType
      firstName
      firstNameKana
      lastName
      lastNameKana
      phoneNumber
      email
      zipCode
      address1
      address2
      address3
      currentIncome
      currentJobDetail
      selfPr
      entryReason
      consideration
      resumeFile
      resumeFileUrl
      resumeFileName
      currentEntryFlowStep {
        no
      }
      entryPledgeAgreement {
        id
      }
      userPermissions
      remark
      unreadOrLastEntryMessageId
      salaryType
      basicSalary
      overtimePay
      jobAllowance
      bonus
    }
  }
`
export interface QueryCareerScoutEntryDetailResponse {
  careerScoutEntry: {
    id: string
    entryType: CareerScoutEntryType
    firstName: string
    firstNameKana: string
    lastName: string
    lastNameKana: string
    phoneNumber: string
    email: string
    zipCode: string
    address1: string
    address2: string
    address3: string
    currentIncome: string
    currentJobDetail: string
    selfPr: string
    entryReason: string
    consideration: string
    resumeFile: string
    resumeFileName: string
    resumeFileUrl: string
    enteredAt: string
    currentEntryFlowStep: {
      no: CareerScoutGroupFlowStepNo | CareerScoutIndividualFlowStepNo
    }
    entryPledgeAgreement: {
      id: string
    } | null
    userPermissions: EntryUserPermission[]
    remark: string | null
    unreadOrLastEntryMessageId: string
    salaryType: SalaryType
    basicSalary: number | null
    overtimePay: number | null
    jobAllowance: number | null
    bonus: number | null
  }
}

export const QueryCareerScoutEntryNavigation = gql`
  query careerScoutEntryNavigation($id: ID!) {
    careerScoutEntry(id: $id) {
      id
      entryType
      createdAt
      firstName
      lastName
      employeeNumber
      user {
        id
      }
      careerScout {
        id
        jobClassification {
          name
        }
      }
      currentEntryFlowStep {
        name
        candidateVisibleName
        no
      }
      interviews {
        id
        status
        careerScoutEntryFlowStepNo
      }
      latestNoneCasualInterview {
        id
      }
      userPermissions
      homeOrganizationName
      isSupportRequired
      isCareerScoutSpecialApplicationUser
      enteredAt
    }
  }
`
export interface QueryCareerScoutEntryNavigationResponse {
  careerScoutEntry: {
    id: string
    entryType: CareerScoutEntryType
    createdAt: Date
    firstName: string
    lastName: string
    employeeNumber: string
    user: {
      id: string
    }
    careerScout: {
      id: string
      jobClassification: {
        name: string
      }
    }
    currentEntryFlowStep: {
      name: string
      candidateVisibleName: string
      no: number
    }
    interviews: Array<{
      id: string
      status: InterviewStatus
      careerScoutEntryFlowStepNo: string
    }>
    latestNoneCasualInterview: {
      id: string
    } | null
    userPermissions: EntryUserPermission[]
    homeOrganizationName: string
    isSupportRequired: boolean | null
    isCareerScoutSpecialApplicationUser: boolean | null
    enteredAt: string
  }
}

export const MutationUpdateCareerScoutEntriesFlowStep = gql`
  mutation updateCareerScoutEntriesFlowStep($input: UpdateCareerScoutEntriesFlowStepInput!) {
    updateCareerScoutEntriesFlowStep(input: $input)
  }
`

export const MutationUpdateCareerScoutEntryIsSupportRequired = gql`
  mutation updateCareerScoutEntryIsSupportRequired($input: UpdateCareerScoutEntryIsSupportRequiredInput!) {
    updateCareerScoutEntryIsSupportRequired(input: $input)
  }
`

export interface MutationUpdateCareerScoutEntryIsSupportRequiredResponse {
  updateCareerScoutEntryIsSupportRequired: boolean
}

export const MutationCreateCareerScoutEntryMessage = gql`
  mutation createCareerScoutEntryMessage($input: CreateCareerScoutEntryMessageInput!) {
    createCareerScoutEntryMessage(input: $input) {
      id
    }
  }
`

export interface MutationCreateCareerScoutEntryMessageResponse {
  createCareerScoutEntryMessage: {
    id: string
  }
}

export const QueryCareerScoutEntryInterviews = gql`
  query careerScoutEntryInterviews($id: ID!) {
    careerScoutEntry(id: $id) {
      id
      entryType
      user {
        id
      }
      currentEntryFlowStep {
        no
        name
        candidateVisibleName
      }
      careerScout {
        id
        organization {
          id
        }
      }
      interviews {
        id
        status
        resultStatus
        careerScoutEntryFlowStepNo
        careerScoutInterviewerUsers {
          id
          user {
            id
            firstName
            lastName
            email
          }
        }
        latestSchedule {
          id
          selectedDate {
            id
            startAt
            endAt
            location
            locationDetail
          }
        }
      }
    }
  }
`
export interface QueryCareerScoutEntryInterviewsResponse {
  careerScoutEntry: {
    id: string
    entryType: CareerScoutEntryType
    user: {
      id: string
    }
    currentEntryFlowStep: {
      no: CareerScoutGroupFlowStepNo | CareerScoutIndividualFlowStepNo
      name: string
      candidateVisibleName: string
    }
    careerScout: {
      organization: {
        id: string
      }
    }
    interviews: Array<{
      id: string
      status: InterviewStatus
      resultStatus: InterviewResultStatus
      careerScoutEntryFlowStepNo: CareerScoutGroupFlowStepNo | CareerScoutIndividualFlowStepNo
      careerScoutInterviewerUsers: Array<{
        user: {
          id: string
          firstName: string
          lastName: string
          email: string
        }
      }>
      latestSchedule: {
        selectedDate: {
          startAt: string
          endAt: string
          location: InterviewLocation
          locationDetail: string
        } | null
      } | null
    }>
  }
}

export const MutationCreateCareerScoutInterviewAndInterviewerUser = gql`
  mutation createCareerScoutInterviewAndInterviewerUser($input: CreateCareerScoutInterviewAndInterviewerUserInput!) {
    createCareerScoutInterviewAndInterviewerUser(input: $input) {
      id
    }
  }
`

export interface MutationCreateCareerScoutInterviewAndInterviewerUserResponse {
  createCareerScoutInterviewAndInterviewerUser: {
    id: string
  }
}

export const MutationCreateCareerScoutInterviewSchedule = gql`
  mutation createCareerScoutInterviewSchedule($input: CreateCareerScoutInterviewScheduleInput!) {
    createCareerScoutInterviewSchedule(input: $input) {
      id
    }
  }
`
export interface MutationCreateCareerScoutInterviewScheduleResponse {
  createCareerScoutInterviewSchedule: {
    id: string
  }
}

export const MutationSelectCareerScoutInterviewScheduleDate = gql`
  mutation selectCareerScoutInterviewScheduleDate($input: SelectCareerScoutInterviewScheduleDateInput!) {
    selectCareerScoutInterviewScheduleDate(input: $input) {
      id
      startAt
      endAt
      isSelected
      location
      locationDetail
    }
  }
`
export interface MutationSelectCareerScoutInterviewScheduleDateResponse {
  selectCareerScoutInterviewScheduleDate: {
    id: string
    startAt: Date
    endAt: Date
    isSelected: string
    location: string
    locationDetail: string
  }
}

export const MutationCareerScoutEntryMessageFileSignedUrl = gql`
  mutation careerScoutEntryMessageFileSignedUrl($messageId: ID!, $fileKey: String!) {
    careerScoutEntryMessageFileSignedUrl(messageId: $messageId, fileKey: $fileKey)
  }
`
export interface MutationCareerScoutEntryMessageFileSignedUrlResponse {
  careerScoutEntryMessageFileSignedUrl: string
}

export const QueryCareerScoutEntryMessages = gql`
  query careerScoutEntryMessages($entryId: ID!, $before: String, $after: String, $limit: Int, $self: String) {
    entryMessages: careerScoutEntryMessages(
      entryId: $entryId
      before: $before
      after: $after
      limit: $limit
      self: $self
    ) {
      items {
        id
        message
        senderUser {
          id
        }
        interviewSchedule: careerScoutInterviewSchedule {
          id
          dates: careerScoutInterviewScheduleDates {
            id
            startAt
            endAt
            isSelected
            location
            locationDetail
          }
          selectedDate {
            id
            startAt
            endAt
            isSelected
            location
            locationDetail
          }
        }
        interviewScheduleDate: careerScoutInterviewScheduleDate {
          id
          startAt
          endAt
          isSelected
          location
          locationDetail
        }
        readUserIds
        senderUserName
        senderUserAvatarUrl
        isUseAdminAvatar
        fileNames
        files
        variant
        scheduleRole
        createdAt
      }
      hasNext
      hasBefore
      startCursor
      endCursor
    }
  }
`
export interface QueryCareerScoutEntryMessagesResponse {
  entryMessages: {
    items: Array<{
      id: string
      message: string
      senderUser: {
        id: string
      } | null
      interviewSchedule: {
        id: string
        dates: Array<{
          id: string
          startAt: Date
          endAt: Date
          isSelected: boolean
          location: InterviewLocation
          locationDetail: string
        }>
        selectedDate: {
          id: string
          startAt: Date
          endAt: Date
          isSelected: string
          location: string
          locationDetail: string
        } | null
      } | null
      interviewScheduleDate: {
        id: string
        startAt: Date
        endAt: Date
        isSelected: string
        location: InterviewLocation
        locationDetail: string
      } | null
      readUserIds: string[]
      senderUserName: string
      senderUserAvatarUrl: string
      isUseAdminAvatar: boolean
      fileNames: string[]
      files: string[]
      variant: EntryMessageVariant
      scheduleRole: EntryMessageScheduleRole
      createdAt: Date
    }>
    hasNext: boolean | null
    hasBefore: boolean | null
    startCursor: string | null
    endCursor: string | null
  }
}

export const QueryManageCareerScoutInterviewEntries = gql`
  query manageCareerScoutInterviewEntries(
    $entryType: Int
    $entryFlowStepNo: Int
    $interviewStatus: Int
    $interviewResultStatus: Int
    $interviewerUserId: ID
    $isOnlyCurrentPeriod: Boolean
    $offset: Int
    $limit: Int
  ) {
    manageCareerScoutInterviewEntries(
      entryType: $entryType
      entryFlowStepNo: $entryFlowStepNo
      interviewStatus: $interviewStatus
      interviewResultStatus: $interviewResultStatus
      interviewerUserId: $interviewerUserId
      isOnlyCurrentPeriod: $isOnlyCurrentPeriod
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        position: careerScout {
          id
          name
        }
        currentEntryFlowStep {
          name
          no
        }
        currentInterview {
          id
          status
          resultStatus
          interviewerUsers: careerScoutInterviewerUsers {
            id
            user {
              id
              firstName
              lastName
            }
          }
          latestSchedule {
            id
            selectedDate {
              id
              startAt
              endAt
            }
          }
        }
        employeeNumber
        firstName
        lastName
      }
      total
    }
  }
`

export interface QueryManageCareerScoutInterviewEntriesResponse {
  manageCareerScoutInterviewEntries: {
    items: Array<{
      id: string
      position: {
        name: string
      }
      currentEntryFlowStep: {
        name: string
        no: number
      }
      currentInterview: {
        id: string
        status: InterviewStatus
        resultStatus: InterviewResultStatus
        interviewerUsers: Array<{
          user: {
            firstName: string
            lastName: string
          }
        }>
        latestSchedule: {
          selectedDate: {
            startAt: Date
            endAt: Date
          } | null
        } | null
      }
      employeeNumber: string
      firstName: string
      lastName: string
    }>
    total: number
  }
}

export const MutationUpdateCareerScoutInterviewStatus = gql`
  mutation updateCareerScoutInterviewStatus($input: UpdateCareerScoutInterviewStatusInput!) {
    updateCareerScoutInterviewStatus(input: $input)
  }
`
export interface MutationUpdateCareerScoutInterviewStatusResponse {
  updateCareerScoutInterviewStatus: boolean
}

export const MutationUpdateCareerScoutInterviewerUser = gql`
  mutation updateCareerScoutInterviewerUser($input: UpdateCareerScoutInterviewerUserInput!) {
    updateCareerScoutInterviewerUser(input: $input) {
      id
    }
  }
`

export interface MutationUpdateCareerScoutInterviewerUserResponse {
  updateCareerScoutInterviewerUser: {
    id: string
  }
}

export const MutationDeleteCareerScoutInterviewAndInterviewerUser = gql`
  mutation deleteCareerScoutInterviewAndInterviewerUser($input: DeleteCareerScoutInterviewAndInterviewerUserInput!) {
    deleteCareerScoutInterviewAndInterviewerUser(input: $input)
  }
`

export interface MutationDeleteCareerScoutInterviewAndInterviewerUserResponse {
  deleteCareerScoutInterviewAndInterviewerUser: boolean
}

export const QueryCareerScoutInterviewerUsers = gql`
  query careerScoutInterviewerUsers($name: String!, $entryId: ID) {
    careerScoutInterviewerUsers(name: $name, entryId: $entryId) {
      id
      lastName
      firstName
      email
    }
  }
`

export interface QueryCareerScoutInterviewerUsersResponse {
  careerScoutInterviewerUsers: Array<{
    id: string
    lastName: string
    firstName: string
    email: string
  }>
}

export const QueryCareerScoutEntryActionHistories = gql`
  query careerScoutEntryActionHistories($id: ID!) {
    careerScoutEntry(id: $id) {
      id
      userPermissions
      entryActionHistories {
        id
        category
        createdAt
        content
        detail
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export interface QueryCareerScoutEntryActionHistoriesResponse {
  careerScoutEntry: {
    id: string
    userPermissions: CareerScoutEntryUserPermission[]
    entryActionHistories: Array<{
      id: string
      category: CareerScoutEntryActionCategory
      createdAt: string
      content: CareerScoutEntryActionHistoryContent
      detail: string
      user: {
        id: string
        firstName: string
        lastName: string
      } | null
    }>
  }
}

export const QueryManagedCareerScoutEntries = gql`
  query manageCareerScoutEntries(
    $id: ID
    $entryType: Int
    $entryFlowStepNo: Int
    $employeeNumber: String
    $interviewStatus: Int
    $period: String
    $isSpecialApplication: Boolean
    $isOnlySupportRequired: Boolean
    $offset: Int
    $limit: Int
  ) {
    manageCareerScoutEntries(
      id: $id
      entryType: $entryType
      entryFlowStepNo: $entryFlowStepNo
      employeeNumber: $employeeNumber
      interviewStatus: $interviewStatus
      period: $period
      isSpecialApplication: $isSpecialApplication
      isOnlySupportRequired: $isOnlySupportRequired
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        user {
          id
        }
        careerScout {
          id
          name
          applicationEndedAt
          organization {
            name
          }
        }
        firstName
        lastName
        isSupportRequired
        currentEntryFlowStep {
          no
          name
        }
        homeOrganizationName
        organizationName
        employmentTypeName
        entryType
        employeeNumber
        lastEntryMessage {
          id
          isRead
        }
        unreadOrLastEntryMessageId
        hasUnreadEntryUserMessage
        isCareerScoutSpecialApplicationUser
        userPermissions
      }
      total
    }
  }
`

export interface QueryManagedCareerScoutEntriesResponse {
  manageCareerScoutEntries: {
    items: Array<{
      id: string
      entryType: CareerScoutEntryType
      currentEntryFlowStep: {
        name: string
        no: CareerScoutGroupFlowStepNo | CareerScoutIndividualFlowStepNo
      }
      careerScout: {
        id: string
        name: string
        applicationEndedAt: string
        organization: {
          name: string
        }
      }
      employeeNumber: string
      firstName: string
      lastName: string
      user: {
        id: string
      }
      homeOrganizationName: string
      organizationName: string
      employmentTypeName: string
      hasUnreadEntryUserMessage: boolean
      isSupportRequired: boolean | null
      isCareerScoutSpecialApplicationUser: boolean
      userPermissions: number[]
      lastEntryMessage: {
        id: string
        isRead: boolean
      }
      unreadOrLastEntryMessageId: string | null
    }>
    total: number
  }
}

export const MutationBulkExportCareerScoutEntries = gql`
  mutation bulkExportCareerScoutEntries($input: BulkExportCareerScoutEntriesInput!) {
    bulkExportCareerScoutEntries(input: $input)
  }
`

export interface MutationBulkExportCareerScoutEntriesResponse {
  bulkExportCareerScoutEntries: boolean
}

export interface BulkCreateCareerScoutEntryMessageInput {
  entryIds: string[]
  message: string
  files: UploadFile[]
}
export const MutationBulkCreateCareerScoutEntryMessages = gql`
  mutation bulkCreateCareerScoutEntryMessages($input: BulkCreateCareerScoutEntryMessageInput!) {
    bulkCreateCareerScoutEntryMessages(input: $input)
  }
`
export interface MutationBulkCreateCareerScoutEntryMessagesResponse {
  bulkCreateCareerScoutEntryMessages: boolean
}

export const QueryCareerScoutEntryInterviewResult = gql`
  query careerScoutEntryInterviewResult($id: ID!) {
    entry: careerScoutEntry(id: $id) {
      id
      interviews {
        id
        status
        resultStatus
        resultPros
        resultCons
        resultMemo
        userPermissions
        interviewerUsers: careerScoutInterviewerUsers {
          id
          user {
            id
            firstName
            lastName
          }
        }
        latestSchedule {
          id
          selectedDate {
            id
            startAt
            endAt
            location
            locationDetail
          }
        }
      }
    }
  }
`
export interface QueryCareerScoutEntryInterviewResultResponse {
  entry: {
    id: string
    interviews: Array<{
      id: string
      status: InterviewStatus
      resultStatus: InterviewResultStatus
      resultPros: string
      resultCons: string
      resultMemo: string
      userPermissions: InterviewUserPermission[]
      interviewerUsers: Array<{
        user: {
          firstName: string
          lastName: string
        }
      }>
      latestSchedule: {
        selectedDate: {
          startAt: string
          endAt: string
          location: InterviewLocation
          locationDetail: string
        } | null
      } | null
    }>
  }
}

export interface UpdateCareerScoutInterviewResultInput {
  input: {
    interviewId: string
    resultStatus: InterviewResultStatus
    resultPros: string
    resultCons: string
    resultMemo: string
    updateType: InterviewResultUpdateType | null
  }
}
export const MutationUpdateCareerScoutInterviewResult = gql`
  mutation updateCareerScoutInterviewResult($input: UpdateCareerScoutInterviewResultInput!) {
    updateCareerScoutInterviewResult(input: $input) {
      id
    }
  }
`
export interface MutationUpdateCareerScoutInterviewResultResponse {
  updateCareerScoutInterviewResult: {
    id: string
  }
}

export const MutationCareerScoutEntryPersonnelNote = gql`
  mutation createCareerScoutEntryPersonnelNote($input: CreateCareerScoutEntryPersonnelNoteInput!) {
    createCareerScoutEntryPersonnelNote(input: $input) {
      id
      text
    }
  }
`
export interface MutationCareerScoutEntryPersonnelNoteResponse {
  createCareerScoutEntryPersonnelNote: {
    id: string
    text: string
  }
}

export const QueryCareerScoutEntryPersonnelNote = gql`
  query careerScoutentryPersonnelNote($id: ID!) {
    entry: careerScoutEntry(id: $id) {
      id
      entryPersonnelNotes {
        id
        text
        createdAt
        user {
          id
          firstName
          lastName
          avatarUrl
        }
      }
    }
  }
`
export interface QueryCareerScoutEntryPersonnelNoteResponse {
  entry: {
    entryPersonnelNotes: Array<{
      id: string
      text: string
      createdAt: Date
      user: {
        firstName: string
        lastName: string
        avatarUrl: string
      }
    }>
  }
}

export const QueryCareerScoutEntryUserInterviews = gql`
  query careerScoutEntryUserInterviews($id: ID!) {
    entry: careerScoutEntry(id: $id) {
      entryType
      interviews {
        id
        status
        reflectionMemo
        careerScoutEntryFlowStepNo
        interviewerUsers: careerScoutInterviewerUsers {
          id
          user {
            id
            firstName
            lastName
            email
            avatarUrl
          }
        }
        latestSchedule {
          id
          selectedDate {
            id
            startAt
            endAt
            location
            locationDetail
          }
        }
      }
      userPermissions
    }
  }
`
export interface QueryCareerScoutEntryUserInterviewsResponse {
  entry: {
    entryType: CareerScoutEntryType
    interviews: Array<{
      id: string
      status: InterviewStatus
      reflectionMemo: string
      careerScoutEntryFlowStepNo: CareerScoutGroupFlowStepNo | CareerScoutIndividualFlowStepNo
      interviewerUsers: Array<{
        user: {
          firstName: string
          lastName: string
          email: string
          avatarUrl: string
        }
      }>
      latestSchedule: {
        selectedDate: {
          startAt: string
          endAt: string
          location: InterviewLocation
          locationDetail: string
        } | null
      } | null
    }>
    userPermissions: CareerScoutEntryUserPermission[]
  }
}

export interface UpdateCareerScoutInterviewReflectionInput {
  input: {
    interviewId: string
    reflectionMemo: string
  }
}
export const MutationUpdateCareerScoutInterviewReflection = gql`
  mutation updateCareerScoutInterviewReflection($input: UpdateCareerScoutInterviewReflectionInput!) {
    updateCareerScoutInterviewReflection(input: $input) {
      id
      reflectionMemo
    }
  }
`
export interface MutationUpdateCareerScoutInterviewReflectionResponse {
  updateCareerScoutInterviewReflection: {
    id: string
    reflectionMemo: string
  }
}

export const QueryOrganizationAdminUsers = gql`
  query organizationAdminUsers(
    $userId: ID
    $organizationId: ID
    $organizationAdminType: String
    $offset: Int
    $limit: Int
  ) {
    organizationAdminUsers(
      userId: $userId
      organizationId: $organizationId
      organizationAdminType: $organizationAdminType
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        user {
          id
          employeeNumber
          firstName
          lastName
        }
        organization {
          id
          externalId
          name
        }
        isCCAdmin
        isJTAdmin
        isScoutAdmin
        isSmyleAdmin
        isDwPublisherAdmin
        isDwUserAdmin
      }
      total
    }
  }
`

export interface QueryOrganizationAdminUsersResponse {
  organizationAdminUsers: {
    items: Array<{
      id: string
      user: {
        id: string
        employeeNumber: string
        firstName: string
        lastName: string
      }
      organization: {
        id: string
        externalId: string
        name: string
      }
      isCCAdmin: boolean
      isJTAdmin: boolean
      isScoutAdmin: boolean
      isSmyleAdmin: boolean
      isDwPublisherAdmin: boolean
      isDwUserAdmin: boolean
    }>
    total: number
  }
}

export const MutationCreateOrUpdateOrganizationAdminUsers = gql`
  mutation createOrUpdateOrganizationAdminUsers($input: CreateOrUpdateOrganizationAdminUsersInput!) {
    createOrUpdateOrganizationAdminUsers(input: $input)
  }
`

export interface MutationCreateOrUpdateOrganizationAdminUsersResponse {
  createOrUpdateOrganizationAdminUsers: boolean
}

export const MutationBulkExportOrganizationAdminUsers = gql`
  mutation bulkExportOrganizationAdminUsers($userOrganizationIds: [ID!]!) {
    bulkExportOrganizationAdminUsers(userOrganizationIds: $userOrganizationIds)
  }
`

export interface MutationBulkExportOrganizationAdminUsersResponse {
  bulkExportOrganizationAdminUsers: string
}

export const MutationBulkImportOrganizationAdminUsers = gql`
  mutation bulkImportOrganizationAdminUsers($file: Upload!) {
    bulkImportOrganizationAdminUsers(file: $file) {
      created
      updated
      errors
    }
  }
`

export interface MutationBulkImportOrganizationAdminUsersResponse {
  bulkImportOrganizationAdminUsers: {
    created: number
    updated: number
    errors: Array<{
      row: number | null
      column: string | null
      value: string | null
      message: string
    }>
  }
}

export const QueryUsersByKeyword = gql`
  query usersByKeyword($keyword: String!) {
    usersByKeyword(keyword: $keyword) {
      id
      employeeNumber
      lastName
      firstName
      email
      isAccountAdmin
      isDwAccountAdmin
      isSmyleAccountAdmin
      isScoutAccountAdmin
      organizations {
        id
        organization {
          id
        }
        isCCAdmin
        isJTAdmin
        isScoutAdmin
        isSmyleAdmin
        isDwPublisherAdmin
        isDwUserAdmin
      }
    }
  }
`

export const QueryAccountAdminUsers = gql`
  query accountAdminUsers($userId: String, $accountAdminType: String, $offset: Int, $limit: Int) {
    accountAdminUsers(userId: $userId, accountAdminType: $accountAdminType, offset: $offset, limit: $limit) {
      items {
        id
        employeeNumber
        lastName
        firstName
        isAccountAdmin
        isScoutAccountAdmin
        isSmyleAccountAdmin
        isDwAccountAdmin
      }
      total
    }
  }
`
export interface AccountAdminUser {
  id: string
  employeeNumber: string
  lastName: string
  firstName: string
  isAccountAdmin: boolean
  isScoutAccountAdmin: boolean
  isSmyleAccountAdmin: boolean
  isDwAccountAdmin: boolean
}
export interface QueryAccountAdminUsersResponse {
  accountAdminUsers: {
    items: AccountAdminUser[]
    total: number
  }
}

export interface QueryUsersByKeywordResponse {
  usersByKeyword: Array<{
    id: string
    employeeNumber: string
    lastName: string
    firstName: string
    email: string
    isAccountAdmin: boolean
    isDwAccountAdmin: boolean
    isSmyleAccountAdmin: boolean
    isScoutAccountAdmin: boolean
    organizations: Array<{
      id: string
      organization: {
        id: string
      }
      isCCAdmin: boolean
      isJTAdmin: boolean
      isScoutAdmin: boolean
      isSmyleAdmin: boolean
      isDwPublisherAdmin: boolean
      isDwUserAdmin: boolean
    }>
  }>
}

export const MutationUpdateAccountAdminUsers = gql`
  mutation updateAccountAdminUsers($input: UpdateAccountAdminUsersInput!) {
    updateAccountAdminUsers(input: $input)
  }
`

export interface MutationUpdateAccountAdminUsersResponse {
  updateAccountAdminUsers: boolean
}

export const MutationBulkExportAccountAdminUsers = gql`
  mutation bulkExportAccountAdminUsers($userIds: [ID!]!) {
    bulkExportAccountAdminUsers(userIds: $userIds)
  }
`

export interface MutationBulkExportAccountAdminUsersResponse {
  bulkExportAccountAdminUsers: string
}

export const MutationBulkImportAccountAdminUsers = gql`
  mutation bulkImportAccountAdminUsers($file: Upload!) {
    bulkImportAccountAdminUsers(file: $file) {
      created
      updated
      errors
    }
  }
`

export interface MutationBulkImportAccountAdminUsersResponse {
  bulkImportAccountAdminUsers: {
    created: number
    updated: number
    errors: Array<{
      row: number | null
      column: string | null
      value: string | null
      message: string
    }>
  }
}

export const QueryVideos = gql`
  query videos($fileKey: String) {
    videos(fileKey: $fileKey) {
      fileKey
      version
      title
      thumbnail
      completeFraction
      totalSeconds
      fileUrl
      thumbnailUrl
      isLatestVideoCompleted
      hasNewVersion
      userVideoActivity {
        id
        createdAt
        modifiedAt
        fileKey
        lastWatchedVersion
        completeVersion
        lastWatchedSeconds
      }
    }
  }
`

export interface Video {
  fileKey: string
  version: number
  title: string
  thumbnail: string
  completeFraction: number
  totalSeconds: number
  fileUrl: string
  thumbnailUrl: string
  isLatestVideoCompleted: boolean
  hasNewVersion: boolean
  userVideoActivity?: {
    id: string
    createdAt: string
    modifiedAt: string
    fileKey: string
    lastWatchedVersion: number
    completeVersion: number
    lastWatchedSeconds: number
  }
}

export interface QueryVideosResponse {
  videos: Video[]
}

export interface CreateOrUpdateUserVideoActivityInput {
  input: {
    fileKey: string
    videoVersion: number
    isCompletedVideo?: boolean
    lastWatchedSeconds?: number
  }
}

export const MutationCreateOrUpdateUserVideoActivity = gql`
  mutation CreateOrUpdateUserVideoActivity($input: CreateOrUpdateUserVideoActivityInput!) {
    createOrUpdateUserVideoActivity(input: $input) {
      id
      fileKey
      lastWatchedVersion
      completeVersion
      lastWatchedSeconds
    }
  }
`
export interface MutationCreateOrUpdateUserVideoActivityResponse {
  createOrUpdateUserVideoActivity: {
    id: string
    fileKey: string
    lastWatchedVersion: number
    completeVersion: number
    lastWatchedSeconds: number
  }
}

export const MutationAutoAssignWorkshopEntriesGroup = gql`
  mutation autoAssignWorkshopEntriesGroup($input: AutoAssignWorkshopEntriesGroupInput!) {
    autoAssignWorkshopEntriesGroup(input: $input)
  }
`

export interface MutationAutoAssignWorkshopEntriesGroupResponse {
  autoAssignWorkshopEntriesGroup: boolean
}

export const QueryOrganizationsForSuperAdmin = gql`
  query organizationsForSuperAdmin($organizationId: ID, $canIndividualScout: Boolean, $offset: Int, $limit: Int) {
    organizationsForSuperAdmin(
      organizationId: $organizationId
      canIndividualScout: $canIndividualScout
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        externalId
        name
        canIndividualScout
        sbuId
      }
      total
    }
  }
`

export interface QueryOrganizationsForSuperAdminResponse {
  organizationsForSuperAdmin: {
    items: Array<{
      id: string
      externalId: string | null
      name: string
      canIndividualScout: boolean
      sbuId: string
    }>
    total: number
  }
}

export const MutationUpdateOrganizations = gql`
  mutation updateOrganizations($input: UpdateOrganizationsInput!) {
    updateOrganizations(input: $input)
  }
`

export interface MutationUpdateOrganizationsResponse {
  updateOrganizations: boolean
}

export const MutationBulkExportOrganizations = gql`
  mutation bulkExportOrganizations($organizationIds: [ID!]!) {
    bulkExportOrganizations(organizationIds: $organizationIds)
  }
`

export interface MutationBulkExportOrganizationsResponse {
  bulkExportOrganizations: string
}

export const QueryDepartmentsForSuperAdmin = gql`
  query departmentsForSuperAdmin($organizationId: ID, $departmentId: ID, $offset: Int, $limit: Int) {
    departmentsForSuperAdmin(
      organizationId: $organizationId
      departmentId: $departmentId
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        externalId
        name
        organization {
          id
          externalId
          name
        }
      }
      total
    }
  }
`

export interface QueryDepartmentsForSuperAdminResponse {
  departmentsForSuperAdmin: {
    items: Array<{
      id: string
      externalId: string | null
      name: string
      organization: {
        id: string
        externalId: string | null
        name: string
      }
    }>
    total: number
  }
}

export const MutationBulkExportDepartments = gql`
  mutation bulkExportDepartments($departmentIds: [ID!]!) {
    bulkExportDepartments(departmentIds: $departmentIds)
  }
`

export interface MutationBulkExportDepartmentsResponse {
  bulkExportDepartments: string
}

export const QueryDepartmentsByKeyword = gql`
  query departmentsByKeyword($keyword: String!) {
    departmentsByKeyword(keyword: $keyword) {
      id
      externalId
      name
    }
  }
`

export interface QueryDepartmentsByKeywordResponse {
  departmentsByKeyword: Array<{
    id: string
    externalId: string | null
    name: string
  }>
}

export const QueryUsersForSuperAdmin = gql`
  query usersForSuperAdmin(
    $isSSOUser: Boolean
    $mainOrganizationId: ID
    $userId: ID
    $isCareerScoutSpecialApplicationUser: Boolean
    $offset: Int
    $limit: Int
  ) {
    usersForSuperAdmin(
      isSSOUser: $isSSOUser
      mainOrganizationId: $mainOrganizationId
      userId: $userId
      isCareerScoutSpecialApplicationUser: $isCareerScoutSpecialApplicationUser
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        createdAt
        modifiedAt
        email
        homeOrganization {
          id
          name
        }
        organizations {
          id
          isMain
          isCsv
          organization {
            id
            name
            externalId
          }
          department {
            id
            name
            externalId
          }
          managerId
          managerName
          managerNameForHR
        }
        department {
          id
          name
        }
        employmentType {
          id
          name
        }
        firstName
        lastName
        firstNameKana
        lastNameKana
        employeeNumber
        isSSOUser
        deletedAt
        userRawData {
          id
          csvRow
        }
        isCareerScoutSpecialApplicationUser
      }
      total
    }
  }
`

export interface QueryUsersForSuperAdminResponse {
  usersForSuperAdmin: {
    items: Array<{
      id: string
      email: string
      homeOrganization: {
        id: string
        name: string
      }
      organizations: Array<{
        id: string
        isMain: boolean
        isCsv: boolean
        organization: {
          id: string
          name: string
          externalId: string
        }
        department: {
          id: string
          name: string
          externalId: string
        } | null
        managerId: string
        managerName: string
        managerNameForHR: string
      }>
      department: {
        id: string
        name: string
      } | null
      employmentType: {
        id: string
        name: string
      }
      firstName: string
      lastName: string
      firstNameKana: string
      lastNameKana: string
      employeeNumber: string
      isSSOUser: boolean
      deletedAt: string
      userRawData: {
        id: string
        csvRow: string[]
      }
      isCareerScoutSpecialApplicationUser: boolean
    }>
    total: number
  }
}

export const MutationBulkExportUsers = gql`
  mutation bulkExportUsers($userIds: [ID!]!) {
    bulkExportUsers(userIds: $userIds)
  }
`

export interface MutationBulkExportUsersResponse {
  bulkExportUsers: string
}

export const MutationUpdateUsers = gql`
  mutation updateUsers($input: UpdateUsersInput!) {
    updateUsers(input: $input)
  }
`

export interface MutationUpdateUsersResponse {
  updateUsers: string
}

export const MutationBulkImportUsers = gql`
  mutation bulkImportUsers($file: Upload!) {
    bulkImportUsers(file: $file) {
      created
      updated
      errors
    }
  }
`

export interface MutationBulkImportUsersResponse {
  bulkImportUsers: {
    created: number
    updated: number
    errors: Array<{
      row: number | null
      column: string | null
      value: string | null
      message: string
    }>
  }
}

export const MutationDeleteUsers = gql`
  mutation deleteUsers($input: DeleteUsersInput!) {
    deleteUsers(input: $input)
  }
`

export interface MutationDeleteUsersResponse {
  deleteUsers: boolean
}
