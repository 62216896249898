import ipaddr from 'ipaddr.js'
import { uniq } from 'lodash'

export interface TokenPayload {
  sub: string
  email: string
  'cognito:username': string
  'custom:user_id'?: string
  'custom:account_id'?: string
  'custom:agreement'?: string
  'custom:is_super_admin'?: string
  'custom:is_account_admin'?: string
  'custom:organizations'?: string
  'custom:is_smy_account_admin'?: string
  'custom:is_dw_account_admin'?: string
  'custom:is_dw_entry_user'?: string
  'custom:is_sct_account_admin'?: string
}

export interface TokenPayloadAgreement {
  accountCustomTerms?: string
}

export interface AccountCustomTerms {
  title?: string
  subTitle?: string
  content?: string
}

export interface TokenPayloadOrganization {
  id: string
  main: 0 | 1
  manager: 0 | 1
  jt: 0 | 1
  cc: 0 | 1
  smyle: 0 | 1
  dwp: 0 | 1
  dwu: 0 | 1
  dwpas: 0 | 1
  sct: 0 | 1 // スカウト人事権
  dpt_mgr: 0 | 1 // 組織マネジャー部長以上(個社管理職)
}
export const AuthRole = {
  SystemAdmin: 'SystemAdmin', // システム管理者
  LdapAuthUser: 'LdapAuthUser', // 外部LDAP認証者
  SuperAdmin: 'SuperAdmin', // スーパー管理者
  AccountAdmin: 'AccountAdmin', // アカウント管理者(グループ人事)
  OrganizationAdmin: 'OrganizationAdmin', // 組織管理者(個社人事) => キャリチャレ管理者またはジョブトラ管理者
  OrganizationCCAdmin: 'OrganizationCCAdmin', // 組織キャリチャレ管理者
  OrganizationJTAdmin: 'OrganizationJTAdmin', // 組織ジョブトラ管理者
  OrganizationManager: 'OrganizationManager', // 組織マネジャー(個社管理職)
  LoginUser: 'LoginUser', // ログインしているユーザ
  AllowIPUser: 'AllowIPUser', // IPアドレス許可ユーザ
  SmyleAccountAdmin: 'SmyleAccountAdmin', // グループSmyle管理者
  OrganizationSmyleAdmin: 'OrganizationSmyleAdmin', // 個社Smyle管理者
  DoubleworkAccountAdmin: 'DoubleworkAccountAdmin', // グループ複業管理者
  OrganizationDWPubliserAdmin: 'OrganizationDWPubliserAdmin', // 複業発注元個社人事
  OrganizationDWPubliserAssistant: 'OrganizationDWPubliserAssistant', // 複業発注元アシスタント
  OrganizationDWUserAdmin: 'OrganizationDWUserAdmin', // 複業輩出元個社人事
  DoubleworkEntryUser: 'DoubleworkEntryUser', // 複業応募可能者(一般)
  ScoutAccountAdmin: 'ScoutAccountAdmin', // グループスカウト管理者
  OrganizationScoutAdmin: 'OrganizationScoutAdmin', // 組織スカウト管理者
  OrganizationDepartmentManager: 'OrganizationDepartmentManager', // 組織マネジャー部長以上(個社管理職)
} as const

export type AuthRole = typeof AuthRole[keyof typeof AuthRole]

export interface UserPermissionAttributes {
  idpSub: string | null
  idpUsername: string | null
  email: string | null
  userId: string | null
  accountId: string | null
  mainOrganizationId: string | null
  subOrganizationIds: string[]
  organizationIds: string[]
  administrateOrganizationIds: string[] // CC,JT用で複業やスカウトは含まれない
  administrateCCOrganizationIds: string[]
  administrateJTOrganizationIds: string[]
  administrateSmyleOrganizationIds: string[]
  administrateDWPOrganizationIds: string[]
  administrateDWUOrganizationIds: string[]
  assistantDWPOrganizationIds: string[]
  manageOrganizationIds: string[]
  administrateOrManageOrganizationIds: string[] // CC,JT用で複業やスカウトは含まれない
  administrateScoutOrganizationIds: string[]
  departmentManageOrganizationIds: string[]
  roles: AuthRole[]
  hasAgreement: boolean
  remoteIps?: string[]
}

export function getUserPermissionAttributes(
  tokenPayload: TokenPayload | null,
  adminApiKeyHeader?: string | string[],
  ldapAuthKeyHeader?: string | string[],
  remoteIps?: string[],
  allowIps?: string[],
  customTermsUpdatedAt: string | null = null,
) {
  const attributes: UserPermissionAttributes = {
    idpSub: null,
    idpUsername: null,
    userId: null,
    email: null,
    accountId: null,
    mainOrganizationId: null,
    subOrganizationIds: [],
    organizationIds: [],
    administrateOrganizationIds: [],
    administrateCCOrganizationIds: [],
    administrateJTOrganizationIds: [],
    administrateSmyleOrganizationIds: [],
    administrateDWPOrganizationIds: [],
    administrateDWUOrganizationIds: [],
    assistantDWPOrganizationIds: [],
    manageOrganizationIds: [],
    administrateOrManageOrganizationIds: [],
    administrateScoutOrganizationIds: [],
    departmentManageOrganizationIds: [],
    roles: [],
    hasAgreement: false,
    remoteIps,
  }

  if (adminApiKeyHeader !== undefined && adminApiKeyHeader === process.env.ADMIN_API_SECRET_KEY) {
    attributes.roles.push(AuthRole.SystemAdmin)
  }

  if (
    ldapAuthKeyHeader !== undefined &&
    (process.env.LDAP_AUTH_SECRET_KEYS ?? '').split(',').some(x => x === ldapAuthKeyHeader)
  ) {
    attributes.roles.push(AuthRole.LdapAuthUser)
  }

  if (tokenPayload === null) {
    return attributes
  }
  attributes.idpSub = tokenPayload.sub
  attributes.idpUsername = tokenPayload['cognito:username']
  attributes.userId = tokenPayload['custom:user_id'] ?? null
  attributes.email = tokenPayload.email ?? null
  attributes.accountId = tokenPayload['custom:account_id'] ?? null
  attributes.roles.push(AuthRole.LoginUser)

  const isSuperAdmin = tokenPayload['custom:is_super_admin'] === 'true'
  if (isSuperAdmin) {
    attributes.roles.push(AuthRole.SuperAdmin)
  }

  const isAccountAdmin = tokenPayload['custom:is_account_admin'] === 'true'
  if (isAccountAdmin) {
    attributes.roles.push(AuthRole.AccountAdmin)
  }

  const isSmyleAccountAdmin = tokenPayload['custom:is_smy_account_admin'] === 'true'
  if (isSmyleAccountAdmin) {
    attributes.roles.push(AuthRole.SmyleAccountAdmin)
  }

  const isDwAccountAdmin = tokenPayload['custom:is_dw_account_admin'] === 'true'
  if (isDwAccountAdmin) {
    attributes.roles.push(AuthRole.DoubleworkAccountAdmin)
  }

  const isDwEntryUser = tokenPayload['custom:is_dw_entry_user'] === 'true'
  if (isDwEntryUser) {
    attributes.roles.push(AuthRole.DoubleworkEntryUser)
  }

  const isScoutAccountAdmin = tokenPayload['custom:is_sct_account_admin'] === 'true'
  if (isScoutAccountAdmin) {
    attributes.roles.push(AuthRole.ScoutAccountAdmin)
  }

  if (checkAllowIp(allowIps, remoteIps)) {
    attributes.roles.push(AuthRole.AllowIPUser)
  }

  const userOrganizations: TokenPayloadOrganization[] = JSON.parse(tokenPayload['custom:organizations'] ?? '[]')
  userOrganizations.forEach(x => {
    attributes.organizationIds.push(x.id)
    if (x.main === 1) {
      attributes.mainOrganizationId = x.id
    } else {
      attributes.subOrganizationIds.push(x.id)
    }
    if (x.cc === 1) {
      attributes.roles.push(AuthRole.OrganizationCCAdmin)
      attributes.administrateCCOrganizationIds.push(x.id)
    }
    if (x.jt === 1) {
      attributes.roles.push(AuthRole.OrganizationJTAdmin)
      attributes.administrateJTOrganizationIds.push(x.id)
    }
    if (x.cc === 1 || x.jt === 1) {
      attributes.roles.push(AuthRole.OrganizationAdmin)
      attributes.administrateOrganizationIds.push(x.id)
      attributes.administrateOrManageOrganizationIds.push(x.id)
    }
    if (x.manager === 1) {
      attributes.roles.push(AuthRole.OrganizationManager)
      attributes.manageOrganizationIds.push(x.id)
      attributes.administrateOrManageOrganizationIds.push(x.id)
    }
    if (x.smyle === 1) {
      attributes.roles.push(AuthRole.OrganizationSmyleAdmin)
      attributes.administrateSmyleOrganizationIds.push(x.id)
    }
    if (x.dwp === 1) {
      attributes.roles.push(AuthRole.OrganizationDWPubliserAdmin)
      attributes.administrateDWPOrganizationIds.push(x.id)
    }
    if (x.dwu === 1) {
      attributes.roles.push(AuthRole.OrganizationDWUserAdmin)
      attributes.administrateDWUOrganizationIds.push(x.id)
    }
    if (x.dwpas === 1) {
      attributes.roles.push(AuthRole.OrganizationDWPubliserAssistant)
      attributes.assistantDWPOrganizationIds.push(x.id)
    }
    if (x.sct === 1) {
      attributes.roles.push(AuthRole.OrganizationScoutAdmin)
      attributes.administrateScoutOrganizationIds.push(x.id)
    }
    if (x.dpt_mgr === 1) {
      attributes.roles.push(AuthRole.OrganizationDepartmentManager)
      attributes.departmentManageOrganizationIds.push(x.id)
    }
  })

  const userAgreement: TokenPayloadAgreement = JSON.parse(tokenPayload['custom:agreement'] ?? '{}')
  attributes.hasAgreement = checkAgreement(userAgreement, customTermsUpdatedAt)

  // この先実装が変わるかもしれないのでテストケースが少なくなることを優先して実装している。
  attributes.subOrganizationIds = uniq(attributes.subOrganizationIds)
  attributes.organizationIds = uniq(attributes.organizationIds)
  attributes.administrateOrganizationIds = uniq(attributes.administrateOrganizationIds)
  attributes.administrateCCOrganizationIds = uniq(attributes.administrateCCOrganizationIds)
  attributes.administrateJTOrganizationIds = uniq(attributes.administrateJTOrganizationIds)
  attributes.administrateSmyleOrganizationIds = uniq(attributes.administrateSmyleOrganizationIds)
  attributes.administrateDWPOrganizationIds = uniq(attributes.administrateDWPOrganizationIds)
  attributes.administrateDWUOrganizationIds = uniq(attributes.administrateDWUOrganizationIds)
  attributes.assistantDWPOrganizationIds = uniq(attributes.assistantDWPOrganizationIds)
  attributes.manageOrganizationIds = uniq(attributes.manageOrganizationIds)
  attributes.administrateOrManageOrganizationIds = uniq(attributes.administrateOrManageOrganizationIds)
  attributes.administrateScoutOrganizationIds = uniq(attributes.administrateScoutOrganizationIds)
  attributes.departmentManageOrganizationIds = uniq(attributes.departmentManageOrganizationIds)
  attributes.roles = uniq(attributes.roles)

  return attributes
}

export function checkLoginUserPermissionAttributes(pa: UserPermissionAttributes) {
  if (
    pa.idpSub === null ||
    pa.idpUsername === null ||
    pa.userId === null ||
    pa.accountId === null ||
    pa.mainOrganizationId === null
  ) {
    throw new Error('IdTokenが不正です')
  }
  return {
    ...pa,
    idpSub: pa.idpSub,
    idpUsername: pa.idpUsername,
    userId: pa.userId,
    accountId: pa.accountId,
    mainOrganizationId: pa.mainOrganizationId,
  }
}

export const checkAllowIp = (allowIps: string[] | undefined, remoteIps: string[] | undefined): boolean => {
  if (allowIps === undefined || remoteIps === undefined) {
    return false
  }

  // NOTE: allowIp未設定のアカウントはすべて認可
  if (allowIps.length === 0) {
    return true
  }

  // NOTE: 社内LANの場合は2個(ClientIP→CloudFrontIP)、VPN経由(ClientIP→ProxyIP→CloudFrontIP)の場合は3個
  // NOTE: Proxyを経由したケースを考慮してIP個数は2個以上であればOKとしている
  if (remoteIps.length < 2) {
    return false
  }

  // NOTE: 社内LANの場合(remoteIpsが2個以上のケース - 1番目のIPが社内IP)
  if (allowIps.some(cidr => ipaddr.parse(remoteIps[0]).match(ipaddr.parseCIDR(cidr)))) {
    return true
  }

  // NOTE: VPN経由の場合(remoteIpsが3個以上)
  if (remoteIps.length > 2) {
    return allowIps.some(cidr => ipaddr.parse(remoteIps[1]).match(ipaddr.parseCIDR(cidr)))
  }
  return false
}

export const checkAgreement = (userAgreement: TokenPayloadAgreement, customTermsUpdatedAt: string | null) => {
  return (
    customTermsUpdatedAt === null ||
    (userAgreement.accountCustomTerms !== undefined && userAgreement.accountCustomTerms >= customTermsUpdatedAt)
  )
}

export type RoleOrRoleArray = AuthRole | RoleOrRoleArray[]

export function checkAuth(pa: UserPermissionAttributes, roleOrRoleArray: RoleOrRoleArray) {
  const checkRoleOrRoleArray = (roleOrRoleArray: RoleOrRoleArray, level = 0): boolean => {
    if (Array.isArray(roleOrRoleArray)) {
      // 空配列なら問答無用でtrue
      if (roleOrRoleArray.length === 0) {
        return true
      }
      // 偶数の階層ではOR(=some), 奇数の階層ではAND(=every)で判定を行う
      if (level % 2 === 0) {
        return roleOrRoleArray.some(x => checkRoleOrRoleArray(x, level + 1))
      } else {
        return roleOrRoleArray.every(x => checkRoleOrRoleArray(x, level + 1))
      }
    }
    return pa.roles.includes(roleOrRoleArray)
  }
  return checkRoleOrRoleArray(roleOrRoleArray)
}
